import ItemForm from "../onboarding/itemForm";
import { makeStyles } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import ReceiptIcon from "@material-ui/icons/Receipt";
import clsx from "clsx";

// eslint-disable-next-line no-unused-vars
import React from "react";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    form: {
        marginTop: theme.spacing(1),
        width: "100%"
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    },
    card: {
        //fontSize: 30,
        //backgroundColor: "red",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    infoContainer: {
        display: "table",
        tableLayout: "fixed",
        width: "100%",
        marginBottom: 30
    },

    infoUser: {
        paddingLeft: 15
    },

    infoIcon: {
        textAlign: "center",
        width: 60,
        color: "#979799"
    },

    infoSub: {
        display: "table-cell",
        overflow: "hidden",
        paddingTop: 20,
        textOverflow: "ellipsis",
        verticalAlign: "middle"
    },

    infoTitle: {
        fontSize: 19
    },

    infoSubTitle: {
        marginLeft: 20,
        marginBottom: 10,
        fontSize: 12
    }
}));

const Invoice = ({ setForm, formData }) => {
    const { invoice, quote } = formData;

    const classes = useStyles();

    return (
        <>
            <div className={ classes.infoTitle }>Numérotation Devis & Factures</div>
            <div className={ classes.infoSubTitle }>Attention dès que vous aurez crée votre première facture, ces paramètres seront figés</div>
            <div className="form" spacing={ 1 }>
                <div className={ classes.infoContainer }>
                    <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                        <ReceiptIcon />
                    </div>
                    <ItemForm label="Facture" name="invoice" type="number" value={ invoice } onChange={ setForm } />
                </div>

                <div className={ classes.infoContainer }>
                    <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                        <DescriptionIcon />
                    </div>
                    <ItemForm label="Devis" name="quote" type="number" value={ quote } onChange={ setForm } />
                </div>
            </div>
        </>
    );
};

export default Invoice;
