import { StyleSheet, Text, View } from "@react-pdf/renderer";

// eslint-disable-next-line no-unused-vars
import React from "react";

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: "row",
        marginTop: 12
    },
    reportTitle: {
        fontSize: 10,
        textAlign: "center",
        textTransform: "uppercase"
    }
});

const InvoiceThankYouMsg = () => (
    <View style={ styles.titleContainer }>
        <Text style={ styles.reportTitle }>Merci de votre confiance</Text>
    </View>
);

export default InvoiceThankYouMsg;
