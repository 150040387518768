/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import PersonIcon from "@material-ui/icons/Person";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
import React, { Component, Fragment } from "react";

class AutocompleteClient extends Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array)
    };

    static defaultProps = {
        suggestions: []
    };

    constructor(props) {
        super(props);

        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: props.ClientGet()["name"]
        };
    }

    onChange = e => {
        const { suggestions } = this.props;
        const userInput = e.currentTarget.value;

        // Filter our suggestions that don't contain the user's input
        const filteredSuggestions = suggestions.filter(suggestion => suggestion["firstname"].toLowerCase().indexOf(userInput.toLowerCase()) > -1);

        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value
        });
    };

    onClick = e => {
        this.props.ClientSet({
            id: e.currentTarget.getAttribute("data"),
            name: e.currentTarget.innerText
        });
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText
        });
    };

    onKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        // User pressed the enter key
        if (e.keyCode === 13) {
            let val = document.querySelector("ul.suggestions > .suggestion-active");
            this.props.ClientSet({
                id: val.getAttribute("data"),
                name: val.innerText
            });
            this.setState({
                activeSuggestion: 0,
                filteredSuggestions: [],
                showSuggestions: false,
                userInput: val.innerText
            });
            return;
        }
        // User pressed the up arrow
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: { activeSuggestion, filteredSuggestions, showSuggestions, userInput }
        } = this;

        let suggestionsListComponent;

        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        { filteredSuggestions.map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li className={ className } key={ suggestion["userId"] } onClick={ onClick } data={ suggestion["userId"] }>
                                    { suggestion["firstname"] }
                                </li>
                            );
                        }) }
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions, you&apos;re on your own!</em>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <TextField
                    margin="normal"
                    type="text"
                    id="client"
                    name="client"
                    label="Client"
                    autoComplete="off"
                    onChange={ onChange }
                    onKeyDown={ onKeyDown }
                    value={ userInput }
                    fullWidth
                    InputProps={ {
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon />
                            </InputAdornment>
                        )
                    } }
                />
                { suggestionsListComponent }
            </Fragment>
        );
    }
}

export default AutocompleteClient;
