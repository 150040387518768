import { calcPriceTotal } from "../Tools";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, InputAdornment, TextField } from "@material-ui/core";
import EuroIcon from "@material-ui/icons/Euro";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";

export default function BookingDiscount({ item, sumFees, discounts, setDiscounts }) {
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [discount, setDiscount] = useState("coupon");
    const [discountValue, setDiscountValue] = useState(0);
    const [discountPercent, setDiscountPercent] = useState(false);
    const [invalid, setInvalid] = useState({ name: false, value: false });

    const grandTotal = isNaN(calcPriceTotal(item.price, sumFees, discounts)) ? 0 : parseFloat(calcPriceTotal(item.price, sumFees, discounts));

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (discountValue < 0 || (discountValue > 100 && discountPercent) || (discountValue > grandTotal && !discountPercent)) {
            setInvalid({ ...invalid, value: true });
        } else {
            setInvalid({ ...invalid, value: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [discountValue, discountPercent]);

    const updateValue = e => {
        setDiscountValue(e.target.value);
    };

    const updatePercent = e => {
        setDiscountPercent(e.target.checked);
    };

    const validate = () => {
        if (!discount || !discountValue || invalid.value) {
            enqueueSnackbar("Une erreurs c'est produites", { variant: "error" });
        }
        setDiscounts([
            ...discounts,
            {
                name: discount,
                value: parseFloat(discountValue),
                percent: discountPercent,
                id: "_" + Math.random().toString(36).substr(2, 9)
            }
        ] || []);
        enqueueSnackbar("Le coupon a été ajouté", { variant: "info" });
        handleClose();
    };

    return (
        <>
            <div style={ { marginBottom: "1.3rem", marginTop: "1.2rem" } }>
                <Button variant="outlined" color="primary" onClick={ handleOpen } startIcon={ <EuroIcon /> } size="small">
                    Ajouter un coupon de réduction
                </Button>
            </div>
            <Dialog open={ open } onClose={ handleClose }>
                <DialogTitle id="form-dialog-title">Ajouter un coupon de Réduction</DialogTitle>
                <DialogContent>
                    <TextField id="discountName" name="discountName" margin="normal" label="Nom de la réduction" type="text" fullWidth value={ discount } onChange={ e => setDiscount(e.target.value) } />
                    <TextField
                        error={ invalid.value }
                        id="discountAmount"
                        name="discountAmount"
                        margin="normal"
                        label={ "montant du coupon de réduction" }
                        type="number"
                        value={ discountValue }
                        onChange={ e => updateValue(e) }
                        InputProps={ {
                            inputProps: {
                                min: 0,
                                max: discountPercent ? 100 : grandTotal,
                                step: 0.1
                            },
                            endAdornment: <InputAdornment position="end">(0 - { discountPercent ? "100) %" : grandTotal + ") €" }</InputAdornment>
                        } }
                        fullWidth
                    />
                    <FormControlLabel control={ <Checkbox checked={ discountPercent } onChange={ e => updatePercent(e) } name="discountPers" color="primary" /> } label="En pourcentage" />
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleClose } color="primary">
                        Annuler
                    </Button>
                    <Button color="primary" disabled={ !discount || !discountValue || invalid.value } onClick={ validate }>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
