import { AuthenticationContext } from "./auth";
import fbConfig from "./base";
import { Hidden } from "@material-ui/core";
import { default as AppBarBase } from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "#0747a6",
        fontSize: "14px"
    },
    title: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "content",
        textDecoration: "none",
        color: theme.palette.primary.contrastText
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    }
}));

export default function AppBar({ toggleDrawer }) {
    const classes = useStyles();

    return (
        <AppBarBase position="fixed" className={ classes.appBar }>
            <Toolbar>
                <IconButton color="inherit" aria-label="open drawer" edge="start" className={ classes.menuButton } onClick={ toggleDrawer }>
                    <MenuIcon />
                </IconButton>
                <PhotoCameraIcon />
                <Typography to="/" component={ Link } className={ classes.title } variant="h5">
                    EzPresta
                </Typography>
                <Hidden mdUp implementation="css">
                    <IconButton color="inherit">
                        <AccountCircleIcon />
                    </IconButton>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Button color="inherit" href="" startIcon={ <AccountCircleIcon /> }>
                        <AuthenticationContext.Consumer>{ value => value.user && value.user.username }</AuthenticationContext.Consumer>
                    </Button>
                </Hidden>
                <IconButton color="inherit" href="/logout" onClick={ () => fbConfig.auth().signOut() }>
                    <ExitToAppIcon />
                </IconButton>
            </Toolbar>
        </AppBarBase>
    );
}

AppBar.propTypes = {
    toggleDrawer: PropTypes.func.isRequired
};
