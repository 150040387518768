import FbConfig from "../base";
import { loadCatalog } from "../catalog/CatalogTools";
import { loadCategory } from "../catalog/Category";
import React, { createContext, useContext } from "react";
export const AuthenticationContext = createContext(null);

export class AuthenticationProvider extends React.Component {
    constructor(props) {
        super(props);

        this.user = JSON.parse(localStorage.getItem("token"));

        const login = async (login, password) => {
            try {
                return await FbConfig.auth()
                    .signInWithEmailAndPassword(login.toString().toLowerCase(), password)
                    .then(result => {
                        const userNfo = {
                            email: result.user.email,
                            uid: result.user.uid
                        };

                        this.setState({ user: userNfo });

                        result.returncode = "success";
                        result.returnmsg = "Bienvenue";

                        return result;
                    })
                    .catch(function (error) {
                        error.returncode = "error";
                        error.returnmsg = error.code;
                        return error;
                    });
            } catch (error) {
                alert(error);
            }
        };

        const isAuthenticated = () => {
            let tokenIsNotNull = null !== localStorage.getItem("token");
            let tokenIsNotEmpty = false;

            if (true === tokenIsNotNull) {
                tokenIsNotEmpty = localStorage.getItem("token").length > 0;
            }

            const isAuth = tokenIsNotNull && tokenIsNotEmpty;
            return isAuth;
        };

        const getUserData = async (uid, email) => {
            let userNfo = [];

            if (true === isAuthenticated()) {
                try {
                    await FbConfig.database()
                        .ref("/users/" + uid)
                        .once("value", snapshot => {
                            const userData = snapshot.val();
                            if (userData) {
                                userNfo = {
                                    uid: uid,
                                    email: email,
                                    username: userData.name,
                                    logo: userData.logo,
                                    address: userData.address,
                                    bic: userData.bic,
                                    iban: userData.iban,
                                    firstInstall: userData.firstInstall
                                };
                            }
                        });

                    this.setState({ user: userNfo }, () => {});
                    return this.state.user;
                } catch (error) {
                    //pass
                }
            } else {
                return false;
            }
        };

        const getCatalog = async uid => {
            const user = JSON.parse(localStorage.getItem("token"));
            uid = user.uid;

            if (true === isAuthenticated()) {
                loadCatalog(uid);
                loadCategory(uid);
            }
        };

        const logout = () => {
            FbConfig.auth().signOut();
            localStorage.removeItem("token");
            localStorage.removeItem("catalog");
            localStorage.removeItem("category");
            this.state.user = {};
            this.state = null;
        };

        const setUser = _user => {
            const { address, bic, email, iban, logo, username, uid } = _user;
            this.setState({ user: { address, bic, email, iban, logo, username, uid } });
        };

        this.state = {
            user: this.user,
            login,
            logout,
            isAuthenticated,
            setUser,
            updateUser: getUserData,
            loadCatalog: getCatalog
        };
    }

    componentDidUpdate() {
        window.localStorage.setItem("token", JSON.stringify(this.state.user));
    }

    render() {
        return <AuthenticationContext.Provider value={ this.state }>{ this.props.children }</AuthenticationContext.Provider>;
    }
}

export const useAuthentication = () => {
    return useContext(AuthenticationContext);
};
