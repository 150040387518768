import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React from "react";
import { fullyPaid } from "./BookingTools";
import FbConfig from "../base";

export default function BookingDelete(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);



    const handleClose = () => {
        setOpen(false);
    };

    const values = props.order;
    const orderId = props.id;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const deleteOrder = () => {
        if (true === fullyPaid(values)) {
            enqueueSnackbar("Vous ne pouvez pas effacer une prestation avec des paiements validés", { variant: "warning" });
            return false;
        }

        if (true === values.orderIsConfirmed) {
            enqueueSnackbar("Vous ne pouvez pas effacer une prestation validée", { variant: "warning" });
            return false;
        }

        
        const user = JSON.parse(localStorage.getItem("token"));
        const fbUrl = "orders/" + user.uid + "/" + orderId + "/";

        FbConfig.database()
            .ref(fbUrl)
            .remove()
            .then(function () {
                enqueueSnackbar("La prestation a été effacée", { variant: "success" });
            })
            .catch(() => enqueueSnackbar("Erreur lors de la suppression", { variant: "error" }));
            

    };


    return (
        <>
            <IconButton
                aria-label="Supprimer une prestation"
                onClick={ handleClickOpen }>
                <DeleteIcon />
            </IconButton>
            <Dialog
                open={ open }
                onClose={ handleClose }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    { "Êtes-vous sûr ?" }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Veuillez confirmer que vous souhaitez bien supprimer cette prestation.
                        Attention cette action est irréversible !
                        (Note : vous ne pouvez pas effacer les prestations validées / payées)
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleClose }>Annuler</Button>
                    <Button onClick={ deleteOrder } >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
