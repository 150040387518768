import FbConfig from "../base";

/**
 * Y'a t'il un reste à payer
 * return bool
 */
export function fullyPaid(values) {
    if (false === values.hasPaymentInstalment && true === values.paymentInstalment[1].isPaid) {
        return true;
    } else if (true === values.fullyPaid) {
        return true;
    }
    return false;
}

/**
 *
 */
export function getButtonColor(values) {
    return true === fullyPaid(values) ? "primary" : "secondary";
}

export function updateInvoiceNumber(uid, orderId, type) {
    FbConfig.database()
        .ref("/users/" + uid + "/" + type)
        .once("value", snapshot => {
            let DT = snapshot.val();
            if (DT) {
                if ("quote" === type) {
                    setQuote(orderId, uid, DT);
                } else if ("invoice" === type) {
                    setInvoice(orderId, uid, DT);
                }
            }
        });
}

function setQuote(orderId, uid, DT) {
    FbConfig.database()
        .ref("/users/" + uid)
        .update({
            quote: DT + 1
        })
        .then(() => {
            var fbSet = FbConfig.database().ref("/orders/" + uid + "/" + orderId);

            fbSet.update({
                quoteNumber: DT + 1
            });
        });
}

function setInvoice(orderId, uid, DT) {
    FbConfig.database()
        .ref("/users/" + uid)
        .update({
            invoice: DT + 1
        })
        .then(() => {
            var fbSet = FbConfig.database().ref("/orders/" + uid + "/" + orderId);

            fbSet.update({
                invoiceNumber: DT + 1
            });
        });
}
