import { StyleSheet, Text, View } from "@react-pdf/renderer";
// eslint-disable-next-line no-unused-vars
import React, { Fragment } from "react";

const borderColor = "grey";
const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: "#D4CDCD",
        borderBottomWidth: 1,
        alignItems: "center",
        minHeight: 24,
        fontStyle: "bold"
    },
    description: {
        width: "60%",
        textAlign: "left",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8
    },
    qty: {
        width: "10%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: "right",
        paddingRight: 8
    },
    rate: {
        width: "15%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: "right",
        paddingRight: 8
    },
    amount: {
        width: "15%",
        textAlign: "right",
        paddingRight: 8
    }
});

const InvoiceTableRow = ({ items }) => {
    const rows = items.map(item => (
        <View style={ styles.row } key={ item.sno.toString() }>
            <Text style={ styles.description }>{ item.description }</Text>
            <Text style={ styles.qty }>{ item.qte }</Text>
            <Text style={ styles.rate }>{ item.price }</Text>
            <Text style={ styles.amount }>{ item.price }</Text>
        </View>
    ));
    return <Fragment>{ rows }</Fragment>;
};

export default InvoiceTableRow;
