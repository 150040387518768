import { StyleSheet, Text, View } from "@react-pdf/renderer";

// eslint-disable-next-line no-unused-vars
import React from "react";

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: "row",
        marginTop: 22
    },
    reportTitle: {
        color: "#012a36",
        letterSpacing: 4,
        fontSize: 22,
        textAlign: "center",
        textTransform: "uppercase"
    }
});

const InvoiceTitle = ({ title }) => (
    <View style={ styles.titleContainer }>
        <Text style={ styles.reportTitle }>{ title }</Text>
    </View>
);

export default InvoiceTitle;
