/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { getDuration, loadCatalog } from "./CatalogTools";
import { loadCategory } from "./Category";
import FbConfig from "../base";
import { FormikCheckbox, FormikSelect, FormikTextField } from "@dccs/react-formik-mui";
import { Grid, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Menu from "@material-ui/core/Menu";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import EuroIcon from "@material-ui/icons/Euro";
import { Form, Formik } from "formik";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { useSnackbar } from "notistack";
// eslint-disable-next-line no-unused-vars
import React from "react";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
    gridColor: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridTemplateRows: "repeat(6, 1fr)",
        gridColumnGap: 0,
        gridRowGap: 0
    },
    color: {
        height: 21,
        width: 21,
        borderRadius: 5,
        margin: theme.spacing(1),
        cursor: "pointer"
    },
    noColor: {
        height: 19,
        width: 19,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "lightgray",
        borderRadius: 5,
        margin: theme.spacing(1),
        cursor: "pointer"
    },
    alignCenter: {
        display: "flex",
        alignItems: "center",
        fontSize: 18
    }
}));

const SignupSchema = Yup.object().shape({
    name: Yup.string().ensure().required("Le titre est obligatoire"),
    price: Yup.number().positive("Le prix doit être positif").required("Le prix est obligatoire"),
    type: Yup.string().ensure().required("Vous devez choisir un type de prestation"),
    duration: Yup.string().ensure().required("Vous devez choisir un durée")
});

export default function CatalogEdit(props) {
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const user = JSON.parse(localStorage.getItem("token"));
    const classes = useStyles();
    const item = props.item;
    const action = props.action;

    const typeCategory = () => {
        const category = null !== localStorage.getItem("category") ? JSON.parse(localStorage.getItem("category")) : loadCategory(user.uid);
        return category;
    };

    const getTypeByName = () => {
        if (!item.type) {
            return null;
        }
        let getItemType = typeCategory().find(types => types.name === item.type);
        if (!getItemType) {
            return null;
        }
        return getItemType.id;
    };

    const storeData = data => {
        let getItemType = typeCategory().find(types => types.id === data["type"]);
        let firebaseURL = "catalog/" + user.uid + "/";

        if ("edit" === action) {
            firebaseURL += data.itemId;
            FbConfig.database()
                .ref(firebaseURL)
                .set({
                    name: data["name"],
                    type: getItemType.name,
                    price: data["price"],
                    duration: true === data["durationAllday"] ? null : data["duration"],
                    payOnline: data["payOnline"],
                    bookOnline: data["bookOnline"],
                    paymentInstalment: data["paymentInstalment"],
                    nbPaymentInstalment: data["nbPaymentInstalment"],
                    description: data["description"],
                    active: data["active"],
                    color: data["color"]
                })
                .then(() => {
                    enqueueSnackbar("Enregistrement des modifications", {
                        variant: "success"
                    });
                    handleClose();
                })
                .catch(() => {
                    enqueueSnackbar("Un problème est survenu à l'enregistrement des modifications", { variant: "error" });
                    handleClose();
                });
        } else {
            FbConfig.database()
                .ref(firebaseURL)
                .push({
                    name: data["name"],
                    type: getItemType.name,
                    price: data["price"],
                    duration: data["duration"],
                    payOnline: data["payOnline"],
                    bookOnline: data["bookOnline"],
                    paymentInstalment: data["paymentInstalment"],
                    nbPaymentInstalment: data["nbPaymentInstalment"],
                    description: data["description"],
                    active: data["active"],
                    color: data["color"]
                })
                .then(() => {
                    enqueueSnackbar("La nouvelle prestation a bien été enregistrée", {
                        variant: "success"
                    });
                    handleClose();
                })
                .catch(() => {
                    enqueueSnackbar("Un problème est survenu à l'enregistrement de la prestation", { variant: "error" });
                    handleClose();
                });
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addItem = () => {
        return (
            <>
                <Button variant="contained" color="primary" startIcon={ <AddIcon /> } onClick={ () => handleClickOpen() }>
                    Ajouter un produit
                </Button>
            </>
        );
    };

    const editItem = () => {
        return (
            <>
                <IconButton aria-label="Modifier" onClick={ () => handleClickOpen() }>
                    <EditIcon />
                </IconButton>
            </>
        );
    };

    return (
        <>
            { "edit" === action ? editItem() : addItem() }
            <Dialog open={ open } onClose={ handleClose } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Ajouter un nouveau produit</DialogTitle>
                <Formik
                    validateOnBlur={ false }
                    validateOnChange={ false }
                    validationSchema={ SignupSchema }
                    initialValues={ {
                        name: "edit" === action ? item.name : null,
                        type: "edit" === action ? getTypeByName() : null,
                        price: "edit" === action ? item.price : 0,
                        duration: "edit" === action ? item.duration : null,
                        payOnline: "edit" === action ? item.payOnline : true,
                        bookOnline: "edit" === action ? item.bookOnline : true,
                        paymentInstalment: "edit" === action ? item.paymentInstalment : false,
                        nbPaymentInstalment: "edit" === action ? item.nbPaymentInstalment : false,
                        description: "edit" === action && item.description ? item.description : null,
                        active: "edit" === action ? item.active : true,
                        color: "edit" === action ? item.color : "#ffffff00"
                    } }
                    onSubmit={ (values, { setSubmitting }) => {
                        setTimeout(() => {
                            if ("edit" === action) {
                                values["itemId"] = item.id;
                            }
                            storeData(values);
                            loadCatalog(user.uid);
                            setSubmitting(false);
                        }, 400);
                    } }>
                    { ({ isSubmitting, values, setFieldValue }) => (
                        <Form noValidate>
                            <DialogContent>
                                <Grid container spacing={ 1 }>
                                    <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                                        <FormikSelect
                                            id="type"
                                            label="Type"
                                            name="type"
                                            required
                                            fullWidth
                                            options={ typeCategory().map(item => ({
                                                key: item.id,
                                                value: item.name
                                            })) }
                                        />
                                    </Grid>
                                    <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                                        <FormikTextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="price"
                                            name="price"
                                            label="Prix"
                                            type="text"
                                            InputProps={ {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EuroIcon />
                                                    </InputAdornment>
                                                )
                                            } }
                                        />
                                    </Grid>
                                    <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                                        <FormikTextField margin="normal" required fullWidth id="name" name="name" label="Titre" type="text" />
                                    </Grid>
                                    <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                                        <FormikTextField label="Description" name="description" id="description" multiline fullWidth margin="normal" />
                                    </Grid>
                                    <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                                        { (!values.durationAllday || false === values.durationAllday) && (
                                            <FormikSelect
                                                id="duration"
                                                label="Durée"
                                                name="duration"
                                                required
                                                fullWidth
                                                options={ getDuration().map(item => ({
                                                    key: item.id,
                                                    value: item.name
                                                })) }
                                            />
                                        ) }
                                    </Grid>
                                    <Grid item xs={ 12 } sm={ 6 } md={ 6 } style={ { alignSelf: "self-end" } }>
                                        <PopupState variant="popover" popupId="demo-popup-menu">
                                            { popupState => (
                                                <React.Fragment>
                                                    <div className={ classes.alignCenter }>
                                                        Couleur :&nbsp;
                                                        <div { ...bindTrigger(popupState) } className={ !values["color"] || values["color"] === "#ffffff00" ? classes.noColor : classes.color } style={ { backgroundColor: values["color"] } } />
                                                    </div>
                                                    <Menu { ...bindMenu(popupState) }>
                                                        <div className={ classes.gridColor }>
                                                            { ["#d50000", "#e67c73", "#f4511e", "#f6bf26", "#33b679", "#0b8043", "#039be5", "#3f51b5", "#7986cb", "#8e24aa", "#616161"].map(item => (
                                                                <div
                                                                    key={ item }
                                                                    className={ classes.color }
                                                                    style={ { backgroundColor: item } }
                                                                    onClick={ () => {
                                                                        setFieldValue("color", item);
                                                                        popupState.close();
                                                                    } }></div>
                                                            )) }
                                                            <div
                                                                key={ "#ffffff00" }
                                                                className={ classes.noColor }
                                                                style={ { backgroundColor: "#ffffff00" } }
                                                                onClick={ () => {
                                                                    setFieldValue("color", "#ffffff00");
                                                                    popupState.close();
                                                                } }></div>
                                                        </div>
                                                    </Menu>
                                                </React.Fragment>
                                            ) }
                                        </PopupState>
                                    </Grid>

                                    <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                        <FormikCheckbox label="Payable en ligne" name="payOnline" id="payOnline" color="primary" />
                                    </Grid>
                                    <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                        <FormikCheckbox label="Réservable en ligne" name="bookOnline" id="bookOnline" color="primary" />
                                    </Grid>
                                    <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                                        <FormikCheckbox label="Payable en 3x fois" name="paymentInstalment" id="paymentInstalment" color="primary" />
                                    </Grid>

                                    { /* {true === values.paymentInstalment && (
                      <>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormikTextField
                            margin="normal"
                            id="paymentInstalment1"
                            name="paymentInstalment1"
                            label="1er paiement en %"
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormikTextField
                            margin="normal"
                            id="paymentInstalment2"
                            name="paymentInstalment2"
                            label="2eme paiement en %"
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <FormikTextField
                            margin="normal"
                            id="paymentInstalment3"
                            name="paymentInstalment3"
                            label="3eme paiement  en %"
                            type="number"
                          />
                        </Grid>
                      </>
                    )} */ }
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={ () => handleClose() } color="primary">
                                    Annuler
                                </Button>
                                <Button type="submit" disabled={ isSubmitting } color="primary">
                                    Valider
                                </Button>
                            </DialogActions>
                        </Form>
                    ) }
                </Formik>
            </Dialog>
        </>
    );
}
