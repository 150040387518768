import BookingPdf from "./BookingPdf";
import { updateInvoiceNumber } from "../booking/BookingTools";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React from "react";

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5"
    }
})(props => (
    <Menu
        elevation={ 0 }
        getContentAnchorEl={ null }
        anchorOrigin={ {
            vertical: "bottom",
            horizontal: "center"
        } }
        transformOrigin={ {
            vertical: "top",
            horizontal: "center"
        } }
        { ...props }
    />
));

const StyledMenuItem = withStyles(theme => ({
    root: {
        "&:focus": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white
            }
        }
    }
}))(MenuItem);

export default function BookingMenu(props) {
    const user = JSON.parse(localStorage.getItem("token"));
    const { enqueueSnackbar } = useSnackbar();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const v = props.order;
    const id = props.id;
    const idFacture = v.invoiceNumber;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const createInvoice = () => {
        updateInvoiceNumber(user.uid, id, "invoice");
        enqueueSnackbar("La facture a bien été créee", {
            variant: "success"
        });
    };

    return (
        <>
            <IconButton color="primary" aria-label="Editer une prestation" onClick={ handleClick }>
                <PictureAsPdfIcon />
            </IconButton>

            <StyledMenu id="customized-menu" anchorEl={ anchorEl } keepMounted open={ Boolean(anchorEl) } onClose={ handleClose }>
                <StyledMenuItem>
                    <BookingPdf id={ id } order={ v } title="Devis" />
                </StyledMenuItem>
                { !idFacture && (
                    <StyledMenuItem>
                        <ListItemIcon>
                            <IconButton aria-label="Générer la facture" onClick={ () => createInvoice() }>
                                <ReceiptIcon />
                            </IconButton>
                        </ListItemIcon>
                        <ListItemText primary="Générer la facture" onClick={ () => createInvoice() } />
                    </StyledMenuItem>
                ) }
                <StyledMenuItem>
                    <BookingPdf id={ id } order={ v } title="Facture" />
                </StyledMenuItem>
            </StyledMenu>
        </>
    );
}
