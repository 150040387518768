import { Divider, Grid, makeStyles, withStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputBase from "@material-ui/core/InputBase";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import EditIcon from "@material-ui/icons/Edit";
import FaceIcon from "@material-ui/icons/Face";
import FilterListIcon from "@material-ui/icons/FilterList";
import HomeIcon from "@material-ui/icons/Home";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import MapIcon from "@material-ui/icons/Map";
import { ascend, prop, sortWith } from "ramda";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { useObjectVal } from "react-firebase-hooks/database";
import { Redirect, useHistory  } from "react-router-dom";
import BookingDelete from "./BookingDelete";
import BookingIcal from "./BookingIcal";
import BookingMenu from "./BookingMenu";
import BookingTodo from "./BookingTodo";
import BookingUpload from "./BookingUpload";
import BookingValidated from "./BookingValidated";
import BookingEditValidatePayment from "./BookingValidatePayment";
import FbConfig from "../base";
import { loadCatalog } from "../catalog/CatalogTools";
import ClientCall from "../client/ClientCall";
import ClientInfo from "../client/ClientInfo";

const BootstrapInput = withStyles(theme => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3)
        }
    },
    input: {
        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        // Use the system font instead of the default Roboto font.
        fontFamily: ["-apple-system", "BlinkMacSystemFont", "\"Segoe UI\"", "Roboto", "\"Helvetica Neue\"", "Arial", "sans-serif", "\"Apple Color Emoji\"", "\"Segoe UI Emoji\"", "\"Segoe UI Symbol\""].join(","),
        "&:focus": {
            borderRadius: 4,
            borderColor: "#80bdff",
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
        }
    }
}))(InputBase);

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset"
        }
    },
    subContainerAction: {
        textAlign: "center",
        //border: "1px solid black",
        height: "54px",
        width: "auto",
        textOverflow: "ellipsis",
        overflow: "hidden",
        lineHeight: "40px"
    },
    hide: {
        display: "none"
    }
});

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    },
    subContainerDate: {
        textAlign: "center",
        //border: "1px solid black",
        height: "40px",
        width: "auto",
        textOverflow: "ellipsis",
        overflow: "hidden",
        lineHeight: "40px",
        backgroundColor: "#3f50b552",
        color: "white"
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    }
}));

const useToolbar = makeStyles(theme => ({
    root: {
        "& a": {
            textDecoration: "none"
        },
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    title: {
        flex: 1
    },
    inputRoot: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 250
    },
    menuItem: {
        display: "flex",
        justifyContent: "space-between"
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300
    },
    noLabel: {
        marginTop: theme.spacing(3)
    },
    titleFilter: {
        fontWeight: "bold",
        justifyContent: "center"
    }
}));

const useCellStyles = makeStyles(theme => ({
    disNone720: {
        [theme.breakpoints.down("720")]: {
            display: "none"
        }
    },
    dispNone890: {
        [theme.breakpoints.down("890")]: {
            display: "none"
        }
    },
    maps: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& svg": {
            marginLeft: "10px",
            color: "#6686cc"
        }
    }
}));

function YearOnChange(event, setCurrentYear, currentYear, year) {
    setCurrentYear([year.indexOf(event.target.value)]);
}

function MonthOnChange(event, setCurrentMonth, currentMonth, month) {
    setCurrentMonth(month.indexOf(event.target.value));
}

function EnhancedTableToolbar(props) {
    const { bookingListWithIdOrderByDate, handleChangeNotValid, handleChangeValid, client, setCurrentMonth, setCurrentYear, setSelectCateg, currentMonth, FilterNotValidatedOnly, handleChange, currentYear, selectCateg, FilterUpcomingOnly, FilterValidatedOnly, history, catalog, month, categ, year } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useToolbar();


    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeC = event => {
        setSelectCateg(event.target.value);
    };

    let clientName = client ? bookingListWithIdOrderByDate.find(b => b.client_id === client)?.client : null;

    if (client && !clientName) {
        return <Redirect to="/orders" />;
    }

    return (
        <Toolbar className={ classes.root }>
            <Typography className={ classes.title } variant="h6" id="tableTitle" component="div">
                Commandes & Rendez-vous
            </Typography>
            { clientName && <Chip
                icon={ <FaceIcon /> }
                label={ clientName }
                onDelete={ () => history.push("/orders") }
            /> }
            <Tooltip title="Filter list">
                <IconButton aria-label="filter list" onClick={ handleClick }>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
            <Menu id="simple-menu" anchorEl={ anchorEl } keepMounted open={ Boolean(anchorEl) } onClose={ handleClose }>
                <MenuItem disabled className={ classes.titleFilter }>
                    Filters
                </MenuItem>
                <Divider style={ { margin: "0 6px" } } />
                <MenuItem className={ classes.menuItem }>
                    Catégorie :
                    <FormControl className={ classes.formControl } variant="outlined">
                        <Select
                            multiple
                            value={ selectCateg }
                            onChange={ handleChangeC }
                            input={ <Input /> }
                            renderValue={ selected => (
                                <div className={ classes.chips }>
                                    { selected.map(value => {
                                        let _color = catalog.find(it => it.type === value);
                                        let style = {};
                                        if (_color && _color.color && _color.color !== "#ffffff00") {
                                            style.background = _color.color;
                                            style.color = "white";
                                        }

                                        return <Chip key={ value } label={ value } className={ classes.chip } style={ style } />;
                                    }) }
                                </div>
                            ) }
                        >
                            { categ.map(name => {
                                let _color = catalog.find(it => it.type === name);
                                let style = {};
                                if (_color && _color.color && _color.color !== "#ffffff00") style.color = _color.color;

                                return (
                                    <MenuItem key={ name } value={ name }>
                                        <Checkbox checked={ selectCateg.indexOf(name) > -1 } style={ style } />
                                        <ListItemText primary={ name } />
                                    </MenuItem>
                                );
                            }) }
                        </Select>
                    </FormControl>
                </MenuItem>
                <MenuItem className={ classes.menuItem }>
                    Prestations à venir <Switch checked={ FilterUpcomingOnly } onChange={ handleChange } />
                </MenuItem>
                <MenuItem className={ classes.menuItem }>
                    Prestations validées <Switch checked={ FilterValidatedOnly } onChange={ handleChangeValid } />
                </MenuItem>
                <MenuItem className={ classes.menuItem }>
                    Prestations non validées <Switch checked={ FilterNotValidatedOnly } onChange={ handleChangeNotValid } />
                </MenuItem>
                <MenuItem className={ classes.menuItem }>
                    Années :
                    <div style={ { padding: "10px 0", textAlign: "center" } }>
                        <IconButton color="primary" aria-label="année précédente" onClick={ () => setCurrentYear([currentYear[0] - 1]) } disabled={ currentYear[0] === 0 }>
                            <ArrowBackIosIcon fontSize="inherit" />
                        </IconButton>
                        <Select labelId="demo-customized-select-label" id="demo-customized-select" onChange={ event => YearOnChange(event, setCurrentYear, currentYear[0], year) } input={ <BootstrapInput /> } value={ year[currentYear[0]] }>
                            { year.map((v, i) => {
                                return (
                                    <MenuItem value={ v } key={ `MenuItem-${i}-${v}` }>
                                        { v }
                                    </MenuItem>
                                );
                            }) }
                        </Select>
                        <IconButton color="primary" aria-label="année suivante" onClick={ () => setCurrentYear([currentYear[0] + 1]) } disabled={ currentYear[0] + 1 > year.length - 1 }>
                            <ArrowForwardIosIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                </MenuItem>
                <MenuItem className={ classes.menuItem }>
                    Mois :
                    <div style={ { padding: "10px 0", textAlign: "center" } }>
                        <IconButton color="primary" aria-label="mois précédent" onClick={ () => setCurrentMonth(currentMonth - 1) } disabled={ currentMonth === 0 }>
                            <ArrowBackIosIcon fontSize="inherit" />
                        </IconButton>
                        <Select labelId="demo-customized-select-label" id="demo-customized-select" onChange={ event => MonthOnChange(event, setCurrentMonth, currentMonth, month) } input={ <BootstrapInput /> } value={ month[currentMonth] }>
                            { month.map((v, i) => {
                                let countFil = bookingListWithIdOrderByDate.filter(it => (currentYear[0] === 0 || year[currentYear[0]] === new Date(it.date).getFullYear()) && (i === 0 || new Date(it.date).getMonth() + 1 === i) && (!FilterUpcomingOnly || new Date() < new Date(it.date))).length;
                                return (
                                    <MenuItem value={ v } key={ `MenuItem-${i}-${v}` }>
                                        { v } { countFil !== 0 && "(" + countFil + ")" }{ " " }
                                    </MenuItem>
                                );
                            }) }
                        </Select>
                        <IconButton color="primary" aria-label="mois suivant" onClick={ () => setCurrentMonth(currentMonth + 1) } disabled={ currentMonth + 1 > month.length - 1 }>
                            <ArrowForwardIosIcon fontSize="inherit" />
                        </IconButton>
                    </div>
                </MenuItem>
            </Menu>
        </Toolbar>
    );
}

function Row(props) {
    const { v, date, editOrder, catalog } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const cell = useCellStyles();
    const dateEvent = date.toLocaleString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long"
    });
    const dateEvent2 = date.toLocaleString("fr-FR", {
        hour: "numeric",
        minute: "numeric"
    });

    let color = catalog.find(it => it.itemId === v.catalogId)?.color || "#ffffff00";
    let style = {};
    if (v.orderIsConfirmed) style.background = "aliceblue";
    if (color && color !== "#ffffff00") style.borderLeft = `5px solid ${color}`;
    return (
        <React.Fragment>
            <TableRow className={ classes.root } style={ style }>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={ () => setOpen(!open) }>
                        { open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <div>
                        <Chip
                            label={ v.catalogType }
                            style={ {
                                color: color !== "#ffffff00" ? "#fff" : "#000",
                                border: `1px ${color === "#ffffff00" ? "lightgray" : color} solid`,
                                backgroundColor: color
                            } }
                        />
                    </div>
                </TableCell>
                <TableCell align="right">
                    <Tooltip title={ `${dateEvent} ${dateEvent2}` } placement="top">
                        <span>
                            <span>{ dateEvent }</span>
                            <span className={ cell.dispNone890 }>{ " " + dateEvent2 }</span>
                        </span>
                    </Tooltip>
                </TableCell>
                <TableCell align="right">
                    <span className={ cell.disNone720 }>{ v.client }</span>
                    <ClientInfo id={ v.client_id } />
                </TableCell>
                <TableCell align="right" className={ cell.disNone720 }>
                    { "RDV" !== v.catalogType && v.priceToPay + "€" }
                </TableCell>
                <TableCell align="right">
                    <div>
                        { "RDV" === v.catalogType && "home" === v.where ? (
                            <HomeIcon fontSize="default" />
                        ) : (
                            <div className={ cell.maps }>
                                <span className={ cell.disNone720 }>{ v.where.slice(0, 60) }</span>
                                <a href={ `https://www.google.com/maps/search/?api=1&query=${v.where}&query_place_id=${v.placeId}` } target="_blank" rel="noreferrer">
                                    <MapIcon />
                                </a>
                            </div>
                        ) }
                    </div>
                </TableCell>
            </TableRow>
            <TableRow style={ style }>
                <TableCell style={ { paddingBottom: 0, paddingTop: 0 } } colSpan={ 6 }>
                    <Collapse in={ open } timeout="auto" unmountOnExit>
                        <Box margin={ 1 }>
                            <div className={ classes.subContainerAction }>
                                { "RDV" !== v.catalogType && <BookingValidated id={ v.id } order={ v } /> }
                                <ClientCall order={ v } />
                                { "RDV" !== v.catalogType && <BookingEditValidatePayment id={ v.id } order={ v } /> }
                                <BookingIcal order={ v } />
                                { "RDV" !== v.catalogType && (
                                    <IconButton aria-label="Editer une prestation" onClick={ () => editOrder(v.id) }>
                                        <EditIcon />
                                    </IconButton>
                                ) }
                                <BookingTodo id={ v.id } order={ v } />
                                { "RDV" !== v.catalogType && <BookingMenu id={ v.id } order={ v } /> }
                                <BookingUpload id={ v.id } order={ v } />
                                <BookingDelete id={ v.id } order={ v } />
                            </div>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const DatabaseList = props => {
    const user = JSON.parse(localStorage.getItem("token"));

    const [currentMonth, setCurrentMonth] = useState(0);
    const [currentYear, setCurrentYear] = useState([null]);
    const [selectCateg, setSelectCateg] = useState([]);
    const [catalog, setCatalog] = useState([]);

    const [FilterValidatedOnly, setFilterValidatedOnly] = useState(false);
    const [FilterNotValidatedOnly, setFilterNotValidatedOnly] = useState(false);
    const [FilterUpcomingOnly, FilterUpcomingOnlySet] = useState(false);

    const classes = useStyles();
    const cell = useCellStyles();
    const history = useHistory();
    let year = [];
    let month = ["All", "Janv", "Fevr", "Mars", "Avri", "Mai", "Juin", "Juil", "Aout", "Sept", "Octo", "Nove", "Dece"];
    let categ = [];
    let renderPrint = 0;

    function storageEventHandler(e) {
        if (e.key === "catalog") {
            setCatalog(JSON.parse(e.newValue) || null);
        }
    }

    useEffect(() => {
        if (!localStorage.getItem("catalog")) {
            loadCatalog(user.uid);
        } else if (localStorage.getItem("catalog") && !catalog.length) {
            setCatalog(JSON.parse(localStorage.getItem("catalog")));
        }

        window.addEventListener("storage", storageEventHandler, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [value, loading, error] = useObjectVal(FbConfig.database().ref("orders/" + user.uid + "/"));

    let bookingListWithId = null;
    let bookingListWithIdOrderByDate = null;
    
    if (value) {
        const ids = Object.keys(value);
        const values = Object.values(value);
        let tmpYear = [];
        let tmpCateg = [];

        bookingListWithId = values.map((value, index) => {
            tmpYear.push(parseInt(new Date(value.date).getFullYear()));
            tmpCateg.push(value.catalogType);
            return Object.assign({}, value, { id: ids[index] });
        });

        year = [...new Set(tmpYear)].sort();
        categ = [...new Set(tmpCateg)].sort();
        year.unshift("All");
        const propDate = prop("date");
        const filterDate = sortWith([ascend(propDate)]);

        bookingListWithIdOrderByDate = filterDate(bookingListWithId);
        
        if (currentYear[0] === null) {
            if (year.includes(new Date().getFullYear())) currentYear[0] = year.indexOf(new Date().getFullYear());
            else currentYear[0] = 0;
        }
    }

    function editOrder(id) {
        history.push("edit_order/" + id);
    }

    const handleChange = () => {
        FilterUpcomingOnlySet(!FilterUpcomingOnly);
    };

    const handleChangeValid = () => {
        setFilterValidatedOnly(!FilterValidatedOnly);
    };

    const handleChangeNotValid = () => {
        setFilterNotValidatedOnly(!FilterNotValidatedOnly);
    };

    return (
        <div>
            <React.Fragment>
                { error && <strong>Error: { error }</strong> }
                { loading && <span>List: Loading...</span> }
                { !loading && value && (
                    <main>
                        <Paper className={ classes.paper }>
                            <EnhancedTableToolbar
                                bookingListWithIdOrderByDate={ bookingListWithIdOrderByDate }
                                handleChangeNotValid={ handleChangeNotValid }
                                handleChangeValid={ handleChangeValid }
                                client={ props?.match?.params?.clientId }
                                setCurrentMonth={ setCurrentMonth }
                                setSelectCateg={ setSelectCateg }
                                setCurrentYear={ setCurrentYear }
                                handleChange={ handleChange }
                                currentMonth={ currentMonth }
                                FilterNotValidatedOnly={ FilterNotValidatedOnly }
                                selectCateg={ selectCateg }
                                currentYear={ currentYear }
                                FilterUpcomingOnly={ FilterUpcomingOnly }
                                FilterValidatedOnly={ FilterValidatedOnly }
                                history={ history }
                                catalog={ catalog }
                                month={ month }
                                categ={ categ }
                                year={ year }
                            />
                            <TableContainer component={ Paper }>
                                <Table aria-label="collapsible table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>Type</TableCell>
                                            <TableCell align="right">Date</TableCell>
                                            <TableCell align="right">Client</TableCell>
                                            <TableCell align="right" className={ cell.disNone720 }>
                                                Prix
                                            </TableCell>
                                            <TableCell align="right">Où</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { bookingListWithIdOrderByDate.map((v, i) => {
                                            let date = new Date(v.date);

                                            if (currentYear[0] !== 0 && year[currentYear[0]] !== date.getFullYear()) return null;
                                            if (currentMonth !== 0 && currentMonth !== date.getMonth() + 1) return null;
                                            if (selectCateg.length && !selectCateg.includes(v.catalogType)) return null;
                                            if (FilterValidatedOnly && !v.orderIsConfirmed) return null;
                                            if (FilterNotValidatedOnly && v.orderIsConfirmed) return null;
                                            if (FilterUpcomingOnly && new Date() >= date) return null;
                                            if (props?.match?.params?.clientId && props.match.params.clientId !== v.client_id) return null;

                                            renderPrint += 1;
                                            return <Row v={ v } date={ date } editOrder={ editOrder } catalog={ catalog } key={ `Row-${i}-dayEvent` } />;
                                        }) }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            { renderPrint === 0 ? <div style={ { position: "fixed", top: "50%", left: "50%" } }>Il n&apos;y a pas RDV sur la période séléctionner</div> : null }
                        </Paper>
                    </main>
                ) }
                { !value && <div className={ classes.root }>{ "Pas de rendez-vous ou de prestation programmés pour l'instant !" }</div> }
            </React.Fragment>
        </div>
    );
};

export default function BookingList(props) {
    const listOrders = DatabaseList(props);

    return <>{ listOrders }</>;
}

export function DateVersus(prevDate, date, classes) {
    let FullYear = null;

    if (new Date(prevDate).getFullYear() !== new Date(date).getFullYear()) {
        FullYear = new Date(date).getFullYear();

        return (
            <Paper elevation={ 4 } className={ classes.paper }>
                <Grid item xs={ 12 } md={ 12 }>
                    <div className={ classes.subContainerDate }>{ FullYear }</div>
                </Grid>
            </Paper>
        );
    }
}
