import BankDetails from "./BankDetails";
import Entreprise from "./Entreprise";
import EntrepriseLogo from "./EntrepriseLogo";
import Settings from "./Settings";
import UserAccount from "./UserAccount";
import FbConfig from "../base";
import { CircularIndeterminate } from "../utils/humanRead";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justifyContent: "center"
    },
    paper: {
        padding: theme.spacing(2)
    },
    control: {
        padding: theme.spacing(2)
    }
}));

export default function UserSettings() {
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem("token"));

    const [userInfo, setUserInfo] = useState(false);
    const [loading, setLoading] = useState(true);
    const isMountedRef = React.useRef(null);

    useEffect(() => {
        isMountedRef.current = true;

        if (loading) {
            FbConfig.database()
                .ref("users/" + user.uid + "/")
                .on("value", snapshot => {
                    if (isMountedRef.current) {
                        setUserInfo(snapshot.val());
                        setLoading(false);
                    }
                });
        }
        return () => (isMountedRef.current = false);
    });

    if (loading) {
        return <CircularIndeterminate />;
    }

    return (
        <Grid container className={ classes.root } spacing={ 2 }>
            <Grid key={ "group-1" } item xs={ 12 } sm={ 6 }>
                <Grid container spacing={ 2 }>
                    <Grid key={ "Account-settings" } item xs={ 12 }>
                        <UserAccount userInfo={ userInfo } />
                    </Grid>
                    <Grid key={ "Entreprise-settings" } item xs={ 12 }>
                        <Entreprise userInfo={ userInfo } />
                    </Grid>
                </Grid>
            </Grid>
            <Grid key={ "group-2" } item xs={ 12 } sm={ 6 }>
                <Grid container spacing={ 2 }>
                    <Grid key={ "BankDetails-settings" } item xs={ 12 }>
                        <BankDetails userInfo={ userInfo } />
                    </Grid>
                    <Grid key={ "Entreprise-settings" } item xs={ 12 }>
                        <Settings userInfo={ userInfo } />
                    </Grid>
                    <Grid key={ "Logo-settings" } item xs={ 12 }>
                        <EntrepriseLogo userInfo={ userInfo } />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
