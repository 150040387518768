import Field from "./components/Field";
import Header from "./components/Header";
import Validate from "./components/Validate";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import ImageIcon from "@material-ui/icons/Image";
import PublishIcon from "@material-ui/icons/Publish";
import clsx from "clsx";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justifyContent: "center"
    },
    fields: {
        display: "flex",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        flexDirection: "column"
    },
    containerAvatar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    avatar: {
        width: theme.spacing(25),
        height: theme.spacing(25),
        borderWidth: 2,
        borderColor: "#E6E6E6",
        borderRadius: "50%"
    },
    displayNone: {
        display: "none"
    },
    input0: {
        opacity: 0,
        width: theme.spacing(25),
        height: theme.spacing(25),
        position: "absolute",
        cursor: "pointer"
    },
    icon0: {
        color: "#FFF",
        position: "absolute",
        fontSize: theme.spacing(8),
        filter: "drop-shadow( 0px 0px 15px rgba(0, 0, 0, 1))"
    }
}));

export default function EntrepriseLogo({ userInfo }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [d, setData] = useState({ logo: userInfo.logo });
    const data = { d, setData, userInfo };

    useEffect(() => {
        setData({ logo: userInfo.logo });
    }, [userInfo]);

    // check if file is an image
    const checkFile = file => {
        const fileType = file.type;
        const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
        return validImageTypes.includes(fileType);
    };

    const fileToBase64 = file => {
        if (!checkFile(file)) {
            return enqueueSnackbar("File is not an image", { variant: "error" });
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        })
            .then(base64 => {
                setData({ logo: base64 });
            })
            .catch(err => {
                enqueueSnackbar(err, { variant: "error" });
            });
    };

    return (
        <Card className={ classes.root }>
            <Header title="Logo" subheader="personnaliser votre interface" icon={ <ImageIcon /> } />
            <CardContent>
                <FormControl component="fieldset" fullWidth>
                    <div className={ classes.containerAvatar }>
                        <img src={ d.logo } alt="logo" className={ classes.avatar } />
                        <PublishIcon className={ classes.icon0 } />
                        <input type="file" id="avatar" className={ classes.input0 } accept="image/*" onChange={ e => fileToBase64(e.target.files[0]) } />
                    </div>
                    <div className={ clsx(classes.fields, classes.displayNone) }>
                        <Field id="logo" label="Logo" data={ data } />
                    </div>
                </FormControl>
            </CardContent>
            <Validate data={ data } />
        </Card>
    );
}
