import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";

// eslint-disable-next-line no-unused-vars
import React from "react";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main
    }
}));

export default function Header({ icon, title, subheader }) {
    const classes = useStyles();

    return <CardHeader titleTypographyProps={ { variant: "h6" } } title={ title } subheader={ subheader } className={ classes.card } avatar={ <Avatar className={ classes.avatar }>{ icon }</Avatar> } />;
}
