import FbConfig from "../base";
import BookingAddFee from "../booking/BookingAddFee";
import BookingAddNewLocation from "../booking/BookingAddNewLocation";
import BookingDiscount from "../booking/BookingDiscount";
import { updateInvoiceNumber } from "../booking/BookingTools";
import { getCatalogItemById, loadCatalog } from "../catalog/CatalogTools";
import AutocompleteClient from "../client/AutocompleteClient";

import Client from "../client/Client";
import { calcDiscountValue, calcPriceTotal, getCurrentDate } from "../Tools";
import { dynamicSortMultiple } from "../utils/humanRead";
import DateFnsUtils from "@date-io/date-fns";
import { FormikDateTimepicker, FormikSelect, FormikTextField } from "@dccs/react-formik-mui";
import { FormControl, FormLabel, Grid, IconButton, Paper, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CancelIcon from "@material-ui/icons/Cancel";
import CommentIcon from "@material-ui/icons/Comment";
import EuroIcon from "@material-ui/icons/Euro";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    form: {
        marginTop: theme.spacing(1),
        width: "100%"
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    },
    paddingTable: {
        padding: "5px"
    },
    tableRow: {
        backgroundColor: "#f5f5f5"
    }
}));

const SignupSchema = Yup.object().shape({
    catalog: Yup.string().ensure().required("Vous devez sélectionner une prestation"),
    price: Yup.number().integer("Le prix doit être un chiffre ou un nombre").required("Vous devez avoir un prix"),
    dateOrder: Yup.date().required("Vous devez choisir une date pour la prestation")
});

export default function BookingAdd(props) {
    const user = JSON.parse(localStorage.getItem("token"));

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [order, setOrder] = React.useState("");
    const [getClientId, setClientId] = React.useState("");
    const [allClients, setClients] = React.useState([]);
    const [noClients, setNoClients] = React.useState(false);

    const [allLocations, setAllLocations] = React.useState([]);

    const [allFees, setAllFees] = React.useState([]);
    const [sumFees, setSumFees] = React.useState(0);

    const [discounts, setDiscounts] = React.useState([]);

    const [orderIsLoading, setOrderLoading] = React.useState(false);
    const [_orderId, setOrderId] = React.useState("");

    const catalogList = null !== localStorage.getItem("catalog") && localStorage.getItem("catalog").length > 0 ? JSON.parse(localStorage.getItem("catalog")) : loadCatalog(user.uid);

    const getAllClients = () => {
        FbConfig.database()
            .ref("customers/" + user.uid + "/")
            .on("value", snapshot => {
                let customers = snapshot.val();
                let newState = [];
                if (customers) {
                    for (let customer in customers) {
                        newState.push({
                            userId: customer,
                            firstname: customers[customer].firstname
                        });
                    }
                }
                if (newState.length > 0) {
                    setClients(newState);
                } else {
                    setNoClients(true);
                }
            });
    };

    if (false === orderIsLoading) {
        if (Object.entries(props.match.params).length) {
            const orderId = props.match.params.orderId;
            setOrderId(orderId);
            if (false === orderIsLoading) {
                let locations = [];
                let fees = null;

                FbConfig.database()
                    .ref("orders/" + user.uid + "/" + orderId)
                    .on("value", snapshot => {
                        setOrder(snapshot.val());
                        ClientSet({
                            id: snapshot.val().client_id,
                            name: snapshot.val().client
                        });
                        if (snapshot.val().locations) {
                            locations = snapshot.val().locations;
                        }

                        if (!locations.length || !locations.filter(x => x.isDefault).length) {
                            locations.push({
                                locationTitle: "Lieu principal",
                                locationDate: snapshot.val().date,
                                locationWhere: snapshot.val().where,
                                place_id: snapshot.val().placeId,
                                isDefault: true
                            });
                        }

                        if (snapshot.val().fees) {
                            fees = snapshot.val().fees;
                        }
                        setSumFees(snapshot.val().feesValue);

                        if (!snapshot.val().fees) {
                            setSumFees(0);
                        }

                        setOldDiscountsValue(snapshot.val());
                    });
                if (null !== locations) {
                    setAllLocations(locations);
                }
                if (null !== fees) {
                    setAllFees(fees);
                }
                setOrderLoading(true);
            }
        }
    }

    React.useEffect(() => {
        if (allClients && !allClients.length && false === noClients) {
            getAllClients();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderIsLoading]);

    const getCatalogDetails = (e, setFieldValue) => {
        if (e.target.value) {
            const getPrestation = getCatalogItemById(user.uid, e.target.value);
            setFieldValue("price", getPrestation.price);
        } else {
            setFieldValue("price", 0);
        }
    };

    /**
     * calc Km amount
     */
    const calcGrandTotal = values => calcPriceTotal(values.price, sumFees, discounts);

    const calcInstalment = (total, percent) => (total * (parseInt(percent) / 100)).toFixed(2);

    /**
     * répartition du paiement
     */
    const renderPaymentInstalment = values => {
        if (values.catalog) {
            const getPrestation = getCatalogItemById(user.uid, values.catalog);
            const grandTotal = calcPriceTotal(values.price, sumFees, discounts);

            return (
                <>
                    <li>Grand Total : { grandTotal }€</li>
                    { true === getPrestation.paymentInstalment && (
                        <ul>
                            <li>Paiement 1 - 30%: { calcInstalment(grandTotal, 30) }€</li>
                            <li>Paiement 2 - 50%: { calcInstalment(grandTotal, 50) }€</li>
                            <li>Paiement 3 - 20%: { calcInstalment(grandTotal, 20) }€</li>
                        </ul>
                    ) }
                </>
            );
        }
    };

    if (allClients && !allClients.length && false === noClients) {
        getAllClients();
    }

    const searchClient = allClients.map(function (param) {
        return param;
    });

    /**
     * Gestion des frais (fee)
     */

    function setFee(params, action = "add") {
        if ("add" === action) {
            setSumFees(sumFees + parseFloat(params.feeAmount));
            _setFees(params);
        } else {
            setSumFees(sumFees - parseFloat(params.feeAmount));
        }
    }

    function getAllFees() {
        return allFees;
    }

    function _setFees(params) {
        setAllFees([...getAllFees(), params]);
    }

    function _removeFeeById(id) {
        var GL = [...getAllFees()];
        setFee(GL[id], "remove");
        GL.splice(id, 1);
        setAllFees(GL);
    }

    /**
     * Gestion des locations (location)
     */
    function setLocation(params) {
        _setLocations(params);
    }

    function getAllLocations() {
        return allLocations;
    }

    function _setLocations(params) {
        setAllLocations([...getAllLocations(), params]);
    }

    function _removeLocationById(id) {
        var GL = [...getAllLocations()];
        if (GL[id].isDefault) {
            GL[(id + 1) % GL.length].isDefault = true;
        }

        GL.splice(id, 1);
        setAllLocations(GL);
    }

    function _setLocationAsDefault(id) {
        var GL = [...getAllLocations()];
        GL = GL.map(function (param) {
            param.isDefault = false;
            return param;
        });
        GL[id].isDefault = true;
        setAllLocations(GL);
    }

    /**
     * Gestion des reductions (discount)
     */
    function _removeDiscount(id) {
        setDiscounts(discounts.filter(x => x.id !== id) || []);
        enqueueSnackbar("Coupon supprimé", { variant: "info" });
    }

    function setOldDiscountsValue(order) {
        if (order.newDiscount) {
            setDiscounts(order.discounts || []);
            return;
        }

        setDiscounts(
            [
                {
                    id: "_" + Math.random().toString(36).substr(2, 9),
                    name: "Coupon",
                    value: order.discountSelector,
                    percent: true
                }
            ] || []
        );
    }

    /**
     * getter / setter pour le Hook Client
     */
    function ClientSet(params) {
        setClientId(params);
    }

    function ClientGet() {
        return getClientId;
    }

    const setInitialValues = {
        client: "",
        price: 0,
        catalog: "",
        where: "",
        dateOrder: getCurrentDate("-"),
        place_id: "",
        orderNote: ""
    };

    const setEditValues = {
        client: order.client_id,
        price: order.catalogPrice,
        catalog: order.catalogId,
        where: order.where,
        dateOrder: order.date,
        place_id: order.placeId,
        orderNote: order.note
    };

    const getInitialValues = () => {
        if ((true === orderIsLoading && !props.match.params.orderId) || !orderIsLoading) {
            return setInitialValues;
        }
        return setEditValues;
    };

    const cancelForm = () => {
        history.push("/orders");
    };

    return (
        <Paper className={ classes.paper }>
            <Formik
                validateOnBlur={ false }
                validateOnChange={ false }
                initialValues={ getInitialValues() }
                validationSchema={ SignupSchema }
                onSubmit={ (values, { setSubmitting }) => {
                    setTimeout(() => {
                        const getPrestation = getCatalogItemById(user.uid, values.catalog);
                        const dateFormat = true === orderIsLoading ? values["dateOrder"] : values["dateOrder"].toISOString();

                        const dateCreateOrder = getCurrentDate("-");

                        values["client"] = ClientGet()["name"];
                        values["client_id"] = ClientGet()["id"];
                        values["locations"] = getAllLocations();
                        values["where"] = values["locations"].find(param => param.isDefault)?.locationWhere;
                        values["place_id"] = values["locations"].find(param => param.isDefault)?.place_id;

                        if (!allClients.map(x => x.userId).includes(values["client_id"])) {
                            enqueueSnackbar("Client non trouvé", { variant: "error" });
                        } else if (!values["locations"] || !values["locations"].length) {
                            enqueueSnackbar("Veuillez ajouter au moins une adresse", { variant: "error" });
                        } else if (!values["where"] || !values["place_id"]) {
                            enqueueSnackbar("veuillez préciser le lieu principal", { variant: "error" });
                        } else if (calcDiscountValue(values.price, sumFees, discounts, "percent") > 100) {
                            enqueueSnackbar("La valeur de la remise ne peut pas être supérieure à 100%", { variant: "error" });
                        } else {
                            const data = {
                                uid: user.uid,
                                client: values["client"],
                                client_id: values["client_id"],
                                catalogId: values["catalog"],
                                catalogType: getPrestation.type,
                                catalogPrice: values["price"],
                                createDate: dateCreateOrder.toISOString(),
                                catalogDuration: getPrestation.duration ? getPrestation.duration : true === getPrestation.durationAllday ? 24 : null,
                                where: values["where"],
                                placeId: values["place_id"],
                                date: dateFormat,
                                feesValue: sumFees,

                                newDiscount: true,
                                activateDiscount: calcDiscountValue(values.price, sumFees, discounts) > 0 ? true : false,
                                discountSelector: calcDiscountValue(values.price, sumFees, discounts, "percent"),
                                discountValue: calcDiscountValue(values.price, sumFees, discounts),
                                discounts: discounts,

                                priceToPay: calcGrandTotal(values),
                                hasPaymentInstalment: getPrestation.paymentInstalment && true === getPrestation.paymentInstalment ? true : false,
                                paymentInstalment: {
                                    1: {
                                        price: getPrestation.paymentInstalment && true === getPrestation.paymentInstalment ? calcInstalment(calcGrandTotal(values), 30) : calcGrandTotal(values),
                                        isPaid: false,
                                        datePaid: null
                                    },
                                    2: {
                                        price: getPrestation.paymentInstalment && true === getPrestation.paymentInstalment ? calcInstalment(calcGrandTotal(values), 50) : null,
                                        isPaid: false,
                                        datePaid: null
                                    },
                                    3: {
                                        price: getPrestation.paymentInstalment && true === getPrestation.paymentInstalment ? calcInstalment(calcGrandTotal(values), 20) : null,
                                        isPaid: false,
                                        datePaid: null
                                    }
                                },
                                note: values["orderNote"],
                                locations: values["locations"],
                                fees: getAllFees()
                            };

                            //Edit order
                            if (true === orderIsLoading) {
                                FbConfig.database()
                                    .ref("orders/" + user.uid + "/" + _orderId)
                                    .update(data)
                                    .then(() => {
                                        enqueueSnackbar("Enregistrement des modifications", { variant: "success" });
                                        history.push("/orders");
                                    })
                                    .catch(() => enqueueSnackbar("Un problème est survenu à l'enregistrement des modifications", { variant: "error" }));
                            } else {
                                var saveOrder = FbConfig.database().ref("orders/" + user.uid);
                                const lastOrderId = saveOrder.push().getKey();
                                saveOrder
                                    .child(lastOrderId)
                                    .set(data)
                                    .then(() => {
                                        updateInvoiceNumber(user.uid, lastOrderId, "quote");
                                        enqueueSnackbar("Enregistrement de la commande", { variant: "success" });
                                        var sendMessage = FbConfig.functions().httpsCallable("sendAddNewOrder");
                                        sendMessage({
                                            email: user.email,
                                            username: user.username,
                                            catalogType: data.catalogType,
                                            client: data.client,
                                            where: data.where,
                                            priceToPay: data.priceToPay,
                                            date: data.date
                                        });
                                        history.push("/orders");
                                    })
                                    .catch(() => enqueueSnackbar("Un problème est survenu à l'enregistrement de la commande", { variant: "error" }));
                            }

                            setSubmitting(false);
                        }
                    }, 400);
                } }>
                { ({ values, handleChange, setFieldValue, resetForm }) => (
                    <Form className={ classes.form } noValidate>
                        <Grid container spacing={ 5 }>
                            <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
                                <FormControl component="fieldset" fullWidth>
                                    <FormLabel component="legend">Nouvelle réservation</FormLabel>

                                    <FormikSelect
                                        required
                                        onChange={ e => {
                                            handleChange(e);
                                            getCatalogDetails(e, setFieldValue);
                                        } }
                                        id="catalog"
                                        label="Type de prestation"
                                        name="catalog"
                                        options={ catalogList.sort(dynamicSortMultiple("type", "name")).map(item => ({
                                            key: item.itemId,
                                            value: item.type + " " + item.name + " - " + item.price + "€ "
                                        })) }
                                    />

                                    <FormikTextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="price"
                                        label="Prix"
                                        name="price"
                                        type="number"
                                        InputProps={ {
                                            endAdornment: <EuroIcon />
                                        } }
                                        onChange={ e => handleChange(e) }
                                    />

                                    <AutocompleteClient suggestions={ searchClient } ClientGet={ ClientGet } ClientSet={ ClientSet } />

                                    <Client fromForm="orders" />
                                    <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                                        <FormikDateTimepicker
                                            id="dateOrder"
                                            name="dateOrder"
                                            margin="normal"
                                            label="Quand"
                                            fullWidth
                                            keyboard="true"
                                            clearable="true"
                                            autoOk
                                            //minDate={new Date()}
                                            format="dd/MM/yyyy HH:mm:ss"></FormikDateTimepicker>
                                    </MuiPickersUtilsProvider>
                                    <br />
                                    <BookingAddNewLocation
                                        setNewLocation={ setLocation }
                                        locationDate={ getAllLocations().length >= 1 ? (new Date(getAllLocations().at(-1).locationDate)) : (values["dateOrder"] ?? new Date()) } 
                                        isDefault={ getAllLocations().length === 0 }
                                    />
                                </FormControl>

                                <div style={ { marginBottom: "1.3rem", marginTop: "1.2rem" } }></div>
                                { getAllLocations().length >= 1 ? (
                                    <TableContainer component={ Paper }>
                                        <Table className={ classes.table } aria-label="simple table">
                                            <TableHead>
                                                <TableRow className={ classes.tableRow }>
                                                    <TableCell className={ classes.paddingTable } align="left">
                                                        Lieu Principal
                                                    </TableCell>
                                                    <TableCell className={ classes.paddingTable } align="center">
                                                        Date
                                                    </TableCell>
                                                    <TableCell className={ classes.paddingTable } align="center">
                                                        Lieu
                                                    </TableCell>
                                                    <TableCell className={ classes.paddingTable } align="center">
                                                        Note
                                                    </TableCell>
                                                    { /* <TableCell className={classes.paddingTable} align="center">
                                                        Distance
                                                    </TableCell> */ }
                                                    <TableCell className={ classes.paddingTable } align="center">
                                                        Action
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { getAllLocations().map((v, index) => {
                                                    return (
                                                        <TableRow key={ v.place_id + index.toString() }>
                                                            <TableCell className={ classes.paddingTable } align="left">
                                                                <Checkbox checked={ v.isDefault } onChange={ () => _setLocationAsDefault(index) } inputProps={ { "aria-label": "primary checkbox" } } />
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                { new Date(v.locationDate).toLocaleString("fr-FR", {
                                                                    year: "numeric",
                                                                    month: "numeric",
                                                                    day: "numeric",
                                                                    hour: "numeric",
                                                                    minute: "numeric"
                                                                }) }
                                                            </TableCell>
                                                            <TableCell className={ classes.paddingTable } align="center">
                                                                { v.locationWhere }
                                                            </TableCell>
                                                            <TableCell className={ classes.paddingTable } align="center">
                                                                { v.locationTitle }
                                                            </TableCell>
                                                            { /* <TableCell className={classes.paddingTable} align="center">
                                                                {v.distance || "Loading..."}
                                                            </TableCell> */ }
                                                            <TableCell className={ classes.paddingTable } align="center">
                                                                <IconButton
                                                                    type="button"
                                                                    variant="contained"
                                                                    color="secondary"
                                                                    size="medium"
                                                                    //disabled={isSubmitting}
                                                                    onClick={ () => {
                                                                        _removeLocationById(index);
                                                                    } }>
                                                                    <CancelIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }) }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : null }
                            </Grid>
                            <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
                                <FormControl component="fieldset" fullWidth margin="none">
                                    <FormLabel component="legend">Options</FormLabel>
                                    <div style={ { marginBottom: "1.3rem", marginTop: "1.2rem" } }>
                                        <BookingAddFee setNewFee={ setFee } />
                                    </div>
                                    { getAllFees().length >= 1 ? (
                                        <TableContainer component={ Paper }>
                                            <Table className={ classes.table } aria-label="simple table">
                                                <TableHead>
                                                    <TableRow className={ classes.tableRow }>
                                                        <TableCell className={ classes.paddingTable } align="center">
                                                            Titre
                                                        </TableCell>
                                                        <TableCell className={ classes.paddingTable } align="center">
                                                            Montant
                                                        </TableCell>
                                                        <TableCell className={ classes.paddingTable } align="center">
                                                            Action
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    { getAllFees().map((v, index) => {
                                                        return (
                                                            <TableRow key={ index.toString() }>
                                                                <TableCell className={ classes.paddingTable } align="center">
                                                                    { v.feeName }
                                                                </TableCell>
                                                                <TableCell className={ classes.paddingTable } align="center">
                                                                    { v.feeAmount } €
                                                                </TableCell>
                                                                <TableCell className={ classes.paddingTable } align="center">
                                                                    <IconButton
                                                                        type="button"
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        size="medium"
                                                                        //disabled={isSubmitting}
                                                                        onClick={ () => {
                                                                            _removeFeeById(index);
                                                                        } }>
                                                                        <CancelIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    }) }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ) : null }
                                    <BookingDiscount item={ values } sumFees={ sumFees } discounts={ discounts } setDiscounts={ setDiscounts } />
                                    { discounts?.length >= 1 ? (
                                        <TableContainer component={ Paper }>
                                            <Table className={ classes.table } aria-label="simple table">
                                                <TableHead>
                                                    <TableRow className={ classes.tableRow }>
                                                        <TableCell className={ classes.paddingTable } align="center">
                                                            Titre
                                                        </TableCell>
                                                        <TableCell className={ classes.paddingTable } align="center">
                                                            Montant
                                                        </TableCell>
                                                        <TableCell className={ classes.paddingTable } align="center">
                                                            Action
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    { discounts.map((v, index) => {
                                                        return (
                                                            <TableRow key={ index.toString() }>
                                                                <TableCell className={ classes.paddingTable } align="center">
                                                                    { v.name }
                                                                </TableCell>
                                                                <TableCell className={ classes.paddingTable } align="center">
                                                                    { v.value } { v.percent ? "%" : "€" }
                                                                </TableCell>
                                                                <TableCell className={ classes.paddingTable } align="center">
                                                                    <IconButton
                                                                        type="button"
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        size="medium"
                                                                        onClick={ () => {
                                                                            _removeDiscount(v.id);
                                                                        } }>
                                                                        <CancelIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    }) }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ) : null }

                                    <FormikTextField
                                        label="Note"
                                        name="orderNote"
                                        id="orderNote"
                                        multiline
                                        fullWidth
                                        margin="normal"
                                        InputProps={ {
                                            startAdornment: <CommentIcon />
                                        } }
                                    />
                                    <Divider />
                                    <ul>
                                        <li>Prix : { values.price }€</li>
                                        <li>Frais supplémentaires : { sumFees } €</li>
                                        <li>Remise accordée : { calcDiscountValue(values.price, sumFees, discounts, "print") }</li>
                                        { renderPaymentInstalment(values) }
                                    </ul>

                                    <Divider />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div className={ classes.buttons }>
                            <Button
                                variant="contained"
                                color="default"
                                className={ classes.submit }
                                type="reset"
                                onClick={ e => {
                                    resetForm();
                                    cancelForm(e);
                                } }>
                                Annuler
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={ classes.submit }
                                //disabled={isSubmitting}
                            >
                                Valider
                            </Button>
                        </div>
                    </Form>
                ) }
            </Formik>
        </Paper>
    );
}
