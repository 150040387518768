import FbConfig from "../base";
import { getCatalogItemById } from "../catalog/CatalogTools";
import InvoiceContact from "../invoice/InvoiceContact";
import InvoiceItemsTable from "../invoice/InvoiceItemsTable";
import InvoiceNo from "../invoice/InvoiceNo";
import InvoiceThankYouMsg from "../invoice/InvoiceThankYouMsg";
import InvoiceTitle from "../invoice/InvoiceTitle";
import InvoiceTVA from "../invoice/InvoiceTVA";
import { IconButton } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Document, Image, Page, StyleSheet, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React from "react";

const styles = StyleSheet.create({
    page: {
        fontFamily: "Helvetica",
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: "column"
    },
    logo: {
        width: 300,
        height: 200,
        marginLeft: "auto",
        marginRight: "auto"
    }
});

const generatePdfDocument = async (invoice, userInfo, clientInfo, title, styles, fileName) => {
    const blob = await pdf(
        <Document>
            <Page size="A4" style={ styles.page }>
                <Image style={ styles.logo } src={ userInfo.logo } />
                <InvoiceTitle title={ title } />
                <InvoiceNo invoice={ invoice } />
                <InvoiceContact client={ clientInfo } user={ userInfo } />
                <InvoiceItemsTable invoice={ invoice } />
                { false === userInfo.tva && <InvoiceTVA user={ userInfo } /> }
                <InvoiceThankYouMsg />
            </Page>
        </Document>
    ).toBlob();
    saveAs(blob, fileName);
};

export default function BookingPdf(props) {
    const user = JSON.parse(localStorage.getItem("token"));
    const { enqueueSnackbar } = useSnackbar();

    let USERINFO = false;
    let CLIENTINFO = false;

    const invoice = props.order;
    const title = props.title;

    let buttonTitle = getButtonTitle(title);

    if ("Devis" === title) {
        invoice.invoice_no = invoice.quoteNumber;
    } else if ("Facture" === title) {
        invoice.invoice_no = invoice.invoiceNumber;
    }

    const fileName = title + "_" + invoice.invoice_no + ".pdf";

    invoice.trans_date = new Date(invoice.createDate).toLocaleString("fr-FR", {
        year: "numeric",
        month: "numeric",
        day: "numeric"
    });

    const loadCatalogItem = async () => {
        const itemNfo = getCatalogItemById(user.uid, invoice.catalogId);
        invoice.items = [];

        if (itemNfo) {
            invoice.items.push({
                sno: 1,
                description: itemNfo.type + ": " + itemNfo.name + " " + itemNfo.description,
                qte: 1,
                price: itemNfo.price + "€"
            });

            if (invoice.fees) {
                invoice.fees.map((v, index) => {
                    invoice.items.push({
                        sno: index,
                        description: v.feeName,
                        qte: 1,
                        price: v.feeAmount + "€"
                    });
                });
            }

            if (invoice.discountSelector && invoice.discountSelector > 0) {
                invoice.items.push({
                    sno: 3,
                    description: "Remise",
                    qte: invoice.discountSelector + "%",
                    price: "- " + invoice.discountValue + "€"
                });
            }
        }
    };

    const loadClient = async () => {
        let query = await FbConfig.database()
            .ref("customers/" + user.uid + "/" + invoice.client_id)
            .once("value");
        CLIENTINFO = query.val();
    };

    const loadUser = async () => {
        let query = await FbConfig.database()
            .ref("users/" + user.uid + "/")
            .once("value");
        USERINFO = query.val();
    };

    const loadData = async () => {
        await loadUser();
        await loadClient();
        await loadCatalogItem();
    };

    function setDocument(title, invoice) {
        switch (title) {
        case "Devis":
            invoice.title = "Devis n°";
            invoice.invoice_no = invoice.quoteNumber;
            invoice.qoteRefTitle = "";
            invoice.quoteRef = "";
            break;
        case "Facture":
            invoice.title = "Facture n°";
            invoice.invoice_no = invoice.invoiceNumber;
            invoice.qoteRefTitle = "Devis en référence n°";
            invoice.quoteRef = invoice.quoteNumber;
            break;
        }

        return invoice;
    }

    function getButtonTitle(title) {
        if ("Devis" === title) {
            return "Téléchargez le devis";
        } else {
            return "Téléchargez la facture";
        }
    }

    const handleClickOpen = async () => {
        setDocument(title, invoice);
        if (!invoice.invoiceNumber && "Facture" === title) {
            enqueueSnackbar("La facture n'a pas encore été créee.", {
                variant: "error"
            });
        } else {
            await loadData();
            generatePdfDocument(invoice, USERINFO, CLIENTINFO, title, styles, fileName);
        }
    };

    return (
        <>
            <ListItemIcon>
                <IconButton aria-label={ buttonTitle } onClick={ () => handleClickOpen() }>
                    <GetAppIcon />
                </IconButton>
            </ListItemIcon>
            <ListItemText primary={ buttonTitle } onClick={ () => handleClickOpen() } />
        </>
    );
}
