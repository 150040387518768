import FbConfig from "../base";

export function getDuration() {
    return [{ id: -1, name: "Toute la journée" }].concat(Array.from(Array(47).keys()).map(i => ({ id: i + 1, name: `${Math.ceil(i / 2)}h${i % 2 ? "00" : "30"}` })));
}

/**
 * Fonction pour retrouver une prestation par son ID
 */
export function getCatalogItemById(uid, id) {
    const catalog = null !== localStorage.getItem("catalog") ? JSON.parse(localStorage.getItem("catalog")) : loadCatalog(uid);

    const result = catalog.find(catalog => catalog.itemId === id);
    return result;
}

export function loadCatalog(uid) {
    let catalog = [];

    let firebaseCatalogURL = "catalog/" + uid + "/";

    try {
        let data = FbConfig.database().ref(firebaseCatalogURL);
        data.on("value", snapshot => {
            let result = snapshot.val();
            for (let item in result) {
                catalog.push({
                    itemId: item,
                    name: result[item].name,
                    type: result[item].type,
                    price: result[item].price,
                    duration: result[item].duration,
                    durationAllday: result[item].durationAllday,
                    payOnline: result[item].payOnline,
                    bookOnline: result[item].bookOnline,
                    paymentInstalment: result[item].paymentInstalment,
                    nbPaymentInstalment: result[item].nbPaymentInstalment,
                    description: result[item].description,
                    active: result[item].active,
                    color: result[item].color
                });
            }
            localStorage.setItem("catalog", JSON.stringify(catalog));
        });
    } catch (error) {
        // pass
    }
    return catalog;
}
