// eslint-disable-next-line no-unused-vars

import FbConfig from "../base";
import { IconButton, Tooltip } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import React from "react";

export default function ClientCall(props) {
    const user = JSON.parse(localStorage.getItem("token"));
    const values = props.order;

    const [clientPhone, setClientPhone] = React.useState(false);
    const [clientName, setClientName] = React.useState(false);
    const [orderIsLoading, setOrderLoading] = React.useState(false);

    function getClientById() {
        let clientId = values.client_id;

        FbConfig.database()
            .ref("customers/" + user.uid + "/" + clientId)
            .on("value", snapshot => {
                let client = snapshot.val();
                setClientName(client?.firstname);
                if (client?.phone) {
                    setClientPhone(client?.phone);
                }
            });
    }

    if (false === orderIsLoading) {
        getClientById();
        setOrderLoading(true);
    }

    const getMsgTooltip = () => {
        return false === clientPhone ? `Pas de numéro renseigné pour ${clientName}` : `Appeler ${clientName}`;
    };

    const getTel = () => {
        return false === clientPhone ? "#" : `tel:${clientPhone}`;
    };

    const getColor = () => {
        return false === clientPhone ? "secondary" : "primary";
    };

    const getResult = () => {
        if (false === clientPhone) {
            return null;
        }
        return (
            <>
                <Tooltip title={ getMsgTooltip() } touch="true">
                    <IconButton aria-label="valider">
                        <a href={ getTel() } style={ { height: 24 } }>
                            <CallIcon color={ getColor() } />
                        </a>
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    return getResult();
}
