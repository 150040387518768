/* eslint-disable no-prototype-builtins */
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import VisibilityIcon from "@material-ui/icons/Visibility";
// eslint-disable-next-line no-unused-vars
import React, { PureComponent } from "react";
import { Bar, BarChart, Brush, CartesianGrid, Cell, Legend, Pie, PieChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import FbConfig from "./base";
import BookingTodo from "./booking/BookingTodo";
import { palet } from "./colorPalet";

const BootstrapInput = withStyles(theme => ({
    input: {
        "&:focus": {
            borderColor: "#80bdff",
            borderRadius: 4,
            boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
        },
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #ced4da",
        borderRadius: 4,
        // Use the system font instead of the default Roboto font.
        fontFamily: ["-apple-system", "BlinkMacSystemFont", "\"Segoe UI\"", "Roboto", "\"Helvetica Neue\"", "Arial", "sans-serif", "\"Apple Color Emoji\"", "\"Segoe UI Emoji\"", "\"Segoe UI Symbol\""].join(","),
        fontSize: 16,
        padding: "10px 26px 10px 12px",
        position: "relative",
        transition: theme.transitions.create(["border-color", "box-shadow"])
    },
    root: {
        "label + &": {
            marginTop: theme.spacing(3)
        }
    }
}))(InputBase);

export default class Example extends PureComponent {
    constructor(props) {
        super(props);
        const date = new Date();
        this.state = {
            AllData: [],
            AllDate: [],
            CurrentDate: new Date(date).toLocaleString("fr-FR", {
                month: "long",
                year: "numeric"
            }),
            CurrentMonth: date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1,
            CurrentYear: date.getFullYear(),
            DataSrc: {},
            DateValue: 0,
            TypeData: [],
            amount: [],
            date: date,
            error: false,
            loading1: true,
            loading2: true,
            loading3: true,
            nexRdvDate: false,
            openTodo: false,
            presta: 0,
            prestaNotValid: 0,
            //prestaAll: 0,
            rendezVous: 0,
            todo: 0,
            todoElement: 0,
            user: JSON.parse(localStorage.getItem("token")),
            userSource: []
        };
    }

    ObjectConstruct(CurrentItemDate, CurrentItemDateMonth, NewState) {
        if (!NewState.hasOwnProperty(CurrentItemDate.getFullYear())) {
            NewState[CurrentItemDate.getFullYear()] = {};
            NewState[CurrentItemDate.getFullYear()]["type"] = {};
        }

        if (!NewState[CurrentItemDate.getFullYear()].hasOwnProperty(CurrentItemDateMonth)) {
            NewState[CurrentItemDate.getFullYear()][CurrentItemDateMonth] = {};
        }

        if (!NewState[CurrentItemDate.getFullYear()][CurrentItemDateMonth].hasOwnProperty("bill")) {
            NewState[CurrentItemDate.getFullYear()][CurrentItemDateMonth]["bill"] = 0;
            NewState[CurrentItemDate.getFullYear()][CurrentItemDateMonth]["cashed"] = 0;
            NewState[CurrentItemDate.getFullYear()][CurrentItemDateMonth]["canceled"] = 0;
        }
        return NewState;
    }

    componentDidMount() {
        //console.log(this.state.CurrentMonth);
        FbConfig.database()
            .ref("customers/" + this.state.user.uid + "/")
            .on("value", snapshot => {
                let value = snapshot.val();
                if (!value) value = [];
                let client = [];

                Object.values(value).map(x => {
                    client.push(x.source);
                });

                this.setState({
                    loading2: false,
                    userSource: client
                });
            });
        FbConfig.database()
            .ref("orders/" + this.state.user.uid + "/")
            .on("value", snapshot => {
                let value = snapshot.val();
                if (!value) value = [];
                const DataSrc = value;
                //console.log(value);
                //console.table(value);
                const { date, CurrentYear } = this.state;
                //let prestaAll = 0;
                let nexRdvDate = false;
                let presta = 0;
                let prestaNotValid = 0;
                let rendezVous = 0;
                let todo = 0;
                let todoElement = 0;
                let NewState = {};

                Object.values(value).map(x => {
                    const CurrentItemDate = new Date(x.date);
                    const CurrentItemDateMonth = CurrentItemDate.getMonth() + 1 < 10 ? "0" + (CurrentItemDate.getMonth() + 1).toString() : (CurrentItemDate.getMonth() + 1).toString();
                    //console.log({ x });
                    if (x.catalogType !== "RDV") {
                        NewState = this.ObjectConstruct(CurrentItemDate, CurrentItemDateMonth, NewState);
                        if (!NewState[CurrentItemDate.getFullYear()].hasOwnProperty("type")) {
                            NewState[CurrentItemDate.getFullYear()]["type"] = {};
                        }
                        if (!NewState[CurrentItemDate.getFullYear()]["type"].hasOwnProperty(x.catalogType)) {
                            NewState[CurrentItemDate.getFullYear()]["type"][x.catalogType] = 0;
                        }

                        if (x.orderIsConfirmed) {
                            NewState[CurrentItemDate.getFullYear()][CurrentItemDateMonth]["bill"] += parseInt(x.priceToPay);
                            NewState[CurrentItemDate.getFullYear()]["type"][x.catalogType] += 1;
                            x.paymentInstalment.map(payement => {
                                if (payement.hasOwnProperty("datePaid") && payement.isPaid) {
                                    const CurrentPayementDate = new Date(payement.datePaid);
                                    const CurrentPayementMonth = CurrentPayementDate.getMonth() + 1 < 10 ? "0" + (CurrentPayementDate.getMonth() + 1).toString() : (CurrentPayementDate.getMonth() + 1).toString();

                                    NewState = this.ObjectConstruct(CurrentPayementDate, CurrentPayementMonth, NewState);

                                    NewState[CurrentPayementDate.getFullYear()][CurrentPayementMonth]["cashed"] += parseInt(payement.price);
                                }
                            });
                        } else {
                            // TODO: calc le total des devis et nb de presta cancel
                            NewState[CurrentItemDate.getFullYear()][CurrentItemDateMonth]["canceled"] += parseInt(x.priceToPay);
                            NewState[CurrentItemDate.getFullYear()]["type"][x.catalogType] += 1;
                        }
                    }
                    if (x.todo) {
                        let todoTest = false;
                        Object.values(x.todo).map(itemTodo => {
                            if (!itemTodo.isDone) {
                                todo += 1;
                                todoTest = true;
                            }
                        });
                        if (todoTest) {
                            todoElement += 1;
                        }
                    }
                    if (CurrentItemDate.getMonth() === date.getMonth() && CurrentItemDate.getFullYear() === CurrentYear && CurrentItemDate > date) {
                        if (x.orderIsConfirmed) {
                            presta += 1;
                        } else {
                            //console.log(x)
                            if (x.catalogType !== "RDV") prestaNotValid += 1;
                            if (x.catalogType === "RDV") {
                                if (CurrentItemDate < nexRdvDate || !nexRdvDate) {
                                    nexRdvDate = CurrentItemDate;
                                }
                                rendezVous += 1;
                            }
                        }

                        //prestaAll += 1;
                    }
                });
                let AllDate = Object.keys(NewState).map(x => {
                    return parseInt(x);
                });
                let currentDate = AllDate.includes(new Date().getFullYear()) ? AllDate.indexOf(new Date().getFullYear()) : 0;
                this.setState({
                    //prestaAll : prestaAll,
                    rendezVous: rendezVous,
                    nexRdvDate: nexRdvDate,
                    todo: todo,
                    todoElement: todoElement,
                    presta: presta,
                    prestaNotValid: prestaNotValid,
                    loading3: false,
                    loading1: false,
                    DateValue: currentDate,
                    AllData: NewState,
                    AllDate: AllDate,
                    DataSrc: DataSrc
                });
            });
        /*let url = "stats/" + this.state.user.uid + "/";
        FbConfig.database()
            .ref(url)
            .on("value", snapshot => {
                let value = snapshot.val();
                if (!value) value = [];
                let date = Object.keys(value).map(x => {return parseInt(x);});
                console.log(value)
                let currentDate = date.includes(new Date().getFullYear()) ? date.indexOf(new Date().getFullYear()) : 0;
                this.setState({AllData: value, AllDate: date, loading1: false, DateValue: currentDate});
            });
            */

        /*
        this.getAmountByMonth(this.state.user, 2020);
        this.getTypeByYear(this.state.user, 2020)
        */
    }

    getAmountByMonth(value) {
        let data = [
            { name: "Janv", id: "01", facture: 0, encaisse: 0, annule: 0 },
            { name: "Fév", id: "02", facture: 0, encaisse: 0, annule: 0 },
            { name: "Mars", id: "03", facture: 0, encaisse: 0 , annule: 0 },
            { name: "Avril", id: "04", facture: 0, encaisse: 0 , annule: 0 },
            { name: "Mai", id: "05", facture: 0, encaisse: 0 , annule: 0 },
            { name: "Juin", id: "06", facture: 0, encaisse: 0 , annule: 0 },
            { name: "Juil", id: "07", facture: 0, encaisse: 0 , annule: 0 },
            { name: "Aout", id: "08", facture: 0, encaisse: 0 , annule: 0 },
            { name: "Sept", id: "09", facture: 0, encaisse: 0 , annule: 0 },
            { name: "Oct", id: "10", facture: 0, encaisse: 0 , annule: 0 },
            { name: "Nov", id: "11", facture: 0, encaisse: 0 , annule: 0 },
            { name: "Déc", id: "12", facture: 0, encaisse: 0, annule: 0 }
        ];

        data.map((v, i) => {
            let id = v.id;
            if (value.hasOwnProperty(id)) {
                if (value[id].hasOwnProperty("bill")) {
                    data[i].facture = value[id].bill;
                }
                if (value[id].hasOwnProperty("cashed")) {
                    data[i].encaisse = value[id].cashed;
                }
                if (value[id].hasOwnProperty("canceled")) {
                    data[i].annule = value[id].canceled;
                }
            }
        });
        return data;
    }

    chartAmount(width, height) {
        let data = this.getAmountByMonth(this.state.AllData[this.state.AllDate[this.state.DateValue]]);

        return (
            <ResponsiveContainer width={ width } height={ height }>
                <BarChart data={ data } margin={ { bottom: 0, left: 0, right: 0, top: 0 } }>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" interval="preserveStartEnd" tickCount={ 12 } />
                    <YAxis type="number" interval="preserveEnd" tickCount={ 50 } />
                    <Tooltip wrapperStyle={ { backgroundColor: "#4A4A4A" } } />
                    <Legend
                        width={ 120 }
                        wrapperStyle={ {
                            backgroundColor: "#f5f5f5",
                            border: "1px solid #d5d5d5",
                            borderRadius: 3,
                            lineHeight: "40px",
                            right: 5,
                            top: 40
                        } }
                    />
                    <Brush dataKey="name" height={ 30 } stroke="#8884d8" />
                    <ReferenceLine y={ 0 } stroke="#000" />
                    <Bar
                        dataKey="facture"
                        fill="#2eb7ba"
                        barSize={ 40 }
                        unit="€"
                        //label
                        fillOpacity={ 1 }
                    />
                    <Bar
                        dataKey="encaisse"
                        fill="#d14845"
                        barSize={ 40 }
                        unit="€"
                        //label
                        fillOpacity={ 1 }
                    />

                    <Bar
                        dataKey="annule"
                        fill="#AE948F"
                        barSize={ 40 }
                        unit="€"
                        //label
                        fillOpacity={ 1 }
                    />
                </BarChart>
            </ResponsiveContainer>
        );
    }

    getTypeByYear(array1) {
        let data = [];

        Object.keys(array1).map(function (key) {
            if (array1[key] !== 0) {
                data.push({
                    name: key,
                    value: array1[key]
                });
            }
        });

        return data;
    }

    getSource() {
        let data = [];
        let source = {
            1: "Instagram",
            2: "Facebook",
            3: "Bouche à oreille",
            4: "Moteur de recherche",
            5: "Réseaux sociaux",
            6: "Autre"
        };
        let userS = Array.from(new Set(this.state.userSource));
        userS.map(key => {
            data.push({
                name: source[key],
                value: this.state.userSource.filter(x => x == key).length
            });
        });

        return data;
    }

    PieChartPrint(width, height, DateType) {
        let data = [];
        if (DateType === "presta") {
            data = this.getTypeByYear(this.state.AllData[this.state.AllDate[this.state.DateValue]].type);
        } else if (DateType === "source") {
            data = this.getSource();
        }
        //console.log(data,DateType,this.state.AllData)
        return (
            <ResponsiveContainer width={ width } height={ height }>
                <PieChart>
                    <Pie isAnimationActive={ true } data={ data } cy={ height / 2 } outerRadius={ 120 } label>
                        { data.map((entry, index) => (
                            <Cell fill={ palet[index % palet.length] } key={ `cell-${index}-Pie` } />
                        )) }
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        );
    }

    MonthInfoBillCashed() {
        const { AllData, CurrentYear, CurrentMonth } = this.state;

        let Current;

        if (AllData.hasOwnProperty(CurrentYear)) {
            Current = AllData[CurrentYear][CurrentMonth];
        }

        let bill = new Intl.NumberFormat("fr-FR", {
            currency: "EUR",
            style: "currency"
        }).format(Current ? (Current.bill ? Current.bill : 0) : 0);

        let cashed = new Intl.NumberFormat("fr-FR", {
            currency: "EUR",
            style: "currency"
        }).format(Current ? (Current.cashed ? Current.cashed : 0) : 0);

        let canceled = new Intl.NumberFormat("fr-FR", {
            currency: "EUR",
            style: "currency"
        }).format(Current ? (Current.canceled ? Current.canceled : 0) : 0);

        return `${cashed}/${bill}`;
    }

    YearInfoBillCashed(year = this.state.CurrentYear, cl1 = "black", cl2 = "black", cl3 = "black") {
        const { AllData } = this.state;
        let Current;
 
        if (AllData.hasOwnProperty(year)) {
            Current = AllData[year];
        }

        let bill = 0;
        let cashed = 0;
        let canceled = 0; 

        if (Current) {
            Object.values(Current).map(v => {
                if (v.bill) {
                    bill += v.bill;
                    
                    if (v.cashed) {
                        cashed += v.cashed;
                    }
                }
                if (v.canceled) {
                    canceled += v.canceled;
                }
            });
        }

        bill = new Intl.NumberFormat("fr-FR", {
            currency: "EUR",
            style: "currency"
        }).format(bill);
        
        cashed = new Intl.NumberFormat("fr-FR", {
            currency: "EUR",
            style: "currency"
        }).format(cashed ? cashed : 0);
       
        canceled = new Intl.NumberFormat("fr-FR", {
            currency: "EUR",
            style: "currency"
        }).format(canceled);

        return (
            <>
                <span style={ { color: cl1 } }><span role="img" aria-label="money_face" >🤑</span>{ cashed } </span>/
                <span style={ { color: cl2 } }>{ bill }</span> 
                <span style={ { color: cl3, fontSize:18 } } >&nbsp;&nbsp;&nbsp;
                    <span role="img" aria-label="crying_face" >😢</span>{ canceled }
                </span>
            </>
        );
    }

    DateChange(action) {
        if (action === "+") {
            this.setState({ DateValue: this.state.DateValue + 1 });
        } else if (action === "-") {
            this.setState({ DateValue: this.state.DateValue - 1 });
        }
    }

    handleChange = event => {
        this.setState({
            DateValue: this.state.AllDate.indexOf(parseInt(event.target.value))
        });
    };

    handleCloseTodo = () => {
        this.setState({ openTodo: false });
    };

    handleOpenTodo = () => {
        this.setState({ openTodo: true });
    };

    SeeTodo = () => {
        const { DataSrc, openTodo, todo } = this.state;
        const options = {
            day: "numeric",
            month: "numeric",
            year: "numeric"
        };

        const haveTodo = item => {
            if (!item.todo) return false;

            let done = false;
            let todo = Object.values(item.todo);
            for (var i = 0; i < todo.length; i++) {
                if (!todo[i].isDone) {
                    done = true;
                    break;
                }
            }

            return done;
        };

        return (
            <div>
                { todo !== 0 && (
                    <IconButton
                        color="primary"
                        aria-label="View task"
                        style={ {
                            fontSize: 22,
                            padding: "0 10px 0 0"
                        } }
                        onClick={ this.handleOpenTodo }>
                        <VisibilityIcon fontSize="inherit" />
                    </IconButton>
                ) }
                <Modal open={ openTodo } onClose={ this.handleCloseTodo } aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    <Paper
                        elevation={ 3 }
                        style={ {
                            left: "50%",
                            padding: 10,
                            position: "absolute",
                            top: "50%",
                            transform: "translate(-50%, -50%)"
                        } }>
                        <h3>List des Taches :</h3>
                        <Divider />
                        { todo !== 0 && (
                            <Grid container spacing={ 1 }>
                                <Hidden xsDown>
                                    <Grid item xs={ 12 }>
                                        <Paper
                                            elevation={ 2 }
                                            style={ {
                                                backgroundColor: "#f5f5f5"
                                            } }>
                                            <Grid
                                                container
                                                style={ {
                                                    alignItems: "center",
                                                    textAlignLast: "center",
                                                    padding: 15
                                                } }>
                                                <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                                                    Type
                                                </Grid>
                                                <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                                                    Client
                                                </Grid>
                                                <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                                                    Date
                                                </Grid>
                                                <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                                                    Action
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Hidden>
                                { Object.entries(DataSrc).map(
                                    ([id, item]) =>
                                        haveTodo(item) && (
                                            <Grid item xs={ 12 } style={ { margin: "0 9px" } }>
                                                <Paper elevation={ 2 }>
                                                    <Grid
                                                        container
                                                        style={ {
                                                            alignItems: "center",
                                                            textAlignLast: "center"
                                                        } }>
                                                        <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                                                            { item.catalogType }
                                                        </Grid>
                                                        <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                                                            { item.client }
                                                        </Grid>
                                                        <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                                                            { new Date(item.date).toLocaleDateString("fr-FR", options) }
                                                        </Grid>
                                                        <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
                                                            <BookingTodo id={ id } order={ item } />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        )
                                ) }
                            </Grid>
                        ) }
                        { todo === 0 && <h4>Vous n&apos;avez plus/pas de tache</h4> }
                    </Paper>
                </Modal>
            </div>
        );
    };

    render() {
        //console.log(this.state.AllData)
        const { loading1, loading2, loading3, error, AllData, presta, prestaNotValid, todoElement, rendezVous, nexRdvDate, CurrentDate, AllDate, DateValue, CurrentYear } = this.state;

        if (loading1 || loading2 || loading3) {
            return (
                <div
                    style={ {
                        position: "fixed",
                        top: "50%",
                        left: "50%"
                    } }>
                    <CircularProgress />
                </div>
            );
        } else if (error) {
            return <div>pas de prestations</div>;
        }
        return (
            <div>
                <Grid container spacing={ 3 }>
                    <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 4 } xl={ 4 }>
                        <Paper elevation={ 2 }>
                            <span style={ style.CardMainContainer }>Prestations restantes ce mois</span>
                            <div>
                                <div style={ style.CardContainer }>{ Object.keys(AllData).length ? presta : 0 }</div>
                                <Divider variant="middle" />
                                <div style={ style.CardSubContainer }>{ prestaNotValid ? <span style={ { color: "red" } }>Prestation à valider : { prestaNotValid }</span> : <span>Toutes les prestations sont validées</span> }</div>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 4 } xl={ 4 }>
                        <Paper elevation={ 2 }>
                            <div
                                style={ {
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                } }>
                                <div
                                    style={ {
                                        ...style.CardMainContainer,
                                        marginTop: 0
                                    } }>
                                    Tâches non traitées
                                </div>
                                <div>{ this.SeeTodo() }</div>
                            </div>
                            <div>
                                <div style={ style.CardContainer }>{ Object.keys(AllData).length ? this.state.todo : 0 }</div>
                                <Divider variant="middle" />
                                <div style={ style.CardSubContainer }>étalée(s) sur { todoElement } élèment(s)</div>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 4 } xl={ 4 }>
                        <Paper elevation={ 2 }>
                            <span style={ style.CardMainContainer }>Rendez-vous à venir</span>
                            <div>
                                <div style={ style.CardContainer }>{ Object.keys(AllData).length ? rendezVous : 0 }</div>
                                <Divider variant="middle" />
                                <div style={ style.CardSubContainer }>
                                    { nexRdvDate ? (
                                        <>
                                            Le prochain est le{ " " }
                                            { new Date(nexRdvDate).toLocaleString("fr-FR", {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric"
                                            }) }
                                        </>
                                    ) : (
                                        <>Il n&apos;a pas/plus de rendez-vous ce mois-ci</>
                                    ) }
                                </div>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 6 } xl={ 6 }>
                        <Paper elevation={ 2 }>
                            <span style={ style.CardMainContainer }>Revenu mois en-cours</span>
                            <div>
                                <div style={ style.CardContainer }>{ Object.keys(AllData).length ? this.MonthInfoBillCashed() : "0.00€/0.00€" }</div>
                                <Divider variant="middle" />
                                <div style={ style.CardSubContainer }>Mois en cours : { CurrentDate }</div>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 } xl={ 6 }>
                        <Paper elevation={ 2 }>
                            <span style={ style.CardMainContainer }>Revenu annuel</span>
                            <div>
                                <div style={ style.CardContainer }>{ Object.keys(AllData).length ? this.YearInfoBillCashed() : "0.00€/0.00€" }</div>
                                <Divider variant="middle" />
                                <div style={ style.CardSubContainer }>Année en cours : { this.state.date.getFullYear() }</div>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={ 12 } md={ 12 }>
                        <Paper elevation={ 2 }>
                            <div style={ { padding: 10, textAlign: "center" } }>
                                <IconButton color="primary" aria-label="add to shopping cart" onClick={ () => this.DateChange("-") } disabled={ DateValue === 0 }>
                                    <ArrowBackIosIcon fontSize="inherit" />
                                </IconButton>
                                <Select labelId="demo-customized-select-label" id="demo-customized-select" onChange={ this.handleChange } input={ <BootstrapInput /> } value={ AllDate[DateValue] } disabled={ AllDate.length <= 1 }>
                                    { Object.keys(AllData).map((v, i) => {
                                        return (
                                            <MenuItem value={ v } key={ `Menu-Item-${i}-${v}` }>
                                                { v }
                                            </MenuItem>
                                        );
                                    }) }
                                </Select>
                                <IconButton color="primary" aria-label="add to shopping cart" onClick={ () => this.DateChange("+") } disabled={ DateValue + 1 > AllDate.length - 1 }>
                                    <ArrowForwardIosIcon fontSize="inherit" />
                                </IconButton>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={ 12 } lg={ 12 } xl={ 6 }>
                        <Paper elevation={ 2 } style={ { paddingRight: 20 } }>
                            <div
                                style={ {
                                    marginTop: 5,
                                    marginLeft: 20,
                                    display: "flex",
                                    justifyContent: "space-between"
                                } }>
                                <h3>CA de { AllDate[DateValue] === CurrentYear ? "cette année" : "l'année " + AllDate[DateValue] }</h3>
                                <h3>{ Object.keys(AllData).length ? this.YearInfoBillCashed(AllDate[DateValue], "#d14845", "#2eb7ba", "#AE948F") : "0.00€/0.00€" }</h3>
                            </div>
                            { Object.keys(AllData).length ? (
                                this.chartAmount("100%", 420)
                            ) : (
                                <div
                                    style={ {
                                        height: 390,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    } }>
                                    il n&apos;y a pas de donnée pour le moment
                                </div>
                            ) }
                        </Paper>
                    </Grid>
                    <Grid item xs={ 12 } lg={ 6 } xl={ 3 }>
                        <Paper elevation={ 2 }>
                            <h3 style={ { marginTop: 5, marginLeft: 5 } }>Type de prestation de cette année</h3>
                            { Object.keys(AllData).length ? (
                                this.PieChartPrint("100%", 390, "presta")
                            ) : (
                                <div
                                    style={ {
                                        height: 390,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    } }>
                                    il n&apos;y a pas de donnée pour le moment
                                </div>
                            ) }
                        </Paper>
                    </Grid>
                    <Grid item xs={ 12 } lg={ 6 } xl={ 3 }>
                        <Paper elevation={ 2 }>
                            <h3 style={ { marginTop: 5, marginLeft: 5 } }>Source des clients au global</h3>
                            { this.state.userSource.length ? (
                                this.PieChartPrint("100%", 390, "source")
                            ) : (
                                <div
                                    style={ {
                                        height: 390,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    } }>
                                    il n&apos;y a pas de donnée pour le moment
                                </div>
                            ) }
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const style = {
    CardContainer: {
        padding: 18,
        textAlign: "center",
        fontSize: 25,
        color: "#000000",
        fontWeight: 400
    },
    CardMainContainer: {
        marginTop: 5,
        marginLeft: 5,
        fontSize: 22,
        letterSpacing: "normal",
        color: "#333333"
    },
    CardSubContainer: {
        textAlign: "right",
        color: "#a9a9a9",
        padding: 2,
        fontSize: 14
    }
};
