import Field from "./components/Field";
import Header from "./components/Header";
import Validate from "./components/Validate";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justifyContent: "center"
    },
    fields: {
        display: "flex",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        flexDirection: "column"
    }
}));

export default function BankDetails({ userInfo }) {
    const classes = useStyles();

    const [d, setData] = useState({ bic: userInfo.bic, iban: userInfo.iban });
    const data = { d, setData, userInfo };

    useEffect(() => {
        setData({ bic: userInfo.bic, iban: userInfo.iban });
    }, [userInfo]);

    return (
        <Card className={ classes.root }>
            <Header title="Vos coordonnées bancaires" subheader="pour personnaliser vos devis et factures" icon={ <AccountBalanceIcon /> } />
            <CardContent>
                <FormControl component="fieldset" fullWidth>
                    <div className={ classes.fields }>
                        <Field id="bic" label="Code BIC" data={ data } />
                        <Field id="iban" data={ data } />
                    </div>
                </FormControl>
            </CardContent>
            <Validate data={ data } />
        </Card>
    );
}
