import FbConfig from "../base";
import { getCurrentDate } from "../Tools";
import { IconButton, Tooltip } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React from "react";

export default function BookingValidated(props) {
    const { enqueueSnackbar } = useSnackbar();

    const user = JSON.parse(localStorage.getItem("token"));
    const values = props.order;
    const orderId = props.id;

    let dateNow = getCurrentDate("-");
    const handleOrder = () => {
        if (!values.orderIsConfirmed) {
            FbConfig.database()
                .ref("orders/" + user.uid + "/" + orderId)
                .update({
                    orderIsConfirmed: true,
                    orderIsConfirmedDate: dateNow
                })
                .then(() => {
                    //updateInvoiceNumber(user.uid, orderId, "invoice");
                    var sendMessage = FbConfig.functions().httpsCallable("sendConfirmationOrder");

                    sendMessage({
                        email: user.email,
                        username: user.username,
                        catalogType: values.catalogType,
                        client: values.client,
                        where: values.where,
                        placeId: values.placeId,
                        priceToPay: values.priceToPay,
                        date: values.date
                    });

                    enqueueSnackbar("Cette prestation est confirmée", {
                        variant: "success"
                    });
                })
                .catch(() => enqueueSnackbar("Un problème est survenu à la confirmation de la commande", { variant: "error" }));
        } else {
            enqueueSnackbar("Prestation déjà confirmée !", { variant: "info" });
        }
    };

    const getColor = () => {
        return true === values.orderIsConfirmed ? "primary" : "secondary";
    };

    const getMsgTooltip = () => {
        return true === values.orderIsConfirmed ? "Prestation confirmée" : "Prestation à confirmer";
    };

    return (
        <>
            { !values.orderIsConfirmed && (
                <Tooltip title={ getMsgTooltip() } arrow>
                    <IconButton aria-label="Confirmer la commande" color={ getColor() } onClick={ () => handleOrder() }>
                        <CheckCircleIcon color={ getColor() } />
                    </IconButton>
                </Tooltip>
            ) }
        </>
    );
}
