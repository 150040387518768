import FbConfig from "../base";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

// eslint-disable-next-line no-unused-vars
import React from "react";

export default class ClientEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            user: this.props.customer,
            open: false,
            setOpen: false
        };
    }

    handleClickOpen() {
        const user = JSON.parse(localStorage.getItem("token"));
        FbConfig.database()
            .ref("customers/" + user.uid + "/" + this.state.user.userId)
            .remove();
    }

    render() {
        return (
            <>
                <IconButton aria-label="Effacer" onClick={ () => this.handleClickOpen() }>
                    <DeleteIcon />
                </IconButton>
            </>
        );
    }
}
