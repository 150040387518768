import Ajv from "ajv";
import { setIn } from "formik";

const ajv = new Ajv({
    allErrors: true,
    errorDataPath: "property"
});

export const validate = (values, schema) => {
    values = removeEmpty(values);

    const valid = ajv.validate(require(`./${schema}.json`), values);

    if (!valid) {
        throw new ValidationError(reformatErrors(ajv.errors));
    }
};

const removeEmpty = obj => {
    let clone = { ...obj };
    Object.entries(clone).forEach(([key, value]) => {
        if (value && typeof value === "object") {
            removeEmpty(value);
        } else if (value == null || value === "") {
            delete clone[key];
        }
    });

    return clone;
};

const reformatErrors = errors => {
    return errors.reduce((errors, error) => {
        const dataPath = error.dataPath.charAt(0) === "." ? error.dataPath.slice(1) : error.dataPath;

        return setIn(errors, dataPath, translate(error));
    }, {});
};

const translate = error => {
    if (messages[error.keyword]) {
        let message = messages[error.keyword];

        for (let index in error.params) {
            message = message.replace(`%${index}%`, error.params[index]);
        }

        return message;
    }

    return error.message;
};

const messages = {
    required: "Ce champ est obligatoire",
    format: "Ne correspond pas au format \"%format%\""
};

export class ValidationError extends Error {
    constructor(errors) {
        super("Validation error");

        this.errors = errors;
    }
}
