import { StyleSheet, Text, View } from "@react-pdf/renderer";

// eslint-disable-next-line no-unused-vars
import React from "react";

const borderColor = "grey";
const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        borderBottomColor: "#D4CDCD",
        backgroundColor: "#D4CDCD",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        textAlign: "center",
        fontStyle: "bold",
        flexGrow: 1
    },
    description: {
        width: "60%",
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    qty: {
        width: "10%",
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    amount1: {
        width: "15%"
    },
    amount: {
        width: "15%",
        borderRightColor: borderColor,
        borderRightWidth: 1
    }
});

const InvoiceTableHeader = () => (
    <View style={ styles.container }>
        <Text style={ styles.description }>Description</Text>
        <Text style={ styles.qty }>Qte</Text>
        <Text style={ styles.amount }>Montant HT</Text>
        <Text style={ styles.amount1 }>Montant TTC</Text>
    </View>
);

export default InvoiceTableHeader;
