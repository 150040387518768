import AppBar from "./AppBar";
import { useAuthentication } from "./auth";
import FbConfig from "./base";
import Drawer from "./Drawer";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

export const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    }
}));

export default function Layout({ children }) {
    const auth = useAuthentication();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    let history = useHistory();

    if (!auth.isAuthenticated()) {
        return <Redirect to="/login" />;
    }

    FbConfig.auth().onIdTokenChanged(user => {
        if (null === user) {
            history.push("/logout");
        } else {
            setLoading(false);
        }
    });

    const toggleDrawer = () => {
        setOpen(!open);
    };

    if (loading) {
        return (
            <div style={ { position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" } }>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={ classes.root }>
            <AppBar open={ open } toggleDrawer={ toggleDrawer } />
            <Drawer open={ open } toggleDrawer={ toggleDrawer } />
            <main className={ classes.content }>
                <div className={ classes.toolbar } />
                { children }
            </main>
        </div>
    );
}

Layout.propTypes = {
    children: PropTypes.element.isRequired
};
