import makeStyles from "@material-ui/core/styles/makeStyles";

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useMemo, useRef } from "react";

const useStyles = makeStyles(() => ({
    paypal: {
        "& > #donate-button": {
            width: "100%"
        }
    }
}));

export default function Paypal() {
    const classes = useStyles();
    const buttonRef = useRef(null);
    const buttonId = useMemo(() => `ID-${Date.now()}`, []);
    useEffect(() => {
        const button = window.PayPal.Donation.Button({
            env: "production",
            hosted_button_id: "TATLZ947MTE6N",
            image: {
                src: "https://pics.paypal.com/00/s/YzY4ZGZhZjktZDgxMS00NDQyLWI2MTEtNjUyNWI3MjY1MGUw/file.PNG",
                alt: "Bouton Faites un don avec PayPal",
                title: "PayPal - The safer, easier way to pay online!"
            }
        });
        button.render(`#${buttonRef.current.id}`); // you can change the code and run it when DOM is ready
    }, []);

    return (
        <>
            { /* <form action="https://www.paypal.com/donate" method="post" target="_top">
                <input type="hidden" name="business" value="GP53K4RABB6NQ" />
                <input type="hidden" name="no_recurring" value="0" />
                <input type="hidden" name="item_name" value="développeur de l'application EzPresta" />
                <input type="hidden" name="currency_code" value="EUR" />
                <input
                    type="image"
                    src="https://www.paypalobjects.com/fr_FR/FR/i/btn/btn_donate_SM.gif"
                    border="0"
                    name="submit"
                    title="PayPal - The safer, easier way to pay online!"
                    alt="Bouton Faites un don avec PayPal"
                />
                <img alt="" border="0" src="https://www.paypal.com/fr_FR/i/scr/pixel.gif" width="1" height="1" />
            </form> */ }
            <div ref={ buttonRef } id={ buttonId } className={ classes.paypal } />
        </>
    );
}
