import { ValidationError, validate } from "../../forms";
import { LoginForm } from "../components";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React from "react";

import { Redirect, useHistory } from "react-router-dom";
import { useAuthentication } from "..";

export const Login = () => {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    let firstInstall = false;

    const { login, isAuthenticated, updateUser, loadCatalog } = useAuthentication();

    const onSubmit = (values, { setErrors, setSubmitting }) => {
        validate(values, "login");
        login(values.login, values.password)
            .then(async result => {
                if ("success" === result.returncode) {
                    let _username = null;

                    await updateUser(result.user.uid, result.user.email).then(user => {
                        loadCatalog();
                        _username = user.username;
                        firstInstall = user.firstInstall;
                    });
                    enqueueSnackbar(result.returnmsg + " " + _username + " !", { variant: result.returncode });

                    /** onboarding */
                    if (true === firstInstall) {
                        history.push("/onboarding");
                    } else {
                        history.push("/");
                    }
                } else {
                    setErrors({ form: result.message });
                }
                setSubmitting(false);
            })
            .catch(e => {
                if (e instanceof ValidationError) {
                    setErrors(e.errors);
                } else {
                    setErrors({ form: e.message });
                }
                setSubmitting(false);
            });
    };

    if (true === isAuthenticated()) {
        return <Redirect to="/" />;
    }

    return <LoginForm onSubmit={ onSubmit } />;
};
