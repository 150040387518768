import FbConfig from "./base";
import { getDuration } from "./catalog/CatalogTools";
// eslint-disable-next-line import/order
import FullCalendar from "@fullcalendar/react";
import frLocale from "@fullcalendar/core/locales/fr";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useObjectVal } from "react-firebase-hooks/database";

function addTimeToDate(date, hours, minutes) {
    date.setHours(date.getHours() + hours);
    date.setMinutes(date.getMinutes() + minutes);
    return date;
}

const Calendar = () => {
    const user = JSON.parse(localStorage.getItem("token"));
    const catalog = JSON.parse(localStorage.getItem("catalog"));
    const [value, loading, error] = useObjectVal(FbConfig.database().ref("orders/" + user.uid + "/"));
    let events = [];

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: { error.message }</div>;
    }

    if (value) {
        let tmpEvent = [];

        for (let val of Object.values(value)) {
            let categ = catalog.find(cat => cat.itemId === val.catalogId);
            let [hours, minutes] = categ && getDuration()[categ.duration] ? getDuration()[categ.duration].name.split("h").map(Number) : [1, 0];

            tmpEvent.push({
                id: 1,
                start: val.date,
                end: addTimeToDate(new Date(val.date), hours, minutes).toISOString(),
                title: `${val.catalogType} - ${val.client}`,
                backgroundColor: categ ? categ.color : "#3788d8"
            });
        }

        events = [...tmpEvent];
    }

    return (
        <FullCalendar
            plugins={ [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin] }
            locales={ [frLocale] }
            locale="fr"
            headerToolbar={ {
                left: "prev,next today",
                center: "title",
                right: "timeGridDay,timeGridWeek,dayGridMonth listYear"
            } }
            events={ events }
            initialView="timeGridWeek"
            firstDay={ 1 }
            weekends={ true }
        />
    );
};

export default Calendar;
