import { useAuthentication } from "./auth/Authentication";
import FbConfig from "./base";
import { FormikCheckbox, FormikSelect, FormikTextField } from "@dccs/react-formik-mui";
import { Avatar, Button, CardHeader, FormControl, Grid, Paper, TextField, makeStyles } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import LanguageIcon from "@material-ui/icons/Language";
import PhotoIcon from "@material-ui/icons/Photo";
import SettingsIcon from "@material-ui/icons/Settings";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";

import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        margin: theme.spacing(1)
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    card: {
        //fontSize: 30,
        //backgroundColor: "red",
    },
    form: {
        marginTop: theme.spacing(1),
        width: "100%"
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    },
    paper: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(1),
        padding: theme.spacing(2)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

export function getCurrencies() {
    return [
        { id: "EUR", name: "Euros (€)" },
        { id: "USD", name: "Dollars ($)" },
        { id: "RUB", name: "Roubles Russe (₽)" },
        { id: "GBP", name: "Livre sterling (£)" }
    ];
}

export function getLanguage() {
    return [
        { id: "FR", name: "Français" },
        { id: "EN", name: "Anglais" },
        { id: "RU", name: "Russe" }
    ];
}

export function Thumb(props) {
    const [loading, setLoading] = useState(false);
    const [thumb, setThumb] = useState("undefined");
    const [thumbName, setThumbName] = useState(false);

    useEffect(() => {
        if (props.base64) {
            setThumb(props.base64);
            setThumbName("tests");
        }

        if (!props.file) {
            return;
        }

        async function loadFile() {
            setLoading(true);

            let reader = new FileReader();

            reader.onloadend = () => {
                setLoading(false);
                setThumb(reader.result);
                setThumbName(props.file.name);
            };

            reader.readAsDataURL(props.file);
        }

        if (!props.base64) {
            loadFile();
        }
    }, [props.file, props.base64]);

    return (
        <>
            { loading && <p>chargement ...</p> }
            { thumbName && <img id="displayLogo" name="displayLogo" src={ thumb } alt={ thumbName } className="img-thumbnail mt-2" height={ 200 } width={ 200 } /> }
        </>
    );
}

const validationSchema = Yup.object().shape({
    accountCompany: Yup.string().ensure().required("Entreprise est obligatoire").nullable(false),
    accountEmail: Yup.string().ensure().email("Email invalide").required("Email est obligatoire"),
    accountSiret: Yup.number().typeError("SIRET doit être un nombre").min(14, "Un numéro de SIRET fait 14 caractères").required("Siret est obligatoire"),
    setName: Yup.string().ensure().required("Nom est obligatoire")
});

export default function UserSettings() {
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem("token"));
    const userSettings = useAuthentication();
    const [userInfo, setUserInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const isMountedRef = React.useRef(null);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        isMountedRef.current = true;

        if (false === loading) {
            FbConfig.database()
                .ref("users/" + user.uid + "/")
                .on("value", snapshot => {
                    if (isMountedRef.current) {
                        setUserInfo(snapshot.val());
                    }
                });
            if (userInfo) {
                setLoading(true);
            }
        }
        return () => (isMountedRef.current = false);
    }, [userInfo, user.uid, loading]);

    const getBase64 = (values, setFieldValue) => {
        let reader = new FileReader();

        reader.readAsDataURL(values);
        reader.onload = () => {
            setFieldValue("accountLogoB64", reader.result);
            setFieldValue("accountLogoName", values.name);
        };
    };

    return (
        <Paper className={ classes.paper }>
            { loading && (
                <Formik
                    validateOnBlur={ false }
                    validateOnChange={ false }
                    initialValues={ {
                        accountCompany: userInfo.company ? userInfo.company : null,
                        accountEmail: userInfo.email ? userInfo.email : null,
                        // accountPassword: userInfo.password ? userInfo.password : null,
                        accountLogo: null,
                        accountLogoB64: userInfo.logo ? userInfo.logo : "",
                        accountSiret: userInfo.siret ? userInfo.siret : null,
                        accountTVA: userInfo.tva ? userInfo.tva : false,
                        accountTel: userInfo.phone ? userInfo.phone : null,
                        accountURL: userInfo.url ? userInfo.url : null,
                        invoice: userInfo.invoice ? userInfo.invoice : 250,
                        quote: userInfo.quote ? userInfo.quote : 250,
                        setAddress: userInfo.address ? userInfo.address : null,
                        setBIC: userInfo.bic ? userInfo.bic : null,
                        setCurrencies: userInfo.currencies ? userInfo.currencies : "EUR",
                        setIBAN: userInfo.iban ? userInfo.iban : null,
                        setKmPrice: userInfo.kmPrice ? userInfo.kmPrice : 0.0,
                        setLanguage: userInfo.language ? userInfo.language : "FR",
                        setName: userInfo.name ? userInfo.name : null
                    } }
                    validationSchema={ validationSchema }
                    onSubmit={ (values, { setSubmitting }) => {
                        setTimeout(() => {
                            FbConfig.database()
                                .ref("users/" + user.uid)
                                .update({
                                    address: values["setAddress"],
                                    bic: values["setBIC"],
                                    company: values["accountCompany"],
                                    currencies: values["setCurrencies"],
                                    email: values["accountEmail"],
                                    iban: values["setIBAN"],
                                    invoice: values["invoice"],
                                    kmPrice: values["setKmPrice"],
                                    language: values["setLanguage"],
                                    //password: values["accountPassword"],
                                    logo: values["accountLogoB64"],
                                    name: values["setName"],
                                    quote: values["quote"],
                                    siret: values["accountSiret"],
                                    tel: values["accountTel"],
                                    tva: values["accountTVA"] ? true : false,
                                    url: values["accountURL"]
                                })
                                .then(() => {
                                    if (values["setKmPrice"] !== userSettings.user.kmPrice) {
                                        userSettings.user.kmPrice = values["setKmPrice"];
                                    }

                                    if (values["setAddress"] !== userSettings.user.address) {
                                        userSettings.user.address = values["setAddress"];
                                    }

                                    if (values["setName"] !== userSettings.user.username) {
                                        userSettings.user.username = values["setName"];
                                    }

                                    if (values["setBIC"] !== userSettings.user.bic) {
                                        userSettings.user.bic = values["setBIC"];
                                    }

                                    if (values["setIBAN"] !== userSettings.user.iban) {
                                        userSettings.user.iban = values["setIBAN"];
                                    }

                                    if (user.email !== values["accountEmail"]) {
                                        let _user = FbConfig.auth().currentUser;
                                        _user.updateEmail(values["accountEmail"]).then(() => enqueueSnackbar("L'email a bien été changé", { variant: "success" }));
                                    }

                                    enqueueSnackbar("Enregistrement des modifications", {
                                        variant: "success"
                                    });
                                })
                                .catch(() => enqueueSnackbar("Un problème est survenu à l'enregistrement des modifications", { variant: "error" }));
                            setSubmitting(false);
                        }, 400);
                    } }>
                    { ({ isSubmitting, values, setFieldValue }) => (
                        <Form className={ classes.form } noValidate>
                            <FormControl component="fieldset" fullWidth>
                                <CardHeader
                                    titleTypographyProps={ { variant: "h6" } }
                                    title="Compte utilisateur"
                                    subheader=""
                                    avatar={
                                        <Avatar className={ classes.avatar }>
                                            <AccountCircleIcon />
                                        </Avatar>
                                    }
                                    className={ classes.card }
                                />
                                <FormikTextField margin="normal" required fullWidth id="setName" name="setName" label="Nom" />
                                <FormikTextField margin="normal" required fullWidth id="accountEmail" name="accountEmail" label="Email" />
                                <CardHeader
                                    titleTypographyProps={ { variant: "h6" } }
                                    title="Votre entreprise"
                                    subheader=""
                                    avatar={
                                        <Avatar className={ classes.avatar }>
                                            <BusinessCenterIcon />
                                        </Avatar>
                                    }
                                    className={ classes.card }
                                />
                                <FormikCheckbox label="assujetti à la TVA ? (prochainement)" name="accountTVA" id="accountTVA" color="primary" margin="normal" disabled={ true } />
                                <FormikTextField margin="normal" required fullWidth id="accountCompany" name="accountCompany" label="Entreprise" />
                                <FormikTextField margin="normal" required fullWidth id="setAddress" name="setAddress" label="Adresse" />
                                <FormikTextField type="number" margin="normal" fullWidth id="accountSiret" name="accountSiret" label="Siret" />
                                <FormikTextField type="url" margin="normal" required fullWidth id="accountURL" name="accountURL" label="Site WEB" />
                                <FormikTextField margin="normal" fullWidth type="tel" id="accountTel" name="accountTel" label="Téléphone" />{ " " }
                                <CardHeader
                                    titleTypographyProps={ { variant: "h6" } }
                                    title="Vos coordonnées bancaires"
                                    subheader="pour personnaliser vos devis et factures"
                                    avatar={
                                        <Avatar className={ classes.avatar }>
                                            <AccountBalanceIcon />
                                        </Avatar>
                                    }
                                    className={ classes.card }
                                />
                                <FormikTextField margin="normal" required fullWidth id="setBIC" name="setBIC" label="Code BIC" />
                                <FormikTextField margin="normal" required fullWidth id="setIBAN" name="setIBAN" label="IBAN" />{ " " }
                                <CardHeader
                                    titleTypographyProps={ { variant: "h6" } }
                                    title="Votre logo"
                                    subheader=""
                                    avatar={
                                        <Avatar className={ classes.avatar }>
                                            <PhotoIcon />
                                        </Avatar>
                                    }
                                    className={ classes.card }
                                />
                                <TextField
                                    fullWidth
                                    id="accountLogo"
                                    name="accountLogo"
                                    label="Votre logo"
                                    margin="normal"
                                    type="file"
                                    onChange={ event => {
                                        setFieldValue("accountLogo", event.currentTarget.files[0]);
                                        getBase64(event.currentTarget.files[0], setFieldValue);
                                    } }
                                />
                                <input id="accountLogoB64" name="accountLogoB64" type="text" value={ values.accountLogoB64 } />
                                <Thumb file={ values.accountLogo } base64={ values.accountLogoB64 } />
                                <br />
                                <Grid container spacing={ 1 }>
                                    <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                                        <CardHeader
                                            titleTypographyProps={ { variant: "h6" } }
                                            title="Paramètres"
                                            subheader="personnaliser votre interface, vos factures et devis"
                                            avatar={
                                                <Avatar className={ classes.avatar }>
                                                    <SettingsIcon />
                                                </Avatar>
                                            }
                                        />
                                        <br />
                                        <FormikTextField type="number" margin="normal" id="setKmPrice" name="setKmPrice" label="Prix au kilomètre (en €)" placeholder="0.4" inputProps={ { max: "10", min: "0", step: "0.1" } } disabled={ true } />
                                        <FormikTextField margin="normal" required fullWidth id="invoice" name="invoice" label="Numérotation Facture" />
                                        <FormikTextField margin="normal" required fullWidth id="quote" name="quote" label="Numérotation Devis" />{ " " }
                                    </Grid>
                                    <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                                        <CardHeader
                                            titleTypographyProps={ { variant: "h6" } }
                                            title="Paramètres régionaux (prochainement)"
                                            subheader="personnaliser votre interface"
                                            avatar={
                                                <Avatar className={ classes.avatar }>
                                                    <LanguageIcon />
                                                </Avatar>
                                            }
                                        />
                                        <FormikSelect
                                            id="setLanguage"
                                            name="setLanguage"
                                            label="Langue"
                                            disabled={ true }
                                            options={ getLanguage().map(item => ({
                                                key: item.id,
                                                value: item.name
                                            })) }
                                            onChange={ (event, value) => {
                                                setFieldValue(event.target.name, value.props.value);
                                            } }
                                        />
                                        <br />
                                        <FormikSelect
                                            id="setCurrencies"
                                            name="setCurrencies"
                                            label="Devises"
                                            disabled={ true }
                                            options={ getCurrencies().map(item => ({
                                                key: item.id,
                                                value: item.name
                                            })) }
                                            onChange={ (event, value) => {
                                                setFieldValue(event.target.name, value.props.value);
                                            } }
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>

                            <div className={ classes.buttons }>
                                <Button type="submit" variant="contained" color="primary" className={ classes.submit } disabled={ isSubmitting }>
                                    Valider
                                </Button>
                            </div>
                        </Form>
                    ) }
                </Formik>
            ) }
        </Paper>
    );
}
