import FbConfig from "../base";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";

const StyledBadge = withStyles(theme => ({
    badge: {
        right: 0,
        top: 3,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: "0 4px"
    }
}))(Badge);

const useStyles = makeStyles(() => ({
    mr10: {
        marginRight: 10
    },
    AlignSelfCenter: {
        alignSelf: "center"
    },
    btn: {
        backgroundImage: "linear-gradient(to right,#3b82f6,#2563eb,#1d4ed8)",
        color: "rgb(255 255 255/1)",
        "&.Mui-disabled": {
            background: "#fff"
        }
    }
}));

export default function BookingUpload(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const user = JSON.parse(localStorage.getItem("token"));

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState(null);

    const orderId = props.id;
    const firebaseURL = `${user.uid}/booking/${orderId}`;

    // execute at first
    React.useEffect(() => {
        getFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (progress !== 0) {
            enqueueSnackbar("Please wait until uploading is finished", {
                variant: "warning"
            });
            return;
        }
        setOpen(false);
    };

    const uploadFile = file => {
        setLoading(true);

        if (file.size / 1024 / 1024 > 5) {
            enqueueSnackbar("File size is too large", {
                variant: "warning"
            });
            return;
        }

        const storageRef = FbConfig.storage().ref(`${firebaseURL}/${file.name}`);
        const uploadTask = storageRef.put(file);

        uploadTask.on(
            "state_changed",
            snapshot => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            },
            error => {
                setLoading(false);
                setProgress(0);
                enqueueSnackbar(error.message, { variant: "error" });
            },
            () => {
                setLoading(false);
                setProgress(0);
                enqueueSnackbar("File uploaded successfully", { variant: "success" });
                getFiles();
            }
        );
    };

    const getFiles = () => {
        const storageRef = FbConfig.storage().ref(`${firebaseURL}`);
        storageRef.listAll().then(result => {
            setFiles(result.items);
        });
    };

    const downloadFile = file => {
        const storageRef = FbConfig.storage().ref(`${firebaseURL}/${file.name}`);
        storageRef.getDownloadURL().then(url => {
            window.open(url);
        });
    };

    const deleteFile = file => {
        const storageRef = FbConfig.storage().ref(`${firebaseURL}/${file.name}`);
        storageRef
            .delete()
            .then(() => {
                enqueueSnackbar("File deleted successfully", {
                    variant: "success"
                });
                getFiles();
            })
            .catch(error => {
                enqueueSnackbar(error.message, {
                    variant: "error"
                });
            });
    };

    return (
        <>
            <Tooltip title="Upload">
                <IconButton onClick={ handleClickOpen }>
                    <StyledBadge badgeContent={ files?.length || 0 } color="secondary">
                        <CloudUploadIcon />
                    </StyledBadge>
                </IconButton>
            </Tooltip>
            <Dialog open={ open } onClose={ handleClose } aria-labelledby="form-dialog-title">
                <DialogTitle>Upload File</DialogTitle>
                <DialogContent>
                    <Table>
                        <TableBody>
                            { files?.map(file => (
                                <TableRow key={ file.name }>
                                    <TableCell>{ file.name }</TableCell>
                                    <TableCell>
                                        <IconButton onClick={ () => downloadFile(file) }>
                                            <GetAppIcon />
                                        </IconButton>
                                        <IconButton onClick={ () => deleteFile(file) } color="secondary">
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )) }
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions className={ classes.AlignSelfCenter }>
                    <Button variant="contained" component="label" disabled={ loading } className={ classes.btn } startIcon={ <CloudUploadIcon /> }>
                        { progress !== 0 ? `Uploading: ${progress}%` : "Upload File" }
                        <input type="file" onChange={ e => uploadFile(e.target.files[0]) } hidden />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
