// eslint-disable-next-line no-unused-vars, import/order
import React from "react";
import "./App.css";
import { AuthenticationProvider } from "./auth";
import Router from "./Router";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";

export default class App extends React.Component {
    render() {
        return (
            <div id="ez_start">
                <AuthenticationProvider>
                    <SnackbarProvider>
                        <BrowserRouter>
                            <Router />
                        </BrowserRouter>
                    </SnackbarProvider>
                </AuthenticationProvider>
            </div>
        );
    }
}
