import { AuthenticationContext } from "./auth";
import { drawerWidth } from "./Layout";
import Paypal from "./Paypal";
import { Avatar, Hidden } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { default as BaseDrawer } from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import EventIcon from "@material-ui/icons/Event";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SettingsIcon from "@material-ui/icons/Settings";
import PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up("md")]: {
            width: drawerWidth,
            flexShrink: 0
        }
    },
    drawerPaper: {
        width: drawerWidth
    },
    toolbar: theme.mixins.toolbar,
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        borderWidth: 2,
        borderColor: "#E6E6E6",
        //borderTopLeftRadius: 1,
        borderStyle: "solid"
    },
    avatar: {
        backgroundColor: "#f5f5f5",
        alignItem: "center",
        textAlign: "-webkit-center"
    }
}));

export default function Drawer({ open, toggleDrawer }) {
    const classes = useStyles();

    return (
        <>
            <Hidden smDown implementation="css">
                <BaseDrawer variant="permanent" className={ classes.drawer } classes={ { paper: classes.drawerPaper } }>
                    <div className={ classes.toolbar } />
                    <div className={ classes.avatar }>
                        <AuthenticationContext.Consumer>{ value => value.user && <Avatar alt={ value.user.username } src={ value.user.logo } className={ classes.large } /> }</AuthenticationContext.Consumer>
                    </div>
                    <DrawerContent classes={ classes } />
                </BaseDrawer>
            </Hidden>
            <Hidden mdUp implementation="css">
                <BaseDrawer variant="temporary" open={ open } onClose={ toggleDrawer } onClick={ toggleDrawer } classes={ { paper: classes.drawerPaper } } ModalProps={ { keepMounted: true } }>
                    <DrawerContent />
                </BaseDrawer>
            </Hidden>
        </>
    );
}

Drawer.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleDrawer: PropTypes.func.isRequired
};

const DrawerContent = () => (
    <>
        <List>
            <ListItem button key="Tableau de bord" component={ Link } to="/home">
                <ListItemIcon>
                    <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Tableau de bord" />
            </ListItem>
            <Divider />
            <ListItem button key="catalog" component={ Link } to="/catalog">
                <ListItemIcon>
                    <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Catalogue" />
            </ListItem>
            <Divider />
            <ListItem button key="users" component={ Link } to="/customers">
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Clients" />
            </ListItem>
            <Divider />
            <ListItem button key="agenda" component={ Link } to="/orders">
                <ListItemIcon>
                    <EventIcon />
                </ListItemIcon>
                <ListItemText primary="Agenda" />
            </ListItem>
            <Divider />
            <ListItem button key="addMeeting" component={ Link } to="/new_meeting">
                <ListItemIcon>
                    <ScheduleIcon />
                </ListItemIcon>
                <ListItemText primary="Nouveau RDV" />
            </ListItem>
            <Divider />
            <ListItem button key="addOrder" component={ Link } to="/new_order">
                <ListItemIcon>
                    <AddShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Nouvelle commande" />
            </ListItem>
            <Divider />
            <ListItem button key="System" component={ Link } to="/settings">
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Réglages" />
            </ListItem>
            <Divider />
            <ListItem button key="System">
                <Paypal />
            </ListItem>
        </List>
    </>
);
