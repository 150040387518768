import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BusinessIcon from "@material-ui/icons/Business";
import CallIcon from "@material-ui/icons/Call";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import HomeIcon from "@material-ui/icons/Home";
import LockIcon from "@material-ui/icons/Lock";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import WebIcon from "@material-ui/icons/Web";
import { AsYouType } from "libphonenumber-js";

// eslint-disable-next-line no-unused-vars
import React from "react";

const useStyles = makeStyles(theme => ({
    field: {
        marginBottom: theme.spacing(2)
    }
}));

export default function Field({ id, label, data: { d, setData }, icon, form = "_", type = "text", required = false }) {
    const classes = useStyles();
    const asYouType = new AsYouType("FR");
    const func = {
        phone: value => asYouType.input(value),
        _: value => value
    };
    const icons = {
        telephone: <CallIcon />,
        email: <MailOutlineIcon />,
        name: <AccountCircleIcon />,
        address: <HomeIcon />,
        company: <BusinessIcon />,
        url: <WebIcon />,
        siret: <ConfirmationNumberIcon />,
        old_password: <LockIcon />,
        new_password: <LockIcon />
    };

    const StartIcon = () => {
        if (icons[icon || id]) {
            return <InputAdornment position="start">{ icons[id] }</InputAdornment>;
        }
        return null;
    };

    const handleChange = event => {
        setData({ ...d, [event.target.id]: func[form](event.target.value) });
    };

    return <TextField id={ id } label={ label || (id.charAt(0).toUpperCase() + id.slice(1)).replace(/_/g, " ") } value={ d[id] } onChange={ handleChange } className={ classes.field } required={ required } InputProps={ { startAdornment: <StartIcon /> } } type={ type } />;
}
