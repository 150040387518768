import { StyleSheet, Text, View } from "@react-pdf/renderer";
// eslint-disable-next-line no-unused-vars
import React from "react";

const styles = StyleSheet.create({
    tvaContainer: {
        fontSize: 7,
        textAlign: "left",
        marginTop: 10
        //width: '50%',
    }
});

const InvoiceTVA = ({ user }) => (
    <View style={ styles.tvaContainer }>
        <Text>T.V.A. non applicable ou exonérée</Text>
        <Text>Micro-entreprise, TVA non applicable en vertu de l&apos;article 293 B du CGI</Text>
        <Text>Durée de validité: 3 mois</Text>
        <Text>Mode de règlement: Virement bancaire</Text>
        <Text>BIC: { user.bic }</Text>
        <Text>IBAN: { user.iban }</Text>
    </View>
);

export default InvoiceTVA;
