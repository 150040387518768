import { useAuthentication } from "../auth/Authentication";
import FbConfig from "../base";
import AutocompleteClient from "../client/AutocompleteClient";
import Client from "../client/Client";
import { getCurrentDate } from "../Tools";
import DateFnsUtils from "@date-io/date-fns";
import { FormikCheckbox, FormikDateTimepicker } from "@dccs/react-formik-mui";

import { FormControl, FormLabel, Grid, Paper, makeStyles } from "@material-ui/core";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import parse from "autosuggest-highlight/parse";
import { Form, Formik } from "formik";
// eslint-disable-next-line no-restricted-imports
import throttle from "lodash/throttle";
import { useSnackbar } from "notistack";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

//GoogleMaps Start
function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };
//GoogleMaps End

const useStyles = makeStyles(theme => ({
    paper: {
        margin: `${theme.spacing(2)}px auto`,
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    form: {
        marginTop: theme.spacing(1),
        width: "100%"
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    }
}));

const SignupSchema = Yup.object().shape({
    date: Yup.date().required("Vous devez choisir une date pour la prestation")
});

export default function MeetingAdd() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const userSettings = useAuthentication();
    let userAddress = !userSettings.user.address ? "à la maison" : userSettings.user.address;

    const history = useHistory();

    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState([]);
    const [allClients, setClients] = React.useState([]);
    const [noClients, setNoClients] = React.useState(false);
    const [getPlaceId, setPlaceId] = React.useState({});
    const [getClientId, setClientId] = React.useState("");

    const loaded = React.useRef(false);
    const user = JSON.parse(localStorage.getItem("token"));

    const getAllClients = () => {
        FbConfig.database()
            .ref("customers/" + user.uid + "/")
            .on("value", snapshot => {
                let customers = snapshot.val();
                let newState = [];
                if (customers) {
                    for (let customer in customers) {
                        newState.push({
                            userId: customer,
                            firstname: customers[customer].firstname
                        });
                    }
                }
                if (newState.length > 0) {
                    setClients(newState);
                } else {
                    setNoClients(true);
                }
            });
    };

    if (typeof window !== "undefined" && !loaded.current) {
        if (!document.querySelector("#google-maps")) {
            loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyCYVIg1bNAfhwm7CaHjnWo5RApHWgoG6FY&libraries=places", document.querySelector("head"), "google-maps");
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((input, callback) => {
                autocompleteService.current.getPlacePredictions(input, callback);
            }, 200),
        []
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === "") {
            setOptions([]);
            return undefined;
        }

        if (allClients && !allClients.length && false === noClients) {
            getAllClients();
        }

        fetch({ input: inputValue }, results => {
            if (active) {
                setOptions(results || []);
            }
        });

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue, fetch]);

    // Distance et geoloc
    const fill2 = (e, setFieldValue, setValue) => {
        if (true === setValue) {
            setFieldValue("where", e.target.value);
        }
        setInputValue(e.target.value);
    };

    if (allClients && !allClients.length && false === noClients) {
        getAllClients();
    }

    const searchClient = allClients.map(function (param) {
        return param;
    });

    function placeIdSet(params) {
        setPlaceId(params);
    }

    function placeIdGet() {
        return getPlaceId;
    }

    function ClientSet(params) {
        setClientId(params);
    }

    function ClientGet() {
        return getClientId;
    }

    return (
        <Paper className={ classes.paper }>
            <Formik
                validateOnBlur={ false }
                validateOnChange={ false }
                initialValues={ {
                    client: "",
                    where: "",
                    date: getCurrentDate("-"),
                    place_id: ""
                } }
                validationSchema={ SignupSchema }
                onSubmit={ (values, { setSubmitting }) => {
                    setTimeout(() => {
                        const user = JSON.parse(localStorage.getItem("token"));
                        const dateFormat = values["date"].toISOString();

                        values["client"] = ClientGet()["name"];
                        values["client_id"] = ClientGet()["id"];
                        values["place_id"] = placeIdGet()["place_id"];

                        if (!values.client || !values.client_id) {
                            enqueueSnackbar("Vous devez choisir un client", { variant: "error" });
                            setSubmitting(false);
                            return;
                        }

                        if (!(values.place_id || values.athome)) {
                            enqueueSnackbar("Vous devez choisir une adresse", { variant: "error" });
                            setSubmitting(false);
                            return;
                        }
                        if (!values.date) {
                            enqueueSnackbar("Vous devez choisir une date", { variant: "error" });
                            setSubmitting(false);
                            return;
                        }

                        FbConfig.database()
                            .ref("orders/" + user.uid + "/")
                            .push({
                                uid: user.uid,
                                catalogType: "RDV",
                                catalogDuration: 1,
                                client: values["client"],
                                client_id: values["client_id"],
                                createDate: getCurrentDate("-"),
                                where: true === values["athome"] ? "home" : values["where"],
                                placeId: true === values["athome"] ? null : values["place_id"],
                                date: dateFormat
                            })
                            .then(() => {
                                enqueueSnackbar("Enregistrement du rendez-vous", { variant: "success" });
                                history.push("/orders");
                            })
                            .catch(() => enqueueSnackbar("Un problème est survenu à l'enregistrement du rendez-vous", { variant: "error" }));
                        setSubmitting(false);
                    }, 400);
                } }>
                { ({ isSubmitting, values, handleChange, handleBlur, setFieldValue }) => (
                    <Form className={ classes.form } noValidate>
                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend">Nouveau rendez-vous</FormLabel>
                            <AutocompleteClient suggestions={ searchClient } ClientGet={ ClientGet } ClientSet={ ClientSet } />
                            <Client fromForm="orders" />
                            <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                                <FormikDateTimepicker id="date" name="date" margin="normal" label="Quand" fullWidth keyboard="true" clearable="true" autoOk minDate={ new Date() } format="dd/MM/yyyy HH:mm:ss" required></FormikDateTimepicker>
                            </MuiPickersUtilsProvider>
                            <FormikCheckbox
                                label={ "À domicile : " + userAddress }
                                name="athome"
                                id="athome"
                                color="primary"
                                onChange={ e => {
                                    handleChange(e);
                                } }
                            />
                            { (!values.athome || false === values.athome) && (
                                <>
                                    <Autocomplete
                                        id="where"
                                        getOptionLabel={ option => (typeof option === "string" ? option : option.description) }
                                        onChange={ (event, value) => {
                                            if (value) {
                                                placeIdSet(value);
                                            }
                                        } }
                                        filterOptions={ x => x }
                                        options={ options }
                                        autoComplete
                                        includeInputInList
                                        freeSolo
                                        //disableOpenOnFocus
                                        renderInput={ params => (
                                            <TextField
                                                { ...params }
                                                label="Où"
                                                name="where"
                                                fullWidth
                                                margin="normal"
                                                onChange={ e => {
                                                    handleChange(e);
                                                    fill2(e, setFieldValue);
                                                } }
                                                onBlur={ e => {
                                                    handleBlur(e);
                                                    fill2(e, setFieldValue, true);
                                                } }
                                            />
                                        ) }
                                        renderOption={ option => {
                                            const matches = option.structured_formatting.main_text_matched_substrings;
                                            const parts = parse(
                                                option.structured_formatting.main_text,
                                                matches.map(match => [match.offset, match.offset + match.length])
                                            );

                                            return (
                                                <Grid container alignItems="center">
                                                    <Grid item>
                                                        <LocationOnIcon className={ classes.icon } />
                                                    </Grid>
                                                    <Grid item xs>
                                                        { parts.map((part, index) => (
                                                            <span
                                                                key={ index }
                                                                style={ {
                                                                    fontWeight: part.highlight ? 700 : 400
                                                                } }>
                                                                { part.text }
                                                            </span>
                                                        )) }

                                                        <Typography variant="body2" color="textSecondary">
                                                            { option.structured_formatting.secondary_text }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            );
                                        } }
                                    />
                                </>
                            ) }
                        </FormControl>
                        <div className={ classes.buttons }>
                            <Button type="submit" variant="contained" color="primary" className={ classes.submit } disabled={ isSubmitting }>
                                Valider
                            </Button>
                        </div>
                    </Form>
                ) }
            </Formik>
        </Paper>
    );
}
