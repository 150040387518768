import ItemForm from "../onboarding/itemForm";

import { makeStyles } from "@material-ui/core";

// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    form: {
        marginTop: theme.spacing(1),
        width: "100%"
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    },
    card: {
        //fontSize: 30,
        //backgroundColor: "red",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },

    infoContainer: {
        display: "table",
        tableLayout: "fixed",
        width: "100%",
        marginBottom: 30,
        marginLeft: 20
    },

    infoUser: {
        paddingLeft: 15
    },

    infoIcon: {
        textAlign: "center",
        width: 60,
        color: "#979799"
    },

    infoSub: {
        display: "table-cell",
        overflow: "hidden",
        paddingTop: 20,
        textOverflow: "ellipsis",
        verticalAlign: "middle"
    },

    infoTitle: {
        fontSize: 19
    },

    infoSubTitle: {
        marginLeft: 20,
        marginBottom: 10,
        fontSize: 12
    },

    displayError: {
        //fontFamily: "Georgia, serif",
        fontSize: "9px",
        //letterSpacing: "2px",
        wordSpacing: "2px",
        color: "#FF0505",
        //fontWeight: 700,
        textDecoration: "none",
        fontStyle: "italic",
        fontVariant: "normal",
        textTransform: "none"
    }
}));

const Bank = ({ setForm, formData }) => {
    const { bic, iban } = formData;

    const [_iban, setIban] = useState(null);
    const [_bic, setBic] = useState(null);

    const classes = useStyles();

    const checkIBAN = e => {
        setIban(e.target.value);
        setForm(e);
    };

    const checkBIC = e => {
        setBic(e.target.value);
        setForm(e);
    };

    const ibanError = () => {
        if (null !== _iban) {
            if (_iban.length < 14 || _iban.length > 34) {
                return "Votre IBAN ne contient pas le nombre de caractères attendu";
            }
        }
    };

    const bicError = () => {
        if (null !== _bic) {
            if (_bic.length < 8 || _iban.length > 11) {
                return "Votre BIC ne contient pas le nombre de caractères attendu";
            }
        }
    };

    return (
        <>
            <div className={ classes.infoTitle }>Vos coordonnées bancaires</div>
            <div className={ classes.infoSubTitle }>uniquement pour personnaliser vos devis et factures</div>
            <div className="form" spacing={ 1 }>
                <div className={ classes.infoContainer }>
                    <ItemForm label="IBAN" name="iban" value={ iban } onPaste={ e => checkIBAN(e) } onChange={ e => checkIBAN(e) } />
                    <span className={ classes.displayError }>
                        <br />
                        { ibanError() }
                    </span>
                </div>

                <div className={ classes.infoContainer }>
                    <ItemForm label="BIC" name="bic" value={ bic } onPaste={ e => checkBIC(e) } onChange={ e => checkBIC(e) } />
                    <span className={ classes.displayError }>
                        <br />
                        { bicError() }
                    </span>
                </div>
            </div>
        </>
    );
};

export default Bank;
