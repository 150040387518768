import ItemForm from "../onboarding/itemForm";

import { makeStyles } from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BusinessIcon from "@material-ui/icons/Business";
import LinkIcon from "@material-ui/icons/Link";
import RoomIcon from "@material-ui/icons/Room";
import clsx from "clsx";

// eslint-disable-next-line no-unused-vars
import React from "react";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    form: {
        marginTop: theme.spacing(1),
        width: "100%"
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    },
    card: {
        //fontSize: 30,
        //backgroundColor: "red",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },

    infoContainer: {
        display: "table",
        tableLayout: "fixed",
        width: "100%",
        marginBottom: 30
    },

    infoUser: {
        paddingLeft: 15
    },

    infoIcon: {
        textAlign: "center",
        width: 60,
        color: "#979799"
    },

    infoSub: {
        display: "table-cell",
        overflow: "hidden",
        paddingTop: 20,
        textOverflow: "ellipsis",
        verticalAlign: "middle"
    },

    infoTitle: {
        marginBottom: 10,
        fontSize: 19
    }
}));

const Company = ({ setForm, formData }) => {
    const { company, address, siret, url } = formData;

    const classes = useStyles();

    return (
        <>
            <div className={ classes.infoTitle }>Votre entreprise</div>
            <div className="form" spacing={ 1 }>
                <div className={ classes.infoContainer }>
                    <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                        <BusinessIcon />
                    </div>
                    <ItemForm label="Entreprise" name="company" value={ company } onChange={ setForm } />
                </div>

                <div className={ classes.infoContainer }>
                    <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                        <RoomIcon />
                    </div>
                    <ItemForm label="Adresse" name="address" value={ address } onChange={ setForm } />
                </div>

                <div className={ classes.infoContainer }>
                    <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                        <AccountBalanceIcon />
                    </div>
                    <ItemForm label="Siret" name="siret" value={ siret } onChange={ setForm } />
                </div>

                <div className={ classes.infoContainer }>
                    <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                        <LinkIcon />
                    </div>

                    <ItemForm label="Site" name="url" value={ url } onChange={ setForm } />
                </div>

                { /* <ItemForm
          label="Assujetti à la TVA ?"
          disabled={true}
          name="tva"
          value={tva}
          onChange={setForm}
        /> */ }
            </div>
        </>
    );
};

export default Company;
