/*global go, navigation*/

import { useAuthentication } from "../auth/Authentication";
import FbConfig from "../base";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React from "react";

const Review = ({ formData }) => {
    const user = JSON.parse(localStorage.getItem("token"));
    const userSettings = useAuthentication();
    const { enqueueSnackbar } = useSnackbar();

    const { name, phone, company, siret, address, url, bic, iban, invoice, quote, logo } = formData;
// eslint-disable-next-line no-unused-vars
    const { go } = navigation;

    setTimeout(() => {
        FbConfig.database()
            .ref("users/" + user.uid)
            .update({
                company: company,
                address: address,
                siret: siret,
                phone: phone,
                url: url,
                name: name,
                logo: logo,
                bic: bic,
                iban: iban,
                invoice: parseInt(invoice),
                quote: parseInt(quote),
                firstInstall: false
            })
            .then(() => {
                if (address !== userSettings.user.address) {
                    userSettings.user.address = address;
                }

                if (name !== userSettings.user.username) {
                    userSettings.user.username = name;
                }

                if (bic !== userSettings.user.bic) {
                    userSettings.user.bic = bic;
                }

                if (iban !== userSettings.user.iban) {
                    userSettings.user.iban = iban;
                }

                enqueueSnackbar("Enregistrement des modifications", {
                    variant: "success"
                });
            })
            .catch(() => enqueueSnackbar("Un problème est survenu à l'enregistrement des modifications", { variant: "error" }));
    }, 400);

    return (
        <div className="form">
            <div>
                <span>
                    Merci { `${name}` }{ " " }
                    <span role="img" aria-label="tada">
                        🎉
                    </span>
                </span>
                <p>
                    Vous pouvez maintenant créer votre catalogue produit et vos premières commandes.{ " " }
                    <span role="img" aria-label="thumpup">
                        👍
                    </span>
                </p>
                <br />
            </div>
        </div>
    );
};

export default Review;
