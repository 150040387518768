import { loadCatalog } from "./CatalogTools";
import FbConfig from "../base";
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
// eslint-disable-next-line no-unused-vars
import React from "react";

export default function CatalogDelete(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const removeItem = props => {
        const user = JSON.parse(localStorage.getItem("token"));
        const fbUrl = "catalog/" + user.uid + "/" + props.item.id + "/";

        FbConfig.database()
            .ref(fbUrl)
            .remove()
            .then(() => {
                loadCatalog(user.uid);
                enqueueSnackbar("Le produit a été effacé", { variant: "success" });
            })
            .catch(() => enqueueSnackbar("Erreur lors de la suppression", { variant: "error" }));
    };

    return (
        <>
            <IconButton aria-label="Supprimer un produit" color="primary" onClick={ () => handleClickOpen(props) }>
                <DeleteIcon />
            </IconButton>
            <Dialog open={ open } onClose={ handleClose } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Supprimer un produit</DialogTitle>
                <DialogContent>Êtes-vous sur de vouloir supprimer ce produit ?</DialogContent>
                <DialogActions>
                    <Button onClick={ () => handleClose() } color="primary">
                        Annuler
                    </Button>
                    <Button type="submit" color="primary" onClick={ () => removeItem(props) }>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
