/* eslint-disable jsx-a11y/anchor-is-valid */

import { Avatar, IconButton, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
//import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import MailOutlineIcon from "@material-ui/icons/MailOutline";
import NoteIcon from "@material-ui/icons/Note";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import UpdateIcon from "@material-ui/icons/Update";
import clsx from "clsx";

import parsePhoneNumber from "libphonenumber-js";
import React, { useEffect } from "react";
import FbConfig from "../base";

const useStyles = makeStyles(theme => ({
    nameContainer: {
        display: "flex",
        alignItems: "center"
    },
    marginRight: theme.spacing(2),
    avatar: {
        height: 48,
        width: 48,
        fontSize: "24px",
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },

    infoContainer: {
        display: "table",
        tableLayout: "fixed",
        width: "100%"
    },

    infoUser: {
        paddingLeft: 15
    },

    infoIcon: {
        textAlign: "center",
        width: 60,
        color: "#979799"
    },

    infoSub: {
        display: "table-cell",
        overflow: "hidden",
        padding: 6,
        textOverflow: "ellipsis",
        verticalAlign: "top"
    },

    infoTitle: {
        marginBottom: 10,
        fontSize: 20
    },

    noDeco: {
        textDecoration: "none",
        color: "#2962ff"
    },

    headerContainer: {
        display: "table-cell",
        position: "relative",
        verticalAlign: "middle"
    },

    headerAvatar: {
        width: 85
    },

    headerTools: {
        textAlign: "right",
        width: 150
    }
}));

export default function ClientInfo(props) {
    const user = JSON.parse(localStorage.getItem("token"));
    const [open, setOpen] = React.useState(false);
    const [load, setLoading] = React.useState(false);
    const [clientInfo, setClientInfo] = React.useState(false);

    const idClient = props.id;

    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (idClient && false === load) {
            let url = "customers/" + user.uid + "/" + idClient;
            FbConfig.database()
                .ref(url)
                .on("value", snapshot => {
                    let val = snapshot.val();
                    setClientInfo({ ...val, phone: val?.phone ? parsePhoneNumber(val.phone, "FR") : false });
                });

            if (clientInfo) {
                setLoading(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idClient, load]);

    return (
        <>
            <IconButton aria-label="Voir" onClick={ () => handleClickOpen() }>
                <AccountBoxIcon />
            </IconButton>
            <Dialog open={ open } onClose={ handleClose } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <div className={ clsx(classes.headerContainer, classes.headerAvatar) }>
                        <Avatar className={ classes.avatar }>{ (clientInfo["firstname"] || "").replace(/\s/g).toUpperCase()[0] }</Avatar>
                    </div>
                    <div className={ classes.headerContainer }>
                        <div>{ clientInfo["firstname"] }</div>
                    </div>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div className={ classes.infoTitle }>Coordonnées</div>
                    <div spacing={ 1 }>
                        { clientInfo["firstname"] ? (
                            <div className={ classes.infoContainer }>
                                <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                                    <PersonIcon />
                                </div>
                                <div className={ clsx(classes.infoUser, classes.infoSub) }>{ clientInfo["firstname"] }</div>
                            </div>
                        ) : null }

                        { clientInfo["company"] ? (
                            <div className={ classes.infoContainer }>
                                <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                                    <BusinessIcon />
                                </div>
                                <div className={ clsx(classes.infoUser, classes.infoSub) }>{ clientInfo["company"] }</div>
                            </div>
                        ) : null }

                        { clientInfo["email"] ? (
                            <div className={ classes.infoContainer }>
                                <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                                    <MailOutlineIcon />
                                </div>
                                <div className={ clsx(classes.infoUser, classes.infoSub) }>
                                    <a className={ classes.noDeco } href={ `mailto:${clientInfo["email"]}` }>
                                        { clientInfo["email"] }
                                    </a>
                                </div>
                            </div>
                        ) : null }

                        { clientInfo["phone"] ? (
                            <div className={ classes.infoContainer }>
                                <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                                    <PhoneIcon />
                                </div>
                                <div className={ clsx(classes.infoUser, classes.infoSub) }>
                                    <a className={ classes.noDeco } href={ clientInfo["phone"]?.getURI() }>
                                        { clientInfo["phone"]?.formatInternational() }
                                    </a>
                                </div>
                            </div>
                        ) : null }

                        { clientInfo["userAddress"] ? (
                            <div className={ classes.infoContainer }>
                                <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                                    <RoomIcon />
                                </div>
                                <div className={ clsx(classes.infoUser, classes.infoSub) }>{ clientInfo["userAddress"] }</div>
                            </div>
                        ) : null }

                        { clientInfo["userNote"] ? (
                            <div className={ classes.infoContainer }>
                                <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                                    <NoteIcon />
                                </div>
                                <div className={ clsx(classes.infoUser, classes.infoSub) }>{ clientInfo["userNote"] }</div>
                            </div>
                        ) : null }

                        { clientInfo["createDate"] ? (
                            <div className={ classes.infoContainer }>
                                <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                                    <UpdateIcon />
                                </div>
                                <div className={ clsx(classes.infoUser, classes.infoSub) }>crée le { clientInfo["createDate"] }
                                    { clientInfo["updateDate"] ? " et modifiée le " + clientInfo["updateDate"]  : null }
                                </div>
                            </div>
                        ): null }
                        
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleClose } color="primary">
                        Quitter
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
