import Account from "./Account";
import { Router as AuthRouter } from "./auth";
import BookingAdd from "./booking/BookingAdd";
import ListOrders from "./booking/BookingList";
import Calendar from "./Calendar";
import CatalogList from "./catalog/Catalog";
import ClientList from "./client/ClientList";
import Layout from "./Layout";
import MeetingAdd from "./meeting/MeetingAdd";
import MultiStepForm from "./onboarding/onboarding";
import Report from "./Report";
import UserSettings from "./settings";
import UserSettings1 from "./UserSettings";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

export default function Router() {
    return (
        <Switch>
            <Route path={ ["/login", "/logout"] } component={ AuthRouter } />
            <Route path="/addAccount" component={ Account } />
            <Route path="/">
                <Layout>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/orders" />
                        </Route>
                        <Route path="/home" component={ Report } />
                        <Route path="/new_order" component={ BookingAdd } />
                        <Route path="/edit_order/:orderId" component={ BookingAdd } />
                        <Route path="/orders/:clientId" component={ ListOrders } />
                        <Route path="/orders" component={ ListOrders } />
                        <Route path="/catalog" component={ CatalogList } />
                        <Route path="/customers" component={ ClientList } />
                        <Route path="/settings" component={ UserSettings } />
                        <Route path="/settings1" component={ UserSettings1 } />
                        <Route path="/new_meeting" component={ MeetingAdd } />
                        <Route path="/onboarding" component={ MultiStepForm } />
                        <Route path="/calendar" component={ Calendar } />
                    </Switch>
                </Layout>
            </Route>
        </Switch>
    );
}
