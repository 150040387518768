export function getCurrentDate() {
    let newDate = new Date();
    return newDate;
}

/**
 * sum product price and km price
 */
export function calcPrice(catalogPrice, kmPrice) {
    let result = parseFloat(catalogPrice) + parseFloat(kmPrice);
    return result;
}

/**
 * calc discount amount
 */
export function calcDiscountValue(catalogPrice, kmPrice, discounts, forWhat) {
    if (discounts.length == 0) {
        return 0;
    }
    let totalPrice = calcPrice(catalogPrice, kmPrice);

    for (let i = 0; i < discounts.length; i++) {
        if (discounts[i].percent) {
            totalPrice = totalPrice - totalPrice * (discounts[i].value / 100);
        } else {
            totalPrice = totalPrice - discounts[i].value;
        }
    }
    let result = parseFloat((calcPrice(catalogPrice, kmPrice) - totalPrice).toFixed(2));

    if (forWhat && forWhat == "print") {
        return `${result} € (${((result * 100) / calcPrice(catalogPrice, kmPrice)).toFixed(2)} %)`;
    }

    if (forWhat && forWhat == "percent") {
        return ((result * 100) / calcPrice(catalogPrice, kmPrice)).toFixed(2);
    }

    return result;
}

/**
 * calc total amount
 */
export function calcPriceTotal(catalogPrice, kmPrice, discounts) {
    let totalWithoutDiscount = calcPrice(catalogPrice, kmPrice);
    let discountAmount = calcDiscountValue(catalogPrice, kmPrice, discounts);
    let result = (totalWithoutDiscount - discountAmount).toFixed(2);
    return result;
}

/**
 * calc discount percent
 */
export function calcDiscountPercent(e, values) {
    if (values && values.price) {
        let reCalcPercent = Math.round(((parseInt(e.target.value) * 100) / calcPrice(values.price, values.kmPrice) + Number.EPSILON) * 100) / 100;

        return reCalcPercent;
    }
}

export function calcDiscount(value, values, setFieldValue) {
    let newDiscountValue = calcDiscountValue(values.price, values.kmValue, value);
    values.discountValue = newDiscountValue;
    setFieldValue("discountValue", newDiscountValue);
}
