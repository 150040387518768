import { Typography, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

// eslint-disable-next-line no-unused-vars
import React from "react";

const useStyles = makeStyles(theme => ({
    error: {
        color: theme.palette.error.dark,
        fontWeight: "bold",
        textAlign: "center"
    }
}));

export function FormErrors({ errors }) {
    const classes = useStyles();
    if (!errors.form) {
        return null;
    }

    return <Typography className={ classes.error }>{ errors.form }</Typography>;
}

FormErrors.proTypes = {
    errors: PropTypes.object.isRequired
};
