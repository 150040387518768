import { StyleSheet, Text, View } from "@react-pdf/renderer";

// eslint-disable-next-line no-unused-vars
import React from "react";

const borderColor = "grey";
const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        borderBottomColor: "#D4CDCD",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 24,
        //fontSize: 12,
        fontStyle: "bold"
    },
    description: {
        width: "85%",
        textAlign: "right",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8
    },
    total: {
        width: "15%",
        textAlign: "right",
        paddingRight: 8
    }
});

const InvoiceTableFooter = ({ pricetopay }) => {
    return (
        <View style={ styles.row }>
            <Text style={ styles.description }>TOTAL</Text>
            <Text style={ styles.total }>{ pricetopay }€</Text>
        </View>
    );
};

export default InvoiceTableFooter;
