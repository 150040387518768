/*global next, navigation*/

import ItemForm from "../onboarding/itemForm";

import { makeStyles } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import clsx from "clsx";

// eslint-disable-next-line no-unused-vars
import React from "react";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    form: {
        marginTop: theme.spacing(1),
        width: "100%"
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    },
    card: {
        //fontSize: 30,
        //backgroundColor: "red",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },

    infoContainer: {
        display: "table",
        tableLayout: "fixed",
        width: "100%",
        marginBottom: 30
    },

    infoUser: {
        paddingLeft: 15
    },

    infoIcon: {
        textAlign: "center",
        width: 60,
        color: "#979799"
    },

    infoSub: {
        display: "table-cell",
        overflow: "hidden",
        paddingTop: 20,
        textOverflow: "ellipsis",
        verticalAlign: "middle"
    },

    infoTitle: {
        marginBottom: 10,
        fontSize: 19
    }
}));

const Names = ({ setForm, formData }) => {
    const user = JSON.parse(localStorage.getItem("token"));

    const { name, phone } = formData;
    // eslint-disable-next-line no-unused-vars
    const { next } = navigation;
    const classes = useStyles();

    return (
        <>
            <div className={ classes.infoTitle }>Compte utilisateur</div>
            <div className="form" spacing={ 1 }>
                <div className={ classes.infoContainer }>
                    <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                        <PersonIcon />
                    </div>
                    <ItemForm
                        label="Votre nom (et prénom)"
                        name="name"
                        value={ name }
                        onChange={ setForm }
                        //disabled={true}
                    />
                </div>

                <div className={ classes.infoContainer }>
                    <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                        <MailOutlineIcon />
                    </div>
                    <ItemForm label="Votre email" name="email" value={ user.email } onChange={ setForm } disabled={ true } />
                </div>

                <div className={ classes.infoContainer }>
                    <div className={ clsx(classes.infoIcon, classes.infoSub) }>
                        <PhoneIcon />
                    </div>
                    <ItemForm label="Votre téléphone" name="phone" value={ phone } onChange={ setForm } />
                </div>
            </div>
        </>
    );
};

export default Names;
