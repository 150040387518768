export const palet = [
    "#1da890",
    "#da4d86",
    "#7d76ae",
    "#5c7ee7",
    "#2c6494",
    "#ccb4ec",
    "#ec743c",
    "#c854ac",
    "#ee8666",
    "#f4d4bc",
    "#3cb4c4",
    "#A46790",
    "#C37B5C",
    "#CE78C2",
    "#463A32",
    "#60942F",
    "#F7A2A2",
    "#5E5419",
    "#5582C6",
    "#817393",
    "#6EAAAD",
    "#B4C2C4",
    "#6BF56E",
    "#B90D1E",
    "#D3BCF5",
    "#5B02AE",
    "#42E00E",
    "#F4B4B7",
    "#6565BE",
    "#6190B7",
    "#413C26",
    "#ECC484",
    "#858053",
    "#E0D3AE",
    "#3B9DA2",
    "#246938",
    "#E69097",
    "#62A5B3",
    "#F2F078",
    "#65C6BD",
    "#DBDFCC",
    "#944390",
    "#800F13",
    "#4FB0E4",
    "#CEACE3",
    "#8E9FAB",
    "#14839E",
    "#0101F6",
    "#49D38A",
    "#CA8853",
    "#ECDCB9",
    "#3B2F47",
    "#80BE8B",
    "#E88654",
    "#664577",
    "#B281EF",
    "#B79896",
    "#635405",
    "#DF7097",
    "#8C509A",
    "#475B3F",
    "#73615B",
    "#9E36EE",
    "#75FAE8",
    "#6F9BB3",
    "#4E391A",
    "#7D7423",
    "#BAC0F1",
    "#D9ACF9",
    "#E8D0CB",
    "#B9BB8D",
    "#6E8806",
    "#5187D3",
    "#49F0D1",
    "#6CD7A1",
    "#C7B6C4",
    "#2B0B8A",
    "#527830",
    "#E7CFA6",
    "#C2B38B",
    "#1C31D7",
    "#E07B6E",
    "#B217F4",
    "#1C4B30",
    "#FA61BF",
    "#3F2E56",
    "#DFE7DD",
    "#4EBDDF",
    "#081064",
    "#E6C9C3",
    "#D9D8F1",
    "#580DEE",
    "#943F34",
    "#70C9EF",
    "#6A13D5",
    "#73EEB2",
    "#745D5A",
    "#C9C9E0",
    "#734B2D",
    "#ADE9F6",
    "#B48F72",
    "#B9B3A1",
    "#7E3C83",
    "#82933D",
    "#D4D8AE",
    "#C2B234",
    "#AB19BE",
    "#CC7BB0",
    "#2699AD",
    "#664C20",
    "#110780",
    "#CFC8DD",
    "#6F51A6",
    "#9EE3E9",
    "#AEB9D1",
    "#9667E0",
    "#AF8990",
    "#B46F6A",
    "#505E14",
    "#626A15",
    "#2C75AD",
    "#CD9016",
    "#38F2FC",
    "#5EAD89",
    "#B5C6BC",
    "#81073A",
    "#F0F393",
    "#3ED224",
    "#E94504",
    "#F4C1B9",
    "#63564F",
    "#6EB5D1",
    "#5030A1",
    "#041296",
    "#B7AF9A",
    "#3A591E",
    "#B41F6C",
    "#2A376F",
    "#B1440D",
    "#CACEA5",
    "#7EFD5B",
    "#FCD6B9",
    "#D2D0C5",
    "#B94A3E",
    "#CBD1DD",
    "#EF2042",
    "#7D3133",
    "#9BA079",
    "#9982D9",
    "#D3A8CB",
    "#42C3F5",
    "#4B5C2E",
    "#B38DC7",
    "#A5DFE0",
    "#EF6C69",
    "#8A3D69",
    "#8A9A50",
    "#7CF27E",
    "#1946A0",
    "#8F676B",
    "#B383D8",
    "#48493A",
    "#F1716E",
    "#EC97A9",
    "#92B0ED",
    "#AB4022",
    "#D87E43",
    "#669290",
    "#4FAB04",
    "#88C7E2",
    "#8288C3",
    "#593B2A",
    "#67A732",
    "#CB8906",
    "#9758FA",
    "#85A902",
    "#A1DDEB",
    "#B7ADCF",
    "#DA9E81",
    "#EAE5D5",
    "#B578DE",
    "#376DD2",
    "#E8CD7A",
    "#8BB99A",
    "#E5E2AF",
    "#D5908E",
    "#6545AC",
    "#511074",
    "#40BCC7",
    "#EB0B12",
    "#81CED6",
    "#648DAD",
    "#A74F10",
    "#DBD1CE",
    "#954EAC",
    "#DF85EB",
    "#43442C",
    "#34EFD3",
    "#731149",
    "#85732C",
    "#D4E1D4",
    "#0C1A7F",
    "#DCE1D8",
    "#98B18F",
    "#DFA5BA",
    "#C48E85",
    "#8F8A33",
    "#D8DBD2",
    "#2A6E89",
    "#3B8A19",
    "#12D6D9",
    "#59788B",
    "#CAB9E8",
    "#626F93",
    "#A8DEE9",
    "#FA830D",
    "#CFD67A",
    "#0A04C0",
    "#A79509",
    "#A7589B",
    "#B6A6A4",
    "#552380",
    "#BD6C15",
    "#8B4DB2",
    "#F79AB8",
    "#E2B044",
    "#F5B498",
    "#C15A48",
    "#A6027A",
    "#865B77",
    "#CCB9B7",
    "#4810C0",
    "#B5E3ED",
    "#CBA259",
    "#E1B3FC",
    "#BFDB20",
    "#B85A72",
    "#A3C768",
    "#5AF5E3",
    "#998D76",
    "#DFDFCD",
    "#E4D8BA",
    "#293E68",
    "#4FA5E2",
    "#726E4A",
    "#ABCACC",
    "#2D4F43",
    "#495474",
    "#B8471B",
    "#6238CD",
    "#14AF2B",
    "#E3EF5C",
    "#65405C",
    "#90B9C7",
    "#3F4B7F",
    "#57F0D7",
    "#8E72E2",
    "#90AD1E",
    "#D2776F",
    "#FDDBA9",
    "#D4BEAE",
    "#2B6706",
    "#7DAEFE",
    "#94386C",
    "#93AAE0",
    "#C7BDD0",
    "#11EE48",
    "#876F8B",
    "#7E5EDD",
    "#CEBE84",
    "#61B44F",
    "#F0BBB5",
    "#9D5C5B",
    "#5D8A83",
    "#BD6608",
    "#30BBA8",
    "#44664B",
    "#64F74A",
    "#6D494C",
    "#61463B",
    "#9D6B29",
    "#040471",
    "#C1956E",
    "#E5DB6E",
    "#5FC0A4",
    "#5317BA",
    "#599395",
    "#447AE5",
    "#B9D11A",
    "#6B3A3C",
    "#EAE8D0",
    "#8B7FFE",
    "#A445D4",
    "#E3D5F5",
    "#B22C37",
    "#D8CFD2",
    "#94F2F4",
    "#AB08CF",
    "#D6C8BB",
    "#FA6857",
    "#55229C",
    "#BF585B",
    "#D3E87D",
    "#7B745D",
    "#91FB3A",
    "#27814D",
    "#9F5CEB",
    "#CDE8CE",
    "#69F7F0",
    "#92BCF3",
    "#D3B7DF",
    "#943528",
    "#EBDFBE",
    "#727C40",
    "#B18C06",
    "#8997C7",
    "#65E5F3",
    "#634A62",
    "#31AECD",
    "#7A76E0",
    "#855D6F",
    "#725CAC",
    "#FBD78F",
    "#6C5DF2",
    "#AE4F01",
    "#BACECB",
    "#12DF37",
    "#C8C588",
    "#C13326",
    "#482546",
    "#423756",
    "#D3F1CE",
    "#EC45F8",
    "#088774",
    "#EBD5C0",
    "#565B2B",
    "#7D48D7",
    "#F99E36",
    "#9FBBDF",
    "#B31D3D",
    "#ACAEBD",
    "#9EE8E3",
    "#AE3820",
    "#9B847E",
    "#342545",
    "#D3EE4F",
    "#4CA62C",
    "#57596B",
    "#B7AD69",
    "#D4D0EF",
    "#0BBBBB",
    "#A0BABB",
    "#E2C1AE",
    "#FBD36F",
    "#0DE9BD",
    "#6F290B",
    "#C991CF",
    "#7964A4",
    "#CDDFCF",
    "#B188DD",
    "#EFA892",
    "#AB8688",
    "#72CBBF",
    "#48225E",
    "#C9AA45",
    "#497B57",
    "#CD47E8",
    "#DECF5C",
    "#F0269C",
    "#2C4878",
    "#D2EB51",
    "#DCC0BE",
    "#FCF6BC",
    "#9A8E73",
    "#C1ACB0",
    "#ACA398",
    "#274C61",
    "#B7BE37",
    "#C30E44",
    "#51FFAB",
    "#5D4DD5",
    "#C5B8E4",
    "#9F77BA",
    "#195357",
    "#74FBF2",
    "#64B2CC",
    "#B9E8BF",
    "#51E63B",
    "#820C94",
    "#9F3DF0",
    "#2B3944",
    "#F4EB37",
    "#4C3030",
    "#C74733",
    "#52482B",
    "#D2B0A9",
    "#E77801",
    "#E3DF5F",
    "#B5AB20",
    "#0293D1",
    "#B0AFCB",
    "#E4E2DC",
    "#975EEE",
    "#1E6C16",
    "#80842E",
    "#B0DCB6",
    "#2C0C5C",
    "#0C8787",
    "#3C6CDC",
    "#DAD3CA",
    "#F1D7D4",
    "#67DF17",
    "#BE6BDA",
    "#6E4A38",
    "#FF5BFF",
    "#526C6E",
    "#5670D6",
    "#DA61E0",
    "#9E6EB6",
    "#C59793",
    "#65522F",
    "#A3A1E0",
    "#AA97A8",
    "#C9C4EC",
    "#BE5A92",
    "#6F522E",
    "#A5AC85",
    "#3196C5",
    "#AD9C4D",
    "#AB3450",
    "#E0B3AC",
    "#C9A0F8",
    "#48716A",
    "#F4F43A",
    "#A01419",
    "#48F3D1",
    "#554016",
    "#AA6BED",
    "#B86FF4",
    "#7F8E70",
    "#7B88FC",
    "#36595E",
    "#94F0CD",
    "#87D152",
    "#782F9D",
    "#E3AFD1",
    "#F0CF17",
    "#EEEBA0",
    "#A785F2",
    "#4C2A2C",
    "#3E7792",
    "#65469B",
    "#F48F7D",
    "#B4B6E1",
    "#524247",
    "#21A9CB",
    "#51D253",
    "#E0B805",
    "#6F4B11",
    "#8492BD",
    "#7E4745",
    "#745F78",
    "#6E6348",
    "#B4C7FB",
    "#2E4241",
    "#51F945",
    "#D67C44",
    "#CEC6BA",
    "#09257C",
    "#A7DDEF",
    "#ED9965",
    "#E496E6",
    "#668871",
    "#B49030",
    "#601F07",
    "#9D1CF9",
    "#A792C0",
    "#885756",
    "#729971",
    "#643D7E",
    "#CEC707",
    "#B6DDE1",
    "#1C1C4E",
    "#50CF47",
    "#E1E4D5",
    "#FCCAC2",
    "#289DB2",
    "#D4B330",
    "#640C42",
    "#A85D84",
    "#063F75",
    "#629BF0",
    "#BB600B",
    "#488FC6",
    "#C39114",
    "#ECC6DB",
    "#DBCAB2",
    "#8ECCD1",
    "#D6E9E9",
    "#B03232",
    "#C8C1D5",
    "#F3F775",
    "#0DD69A",
    "#90EA5B",
    "#8675A8",
    "#547E77",
    "#551C1C",
    "#C83D7E",
    "#492E2C",
    "#3B2E57",
    "#6A6A01",
    "#5EB5E5",
    "#8F6F94",
    "#BDE19E",
    "#6D6F21",
    "#B1B139",
    "#C8BAAB",
    "#EF2715",
    "#E4BA9D",
    "#39610A",
    "#857697",
    "#91338C",
    "#FD7E7E",
    "#D3E0BE",
    "#7D7FDA",
    "#53562B",
    "#B1AE8E",
    "#7F6AA8",
    "#12A4C9",
    "#6FE8F3",
    "#FCC1CF",
    "#E6958D",
    "#5E43B7",
    "#27225B",
    "#E663C8",
    "#9191C0",
    "#296C10",
    "#344949",
    "#D79F63",
    "#1D5A31",
    "#303440",
    "#DEDDCD",
    "#66171F",
    "#B13914",
    "#9265BD",
    "#53AED4",
    "#A8A78E",
    "#AD7B7E",
    "#7ECD7C",
    "#3EF74A",
    "#E7D5B1",
    "#8579CF",
    "#453026",
    "#F4B4CE",
    "#C47460",
    "#F9D3B8",
    "#0B5278",
    "#F2FEC9",
    "#A08AC6",
    "#8AA3AF",
    "#EACAE6",
    "#5AFFEE",
    "#B545F6"
];
