import Bank from "../onboarding/Bank";
import Company from "../onboarding/Company";
import Invoice from "../onboarding/Invoice";
import Names from "../onboarding/Names";
import Submit from "../onboarding/Submit";
import Welcome from "../onboarding/Welcome";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import { useHistory } from "react-router-dom";

const steps = [{ id: "welcome" }, { id: "names" }, { id: "company" }, { id: "bank" }, { id: "invoice" }, { id: "submit" }];

const defaultData = {
    /* info perso */
    name: JSON.parse(localStorage.getItem("token"))?.name || "",
    //email: "email@domain.com",
    phone: "+33XX",
    /* info entreprise */
    company: "",
    logo:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAAEsCAMAAABOo35HAAAAS1BMVEXFxcXT09PMzMz29vb9/f3Pz8/Hx8f////" +
        "ExMTCwsLc3Nzo6Ojf39/6+vrt7e3V1dXJycn4+Pjx8fHk5OTh4eHZ2dnv7+/z8/Pq6uo9UIqsAAAGZElEQVR42u2dWc/0JgxGs5AYsu8z//+X" +
        "dtRF6tdWr2Y6gJc85zKXR2CMAacoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAoffsF7KPlPTb5dqmbYr2ukP5iuq" +
        "xuaqvVQ9g9TTe9e0L95fb126PqT4Ku9Xuln1utswt2N+aWZanqPbWiKO/sqO/qIx3zbha+s6WPGfgn3i1RV/z9U/R7xX8PrXrOx3ekL1jvNxl" +
        "Cu9B17e5dgVT7oa8a+ukWyMFAcGvuyZopGbXxdDJeLJ4vGxvCy6Kua4jLYDfQlRac2aqs9KAFuthi5vstDfwhcs8HA9aBUnOZcrZSO09bYqhy" +
        "l5GkqbqV1RdQbGls1peZEvPqAzsZMTJUzmKxxDZSF0UIu31AmRgOyKBu99rDl63yyqNOdQPgn5UR38fR0WWXVqmWNlJdB8UTsKDd6z3ya7K5o" +
        "07oihim/LJoxsD6I8Tqjll85ZNGu0ZY/iIdFY7GByRXtAQPLcvoQLjZZ+hbEhs0VPbTNQ883sPQNLcaBRbRiV/jB/QdleYPjlEWHh6y3mTSF+" +
        "NATL6pKpjWzLE3V+IXZlaq7Dx23LNITtMLFLkvPRZGwsss6kL5bPBQb+GXpufiw8csiNcthLUCWmgvfowBZas4tnABZDyWyWiIErXeLpLMIWT" +
        "puTfpdhCwdZzy+FyGrgSxrssIoQlapQxZBFmRBFmRBFmRBFmRpS0qx3bG33XlC1vugRPMBJWR9gAhZWg7wJZQdnloOLHAUpuyQVcsZa3Hwu9J" +
        "zB/fkl6XndreA3eGkRxZ/hB8KBC178V3AbbZL0UV43Fb+JGhNzLJKRdOwKHlvHa2qemkxv91R1l2StVrqFlWueNdDZe8NefPSQZkszv3hqq+x" +
        "Q42B9f48fCLJep+KK3u4NLbQ4sriVXZJrHhcKW2SeGIpFJ5rDUWBofUmehsst/mHluaOwbnTB9U/s8hdfFgUuypC3sNp5X+yyPr2UH1D+CZf2" +
        "NoK9eQLWwZ+pZktbHUWflTU5LF1mPipk89Sj+8LG/gMQX4rzJC8WlPbcVX4xFvq1dSfWUNSW9dS2KLCHJSQQez2XL3WxAdcfRC4Epz3uC7YlF" +
        "X46C05t6YwS+z0dC9Ms0QsQjiL/yb/NXDNsd6M9UtxA6IMrvEsbkGo+m9DV322vrgLXzZvG28jyjfX12uiu5objKzQHmOU9MGNRxtsuyq3iO+" +
        "nx620rCr67vBhVFeYk3SJX2d7kzGUyc54tjJYi1VJTysMTUZfJv9Nw1rayCR8m+UR8GEhpfdHppshTv+RdJXxNvxUqVYVMv+tSHGN2VfZL+DW" +
        "ldK52LI0HSt1Hk4z9dBXWJcPfO/vnbaM3neM3dlGXXcAuZtgaPrN6MLdMISmRcsUXAT8pMgtOqaigB/2abnoHToSgoJ0vnNSZLlOuquBBCH7V" +
        "avvnSRZTnLPMSHNzf+G4KbBO4lD6k7RC3Ql9Q+aoXMSZcm8dCrTlcgMwjckFnEXbkoSjLAz2IVEI6oGsTjZsiS1l2RveKuoGhieJB4xfVcGUo" +
        "CQTXU7apA1yjhPdKQCh4ClK2wNpAb2sFWSIrgz+VqTLN6X5+EgVRycYWsmZcyMA+uhTdaDb2hNpA6232JVTp8sx3RJt1Xo6mWLZdvjO1IJy02" +
        "3ttYpq2YYWn4jpWz5h1ZDasl/c2vTKyt7f8DG6ZXlMg+thVST9bDHH7pl5X1tt+qWlfXHycoH1mto4YxC4umFH0g9g8dSKG5B9IcFWZkWxNZZ" +
        "kJWpVDOQCfKcIj5syHpgVyhsh3iREa4MpxRkhuRnF1or7zzV+NWOrOTb6ZMMkbiJrp8syZrSzsPKWZKV+Hh6JlOkvVQz2pKVtEdz62zJSrmbl" +
        "vlW9RsSvnMNkzVZCd/0lGSOElsdAVueUNuTVaeahw0ZJFVV67QoK9X+sLYoq0b6zp7El2SSJMmDH2zKSnKQ7yebspIUtVoySorNdGVVVoIKoL" +
        "2KQ8LKQ9isytri73gCmSUgZHEGrc6urPit23q7svroi+FmV1b0Z2J+tStr9VgM+ZbD0rKsyIUH31uWFblfNWTd6rXOT0R+ydPalhW3SlPZlhV" +
        "3w7PYlrVgG820lbZ6WPEXUQ8tIOuTzc5lW9YVc8MTJtuyot5pC2QcyGKSVVmXVUEWj6zZuqwZsmLL+g1jStd0mHlJUAAAAABJRU5ErkJggg==",
    siret: "",
    address: "",
    tva: false,
    url: "",
    /* coordonnées bancaires */
    bic: "",
    iban: "",
    /* facture & devis */
    invoice: "100",
    quote: "100",
    /* paramètres */
    currencies: "EUR",
    language: "FR",
    kmPrice: "0.4"
};

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={ 1 }>
                <LinearProgress variant="determinate" { ...props } />
            </Box>
            <Box minWidth={ 35 }>
                <Typography variant="body2" color="textSecondary">{ `${Math.round(props.value)}%` }</Typography>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles({
    root: {
        width: "100%"
    }
});

const MultiStepForm2 = ({ step, navigation }) => {
    const classes = useStyles();

    const [formData, setForm] = useForm(defaultData);

    const { id } = step;

    const props = { formData, setForm, navigation };

    switch (id) {
    case "welcome":
        return (
            <>
                <Welcome { ...props } />{ " " }
                <div className={ classes.root }>
                    <LinearProgressWithLabel value={ 15 } />
                </div>
            </>
        );
    case "names":
        return (
            <>
                <Names { ...props } />
                <div className={ classes.root }>
                    <LinearProgressWithLabel value={ 30 } />
                </div>
            </>
        );
    case "company":
        return (
            <>
                <Company { ...props } />{ " " }
                <div className={ classes.root }>
                    <LinearProgressWithLabel value={ 45 } />
                </div>
            </>
        );
    case "bank":
        return (
            <>
                <Bank { ...props } />{ " " }
                <div className={ classes.root }>
                    <LinearProgressWithLabel value={ 60 } />
                </div>
            </>
        );
    case "invoice":
        return (
            <>
                <Invoice { ...props } />{ " " }
                <div className={ classes.root }>
                    <LinearProgressWithLabel value={ 75 } />
                </div>
            </>
        );
    case "submit":
        return (
            <>
                <Submit { ...props } />{ " " }
                <div className={ classes.root }>
                    <LinearProgressWithLabel value={ 100 } />
                </div>
            </>
        );
    default:
        return null;
    }
};

export default function MultiStepForm() {
    const history = useHistory();

    const endRedirect = () => {
        history.push("/settings");
    };

    const { step, navigation } = useStep({ initialStep: 0, steps });
    const { previous, next } = navigation;
    return (
        <>
            <Dialog open={ true }>
                <DialogTitle id="form-dialog-title">Bienvenue dans EzPresta</DialogTitle>
                <Divider />
                <DialogContent style={ { minWidth: "320px" } }>
                    <MultiStepForm2 step={ step } navigation={ navigation } />
                </DialogContent>
                <br />
                <Divider />
                <DialogActions>
                    { step.id !== "submit" && step.id !== "welcome" ? (
                        <Button color="primary" onClick={ previous }>
                            Précédent
                        </Button>
                    ) : null }
                    { step.id !== "submit" && (
                        <Button variant="contained" color="primary" onClick={ next }>
                            Suivant
                        </Button>
                    ) }
                    { step.id === "submit" && (
                        <Button variant="contained" color="primary" onClick={ endRedirect }>
                            Terminé
                        </Button>
                    ) }
                </DialogActions>
            </Dialog>
        </>
    );
}
