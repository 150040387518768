import FbConfig from "../base";
import { FormikSelect, FormikTextField } from "@dccs/react-formik-mui";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddIcon from "@material-ui/icons/Add";
import CallIcon from "@material-ui/icons/Call";

import CommentIcon from "@material-ui/icons/Comment";
import EditIcon from "@material-ui/icons/Edit";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React from "react";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
    nameContainer: {
        display: "flex",
        alignItems: "center"
    },
    marginRight: theme.spacing(2),
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        height: 58,
        width: 58,
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontSize: 0,
        transition: "width 0.5s, border-radius 0.5s, background-color 0.5s",
        paddingLeft: 25,
        "&:hover": {
            fontSize: 20,
            width: 320,
            borderRadius: 15
        }
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
        fontSize: 32
    }
}));

const SignupSchema = Yup.object().shape({
    firstname: Yup.string().ensure().required("Le prénom est obligatoire"),
    email: Yup.string().email("L'email n'est pas valide")
});

export function getClientSource() {
    return [
        { id: 1, name: "Instagram" },
        { id: 2, name: "Facebook" },
        { id: 3, name: "Bouche à oreille" },
        { id: 4, name: "Moteur de recherche" },
        { id: 5, name: "Réseaux sociaux" },
        { id: 6, name: "Autre" }
    ];
}

export default function Client(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const fromForm = props.fromForm;
    const item = props.customer;

    const buttonAdd = () => {
        return (
            <>
                <Fab variant="extended" color="primary" aria-label="add" className={ classes.fab } size="large" onClick={ () => handleClickOpen() }>
                    <AddIcon className={ classes.extendedIcon } />
                    Ajouter un contact
                </Fab>
            </>
        );
    };

    const buttonEdit = () => {
        return (
            <>
                <IconButton aria-label="Modifier" onClick={ () => handleClickOpen() }>
                    <EditIcon />
                </IconButton>
            </>
        );
    };

    const buttonFromOrder = () => {
        return (
            <Button variant="outlined" color="primary" className={ classes.button } startIcon={ <PersonAddIcon /> } onClick={ handleClickOpen } size="small">
                Nouveau client
            </Button>
        );
    };

    const getTitlePage = () => {
        switch (fromForm) {
        case "edit":
            return "Modifier la fiche de " + item.fullname;
        case "orders":
        default:
            return "Créer un nouveau contact";
        }
    };

    const getUserFormDetails = () => {
        let msg = "";
        if ( "edit" === fromForm) {
            if ( typeof item.createDate !== "undefined" && "" !== item.createDate) {
                msg += "Fiche crée le " + item.createDate; 

                if ( typeof item.updateDate !== "undefined" && "" !== item.updateDate) {
                    msg += " et modifiée le " + item.updateDate;
                }
            }
        }
        return msg;
    };

    return (
        <>
            { "orders" === fromForm ? buttonFromOrder() : "edit" === fromForm ? buttonEdit() : buttonAdd() }
            <Dialog open={ open } onClose={ handleClose } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{ getTitlePage() }</DialogTitle>
                <Formik
                    validateOnBlur={ false }
                    validateOnChange={ false }
                    initialValues={ {
                        firstname: "edit" === fromForm ? item.firstname : "",
                        company: "edit" === fromForm ? item.company : "",
                        email: "edit" === fromForm ? item.email : "",
                        phone: "edit" === fromForm && item.phone ? item.phone?.formatInternational() : "",
                        userNote: "edit" === fromForm ? item.note : "",
                        userAddress: "edit" === fromForm ? item.address : "",
                        source: "edit" === fromForm ? item.source : "",
                        sourceother: "edit" === fromForm ? item.sourceOther : "",
                        createDate: "edit" === fromForm ? item.createDate : "",
                        updateDate: "edit" === fromForm ? item.updateDate : ""
                    } }
                    validationSchema={ SignupSchema }
                    onSubmit={ (values, { setSubmitting }) => {
                        setTimeout(() => {
                            const user = JSON.parse(localStorage.getItem("token"));

                            var saveClient = FbConfig.functions().httpsCallable("saveClient");
                            saveClient({
                                uid: user.uid,
                                userId: "edit" === fromForm ? item.userId : null,
                                fromForm: fromForm,
                                firstname: values["firstname"],
                                company: values["company"],
                                email: values["email"],
                                phone: values["phone"],
                                userNote: values["userNote"],
                                userAddress: values["userAddress"],
                                createDate: "edit" === fromForm ? item.createDate : "",
                                source: values["source"],
                                sourceOther: 6 === values["source"] ? values["sourceother"] : null
                            })
                                .then(() => {
                                    let msg = "edit" === fromForm ? "La fiche client a bien été modifiée" : "La fiche client a bien été créée";
                                    enqueueSnackbar(msg, { variant: "success" });
                                    handleClose();
                                })
                                .catch(() => enqueueSnackbar("Un problème est survenu à la sauvegarde de la fiche client", { variant: "error" }));

                            setSubmitting(false);
                        }, 400);
                    } }>
                    { ({ isSubmitting, values, handleChange, setFieldValue }) => (
                        <Form className={ classes.form } noValidate>
                            <DialogContent>
                                { getUserFormDetails() }
                                <Grid container spacing={ 1 }>
                                    <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                                        <FormikTextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="firstname"
                                            name="firstname"
                                            label="Nom Prénom"
                                            type="text"
                                            InputProps={ {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountCircleIcon />
                                                    </InputAdornment>
                                                )
                                            } }
                                        />
                                        <FormikTextField
                                            margin="normal"
                                            fullWidth
                                            id="company"
                                            name="company"
                                            label="Entreprise"
                                            type="text"
                                            InputProps={ {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountCircleIcon />
                                                    </InputAdornment>
                                                )
                                            } }
                                        />
                                        <FormikTextField
                                            label="Adresse"
                                            name="userAddress"
                                            id="userAddress"
                                            multiline
                                            fullWidth
                                            margin="normal"
                                            InputProps={ {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LocationOnIcon />
                                                    </InputAdornment>
                                                )
                                            } }
                                        />
                                    </Grid>
                                    <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
                                        <FormikTextField
                                            margin="normal"
                                            fullWidth
                                            id="email"
                                            name="email"
                                            label="Email"
                                            type="text"
                                            InputProps={ {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MailOutlineIcon />
                                                    </InputAdornment>
                                                )
                                            } }
                                        />

                                        <FormikTextField
                                            margin="normal"
                                            fullWidth
                                            id="phone"
                                            name="phone"
                                            label="Numéro de téléphone"
                                            type="text"
                                            InputProps={ {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <CallIcon />
                                                    </InputAdornment>
                                                )
                                            } }
                                        />
                                        <FormikSelect
                                            id="source"
                                            label="Source"
                                            name="source"
                                            fullWidth
                                            onChange={ event => {
                                                handleChange(event);
                                                setFieldValue(event.target.value);
                                            } }
                                            options={ getClientSource().map(item => ({
                                                key: item.id,
                                                value: item.name
                                            })) }
                                        />
                                    </Grid>
                                    { 6 === values.source && (
                                        <Grid item xs={ 12 } sm={ 6 } md={ 12 }>
                                            <FormikTextField fullWidth id="sourceother" name="sourceother" label="Autre" type="text" />
                                        </Grid>
                                    ) }
                                    <Grid item xs={ 12 } sm={ 6 } md={ 12 }>
                                        <FormikTextField
                                            label="Note"
                                            name="userNote"
                                            id="userNote"
                                            multiline
                                            fullWidth
                                            margin="normal"
                                            InputProps={ {
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <CommentIcon />
                                                    </InputAdornment>
                                                )
                                            } }
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={ handleClose } color="primary">
                                    Annuler
                                </Button>
                                <Button type="submit" disabled={ isSubmitting } color="primary">
                                    Valider
                                </Button>
                            </DialogActions>
                        </Form>
                    ) }
                </Formik>
            </Dialog>
        </>
    );
}
