import { fullyPaid, getButtonColor } from "./BookingTools";
import FbConfig from "../base";
import { FormikSelect, FormikTextField } from "@dccs/react-formik-mui";
import { Avatar, Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import EuroIcon from "@material-ui/icons/Euro";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React from "react";

import * as Yup from "yup";

/**
 * Liste des mode de paiements
 */
export function PaymentMethod() {
    return [
        { id: "SELECT", name: "Mode de paiement" },
        { id: "CB", name: "Carte bancaire" },
        { id: "CHK", name: "Chèque" },
        { id: "PP", name: "PayPal" },
        { id: "SEPA", name: "Virement" },
        { id: "ESP", name: "Espèce" }
    ];
}

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    green: {
        color: "#fff",
        backgroundColor: green[500]
    }
});

const StyledBadge = withStyles(theme => ({
    badge: {
        right: 0,
        top: 3,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: "0 4px"
    }
}))(Badge);

const SignupSchema = Yup.object().shape({
    paymentMethod: Yup.string().ensure().required("Vous devez sélectionner une prestation").notOneOf(["SELECT"]),
    paymentDate: Yup.date().required("Vous devez choisir une date pour le paiement")
});

export function BookingValidatePayment(props) {
    const values = props.order;
    const orderId = props.id;
    const index = props.index;

    const price = values.paymentInstalment[index].price;

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = React.useState(false);

    const user = JSON.parse(localStorage.getItem("token"));
    const url = "orders/" + user.uid + "/" + orderId;

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button size="small" variant="contained" color="primary" onClick={ handleOpen }>
                À valider !
            </Button>

            <Dialog open={ open } onClose={ handleClose }>
                <DialogTitle id="form-dialog-title">
                    Valider le paiement n°{ index } de { price } €
                </DialogTitle>
                <Formik
                    validateOnBlur={ false }
                    validateOnChange={ false }
                    enableReinitialize={ true }
                    initialValues={ {
                        paymentDate: "",
                        paymentMethod: "SELECT",
                        paymentNote: null
                    } }
                    validationSchema={ SignupSchema }
                    onSubmit={ (values, { setSubmitting }) => {
                        setTimeout(() => {
                            let newDate = new Date(values.paymentDate);
                            let month = newDate.getMonth() + 1;

                            if (month < 10) {
                                month = "0" + month.toString();
                            } else {
                                month = month.toString();
                            }

                            FbConfig.database()
                                .ref(url + "/paymentInstalment/" + index)
                                .update({
                                    isPaid: true,
                                    datePaid: values.paymentDate,
                                    paymentMode: values.paymentMethod,
                                    paymentInfo: !values.paymentNote ? null : values.paymentNote
                                })
                                .then(() => {
                                    enqueueSnackbar("Enregistrement du règlement", { variant: "success" });
                                    handleClose();
                                })
                                .catch(() => enqueueSnackbar("Un problème est survenu à l'enregistrement du règlement", { variant: "error" }));

                            if (3 === index) {
                                FbConfig.database()
                                    .ref(url)
                                    .update({
                                        fullyPaid: true,
                                        fullyPaidDate: new Date()
                                    })
                                    .then(() => {
                                        enqueueSnackbar("La prestation a été totalement payée", {
                                            variant: "success"
                                        });
                                    })
                                    .catch(() => enqueueSnackbar("Un problème est survenu à l'enregistrement du règlement", { variant: "error" }));
                            }
                            setSubmitting(false);
                        }, 400);
                    } }>
                    { ({ isSubmitting, setFieldValue }) => (
                        <Form className={ classes.form } noValidate>
                            <DialogContent>
                                <Grid container spacing={ 2 } className={ classes.grid }>
                                    <Grid item xs={ 6 } md={ 2 }>
                                        <FormikTextField
                                            margin="normal"
                                            fullWidth
                                            required
                                            id="paymentDate"
                                            name="paymentDate"
                                            label="Date du paiement"
                                            type="date"
                                            className={ classes.textField }
                                            InputLabelProps={ {
                                                shrink: true
                                            } }
                                        />
                                        <FormikSelect
                                            label="Mode de paiement"
                                            id="paymentMethod"
                                            name="paymentMethod"
                                            required
                                            options={ PaymentMethod().map(item => ({
                                                key: item.id,
                                                value: item.name
                                            })) }
                                            onChange={ (event, value) => {
                                                setFieldValue(event.target.name, value.props.value);
                                            } }
                                        />
                                        <FormikTextField
                                            margin="normal"
                                            fullWidth
                                            id="paymentNote"
                                            name="paymentNote"
                                            label="Commentaire / Numéro de chèque"
                                            type="text"
                                            className={ classes.textField }
                                            InputLabelProps={ {
                                                shrink: true
                                            } }
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={ handleClose } color="primary">
                                    Annuler
                                </Button>
                                <Button type="submit" color="primary" disabled={ isSubmitting }>
                                    Valider
                                </Button>
                            </DialogActions>
                        </Form>
                    ) }
                </Formik>
            </Dialog>
        </>
    );
}

export default function BookingEditValidatePayment(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = React.useState(false);

    const values = props.order;
    const orderId = props.id;

    const handleClickOpen = () => {
        if (!values.orderIsConfirmed) {
            enqueueSnackbar("Vous devez d'abord confirmer la prestation avant de valider le(s) paiement(s)", { variant: "warning" });
            handleClose();
        } else {
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getColor = () => {
        return getButtonColor(values);
    };

    const getMsgTooltip = () => {
        return true === fullyPaid(values) ? "Prestation payée en intégralité" : "Prestation non réglée";
    };

    const countPayment = () => {
        let counter = 0;
        if (values.fullyPaid) {
            return 0;
        } else if (false === values.hasPaymentInstalment) {
            if (!values.paymentInstalment[1].isPaid || false === values.paymentInstalment[1].isPaid) {
                counter += 1;
            }
        } else if (true === values.hasPaymentInstalment) {
            if (!values.paymentInstalment[1].isPaid || false === values.paymentInstalment[1].isPaid) {
                counter += 1;
            }
            if (!values.paymentInstalment[2].isPaid || false === values.paymentInstalment[2].isPaid) {
                counter += 1;
            }
            if (!values.paymentInstalment[3].isPaid || false === values.paymentInstalment[3].isPaid) {
                counter += 1;
            }
        }
        return counter;
    };

    return (
        <>
            <Tooltip title={ getMsgTooltip() } touch="true">
                <IconButton aria-label="valider">
                    <StyledBadge badgeContent={ countPayment() } aria-label="Valider un paiement" color={ getColor() } onClick={ () => handleClickOpen(props) }>
                        <AttachMoneyIcon color={ getColor() } />
                    </StyledBadge>
                </IconButton>
            </Tooltip>

            <Dialog open={ open } onClose={ handleClose }>
                <DialogTitle id="form-dialog-title">Résumé des paiements</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table className={ classes.table } aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell align="center">
                                        <strong>Montant</strong>
                                    </TableCell>
                                    <TableCell align="center">
                                        <strong>Date</strong>
                                    </TableCell>
                                    <TableCell align="center">
                                        <strong>Mode de paiement</strong>
                                    </TableCell>
                                    <TableCell align="center">
                                        <strong>Commentaire</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { values.paymentInstalment.map(
                                    (row, index) =>
                                        row.price && (
                                            <TableRow key={ index }>
                                                <TableCell component="th" scope="row">
                                                    { row.isPaid && (
                                                        <Avatar className={ classes.green }>
                                                            <EuroIcon />
                                                        </Avatar>
                                                    ) }
                                                    { false === row.isPaid && <BookingValidatePayment id={ orderId } order={ values } index={ index } /> }
                                                </TableCell>
                                                <TableCell align="center">{ row.price } €</TableCell>
                                                <TableCell align="center">
                                                    <div className={ classes.subContainerTitle }>{ row.isPaid ? new Date(row.datePaid).toLocaleDateString("fr-FR") : null }</div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div className={ classes.subContainerTitle }>{ row.isPaid ? row.paymentMode : null }</div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div className={ classes.subContainerTitle }>{ row.isPaid ? row.paymentInfo : null }</div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                ) }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleClose } color="primary">
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
