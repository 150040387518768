import { Login, Logout } from "./containers";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { Route, Switch } from "react-router-dom";

export const Router = () => (
    <Switch>
        <Route exact path="/login" component={ Login } />
        <Route exact path="/logout" component={ Logout } />
    </Switch>
);
