import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

// eslint-disable-next-line no-unused-vars
import React from "react";

const circularStyle = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down("960")]: {
            left: "50%"
        },
        "& > * + *": {
            marginLeft: theme.spacing(2)
        },
        display: "flex",
        left: "calc(50% + 124px)",
        position: "fixed",
        top: "50%",
        transform: "translate(-50%, -50%)"
    }
}));

export function CircularIndeterminate() {
    const classes = circularStyle();

    return (
        <div className={ classes.root }>
            <CircularProgress />
        </div>
    );
}

export function phone(number) {
    var nonInt = /\D/g;
    var allNumbers = /.*(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/;
    var formatStyle = "+33 $1 $2 $3 $4 $5";
    return number.replace(nonInt, "").replace(allNumbers, formatStyle);
}

export function price(number) {
    return new Intl.NumberFormat("fr-FR", { currency: "EUR", style: "currency" }).format(number);
}

export function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
}

export function dynamicSortMultiple() {
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0,
            result = 0,
            numberOfProperties = props.length;
        while (result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
        }
        return result;
    };
}
