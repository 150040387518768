import { StyleSheet, View } from "@react-pdf/renderer";
import React from "react";
import InvoiceTableBlankSpace from "./InvoiceTableBlankSpace";
import InvoiceTableFooter from "./InvoiceTableFooter";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";

// eslint-disable-next-line no-unused-vars
const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 24,
        borderWidth: 1,
        borderColor: "#bff0fd"
    }
});

const InvoiceItemsTable = ({ invoice }) => (
    <View style={ styles.tableContainer }>
        <InvoiceTableHeader />
        <InvoiceTableRow items={ invoice.items } />
        <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length } />
        <InvoiceTableFooter pricetopay={ invoice.priceToPay } />
    </View>
);

export default InvoiceItemsTable;
