import FbConfig from "../base";

import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TableContainer from "@material-ui/core/TableContainer";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { withStyles } from "@material-ui/styles";
import { withSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React from "react";

const useStyles = () => ({
    nameContainer: {
        display: "flex",
        alignItems: "center"
    },
    categorysaveicon: {
        cursor: "pointer"
    },
    dotColor: {
        height: "15px",
        width: "20px",
        borderRadius: "3px",
        margin: "5px",
        cursor: "pointer"
    },
    parentMenu: {
        display: "grid",
        gridTemplateColumns: " repeat(2, 1fr)",
        gridTemplateRows: "repeat(6, 1fr)",
        gridColumnGap: 0,
        gridRowGap: 0
    },
    dotMain: {
        height: "15px",
        width: "20px",
        borderRadius: "3px",
        margin: "5px auto",
        cursor: "pointer",
        border: "1px solid lightgray"
    },
    dotColorTrans: {
        border: "1px solid gray",
        borderRadius: "3px",
        margin: "5px",
        cursor: "pointer",
        height: "13px",
        width: "18px"
    }
});

export function loadCategory(uid) {
    let firebaseCategoryURL = "category/" + uid + "/";
    let item = FbConfig.database().ref(firebaseCategoryURL);

    let category = [];

    item.on("value", snapshot => {
        let result = snapshot.val();
        for (let item in result) {
            category.push({
                id: item,
                name: result[item].name
            });
        }
        localStorage.setItem("category", JSON.stringify(category));
    });

    return category;
}
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: [],
            open: false,
            name: "",
            nameEdit: "",
            id: -1,
            user: null
        };
    }

    handleClose() {
        this.setState({ open: false });
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem("token"));
        let ref = FbConfig.database().ref("category/" + user.uid);
        ref.on("value", snapshot => {
            let result = snapshot.val();
            let category = [];
            for (let item in result) {
                category.push({
                    id: item,
                    name: result[item].name
                });
            }
            localStorage.setItem("category", JSON.stringify(category));
            this.setState({ category });
        });

        this.setState({ user });
    }

    editCategory(id, value) {
        if (id) {
            this.setState({ id: id, nameEdit: value });
        }
    }

    closeEdit() {
        this.setState({ id: -1, nameEdit: "" });
    }

    deleteCategory(itemId) {
        const { enqueueSnackbar } = this.props;
        const { user } = this.state;

        const fbUrl = "category/" + user.uid + "/" + itemId + "/";
        FbConfig.database()
            .ref(fbUrl)
            .remove()
            .then(function () {
                enqueueSnackbar("La catégorie a été effacée", { variant: "success" });
            })
            .catch(() => {
                enqueueSnackbar("Erreur lors de la suppression", { variant: "error" });
            });
    }

    updateCategory(name, id) {
        const { enqueueSnackbar } = this.props;
        const { user, category } = this.state;
        let firebaseCategoryURL = "category/" + user.uid + "/";

        if (name === "") {
            return enqueueSnackbar("Le nom de la catégorie ne peut pas être vide", { variant: "error" });
        }

        if (category.find(item => item.name === name) && category.find(item => item.name === name).id !== id) {
            return enqueueSnackbar("Cette catégorie existe déjà", { variant: "error" });
        }

        if (id) {
            firebaseCategoryURL += id;
            FbConfig.database()
                .ref(firebaseCategoryURL)
                .set({ name })
                .then(() => {
                    enqueueSnackbar("La catégorie a bien été modifiée", { variant: "success" });
                })
                .catch(() => {
                    enqueueSnackbar("Problème à l'enregistrement de la catégorie", { variant: "error" });
                });
        } else {
            FbConfig.database()
                .ref(firebaseCategoryURL)
                .push({ name })
                .then(() => {
                    enqueueSnackbar("La catégorie a bien été enregistrée", { variant: "success" });
                })
                .catch(() => {
                    enqueueSnackbar("Problème à l'enregistrement de la catégorie", { variant: "error" });
                });
        }
        this.setState({ id: -1, name: "" });
    }

    displayDeleteIcon(id) {
        return (
            <IconButton aria-label="Supprimer une catégorie" color="primary" onClick={ () => this.deleteCategory(id) }>
                <DeleteIcon />
            </IconButton>
        );
    }

    displayAddButton() {
        const { classes } = this.props;
        return (
            <Button variant="contained" color="primary" className={ classes.button } startIcon={ <AddIcon /> } onClick={ () => this.setState({ open: true }) }>
                Gérer les catégories
            </Button>
        );
    }

    addCategory() {
        const { classes } = this.props;
        const { name } = this.state;
        return (
            <>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="categoryName"
                    name="categoryName"
                    label="Ajouter une catégorie"
                    type="text"
                    value={ name }
                    onChange={ e => this.setState({ name: e.target.value }) }
                    InputProps={ {
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton aria-label="Enregistrer" onClick={ () => this.updateCategory(name) }>
                                    <SaveIcon color="secondary" className={ classes.categorysaveicon } />
                                </IconButton>
                            </InputAdornment>
                        )
                    } }
                />
                <Divider />
            </>
        );
    }

    displayEditIcon = (id, value) => {
        return (
            <IconButton aria-label="Éditer cette catégorie" color="primary" onClick={ () => this.editCategory(id, value) }>
                <EditIcon />
            </IconButton>
        );
    };

    displayItemView(i, row) {
        return (
            <>
                <TableCell component="th" scope="row">
                    { i + 1 }
                </TableCell>
                <TableCell>{ row.name }</TableCell>
                <TableCell align="right">
                    { this.displayEditIcon(row.id, row.name) }
                    { this.displayDeleteIcon(row.id) }
                </TableCell>
            </>
        );
    }

    displayItemEdit() {
        const { classes } = this.props;
        const { id, nameEdit } = this.state;

        return (
            <>
                <TableCell component="th" scope="row" colSpan={ 2 }>
                    <TextField margin="normal" required fullWidth id="categoryName" name="categoryName" label="Nom de la catégorie" type="text" value={ nameEdit } onChange={ e => this.setState({ nameEdit: e.target.value }) } />
                </TableCell>
                <TableCell align="right">
                    <IconButton aria-label="Enregistrer" onClick={ () => this.updateCategory(nameEdit, id) }>
                        <SaveIcon color="secondary" className={ classes.categorysaveicon } />
                    </IconButton>
                    <IconButton aria-label="Annuler" onClick={ () => this.closeEdit() }>
                        <CloseIcon color="secondary" className={ classes.categorysaveicon } />
                    </IconButton>
                </TableCell>
            </>
        );
    }

    displayCategory() {
        const { classes } = this.props;
        const { category, id } = this.state;

        if (!category || 0 === category.length) {
            return "Aucune catégorie définie";
        }

        return (
            <TableContainer component={ Paper }>
                <Table className={ classes.table } size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">#</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="right">Action(s)</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { category.map((row, i) => {
                            return <TableRow key={ row.id }>{ row.id === id ? this.displayItemEdit(row) : this.displayItemView(i, row) }</TableRow>;
                        }) }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    render() {
        const { open } = this.state;

        return (
            <>
                { this.displayAddButton() }
                <Dialog open={ open } onClose={ () => this.handleClose() } aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Gérer les catégories</DialogTitle>

                    <DialogContent>
                        { this.addCategory() }
                        { this.displayCategory() }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ () => this.handleClose() } color="primary">
                            Annuler
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default withStyles(useStyles)(withSnackbar(Category));
