/* eslint-disable jsx-a11y/anchor-is-valid */

import Client from "./Client";
import ClientDelete from "./ClientDelete";
import ClientInfo from "./ClientInfo";
import FbConfig from "../base";
import { withStyles } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import LaunchIcon from "@material-ui/icons/Launch";
import SearchIcon from "@material-ui/icons/Search";
import parsePhoneNumber from "libphonenumber-js";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { Link } from "react-router-dom";


export class ClientList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            search: "",
            users: [],
            orders: []
        };
    }

    componentWillUnmount() {
        this.setState({ users: null });
    }

    getOrders() {
        const user = JSON.parse(localStorage.getItem("token"));
        let userRef = FbConfig.database().ref("orders/" + user.uid + "/");
        userRef.on("value", snapshot => {
            const orders = snapshot.val();
            const ordersList = [];
            for (let id in orders) {
                ordersList.push({ id, ...orders[id] });
            }
            this.setState({ orders: ordersList });
        });
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem("token"));
        let UserRef = FbConfig.database().ref("customers/" + user.uid + "/");
        this.getOrders();


        this.setState({ loading: true });

        UserRef.on("value", snapshot => {
            let customers = snapshot.val();
            let newState = [];
            for (let customer in customers) {
                newState.push({
                    userId: customer,
                    email: customers[customer].email,
                    fullname: customers[customer].firstname + (customers[customer].company ? " (" + customers[customer].company + ")" : ""),
                    type: customers[customer].type,
                    phone: parsePhoneNumber(customers[customer].phone || "", "FR"),
                    note: customers[customer].userNote,
                    address: customers[customer].userAddress,
                    firstname: customers[customer].firstname,
                    company: customers[customer].company,
                    source: customers[customer].source,
                    sourceOther: customers[customer].sourceOther,
                    createDate: customers[customer]?.createDate,
                    updateDate: customers[customer]?.updateDate
                });
            }
            this.setState({
                users: newState,
                loading: false
            });
        });
    }

    filter(r) {
        return r.fullname?.toLowerCase().includes(this.state.search.toLowerCase().trim()) || r.email?.toLowerCase().includes(this.state.search.toLowerCase().trim()) || r.phone?.number?.replace(/\s/g, "").toLowerCase().includes(this.state.search.replace(/\s/g, "").toLowerCase().trim());
    }

    EnhancedTableToolbar() {
        const { classes } = this.props;

        return (
            <Toolbar className={ classes.root }>
                <Typography className={ classes.title } variant="h6" id="tableTitle" component="div">
                    Clients
                </Typography>
                <Paper component="div" className={ classes.inputRoot }>
                    <InputBase className={ classes.input } placeholder="Search client" inputProps={ { "aria-label": "search google maps" } } onChange={ e => this.setState({ search: e.target.value }) } />
                    <IconButton type="submit" className={ classes.iconButton } aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Toolbar>
        );
    }

    clientPresta(client) {

        let orders = this.state.orders.filter(r => r.client_id === client).length;

        if (!orders) {return null;}

        return (
            <>
                <IconButton aria-label="Modifier" component={ Link } to={ `/orders/${client}` }>
                    <Badge badgeContent={ orders } color="primary">
                        <LaunchIcon />
                    </Badge>

                </IconButton>
            </>
        );
    }

    render() {
        const { classes } = this.props;
        const users = this.state.users;

        return (
            <>
                <div className={ classes.root }>
                    <Paper className={ classes.paper }>
                        { this.EnhancedTableToolbar() }
                        <TableContainer component={ Paper }>
                            <Table className={ classes.table } size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nom (Entreprise)</TableCell>
                                        <TableCell align="right" className={ classes.phoneCell }>
                                            Téléphone
                                        </TableCell>
                                        <TableCell align="right" className={ classes.emailCell }>
                                            Email
                                        </TableCell>
                                        <TableCell align="right">Action(s)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { users
                                        .filter(r => this.filter(r))
                                        .map((row, idx) => (
                                            <TableRow key={ `${row.name}_${idx}` }>
                                                <TableCell component="th" scope="row">
                                                    { row.fullname }
                                                </TableCell>
                                                <TableCell align="right" className={ classes.phoneCell }>
                                                    <a href={ row.phone?.getURI() }>{ row.phone?.formatInternational() }</a>
                                                </TableCell>
                                                <TableCell align="right" className={ classes.emailCell }>
                                                    <a href={ `mailto:${row.email}` }>{ row.email }</a>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <div className={ classes.subContainer }>
                                                        { this.clientPresta(row.userId) }
                                                        <ClientInfo id={ row.userId } />
                                                        <Client customer={ row } fromForm="edit" />
                                                        <ClientDelete customer={ row } />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )) }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
                <Client />
            </>
        );
    }
}

const useStyles = theme => ({
    root: {
        "& a": {
            textDecoration: "none"
        },
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 400
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    inputRoot: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 250
    },
    title: {
        flex: 1
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    iconButton: {
        padding: 10
    },
    nameCell: {},
    phoneCell: {
        [theme.breakpoints.down("570")]: {
            display: "none"
        }
    },
    emailCell: {
        [theme.breakpoints.down("760")]: {
            display: "none"
        }
    },
    actionCell: {}
});

export default withStyles(useStyles)(ClientList);
