import Field from "./components/Field";
import Header from "./components/Header";
import Validate from "./components/Validate";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justifyContent: "center"
    },
    fields: {
        display: "flex",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        flexDirection: "column"
    }
}));

export default function Settings({ userInfo }) {
    const classes = useStyles();

    const [d, setData] = useState({ invoice: userInfo.invoice, quote: userInfo.quote });
    const data = { d, setData, userInfo };

    useEffect(() => {
        setData({ invoice: userInfo.invoice, quote: userInfo.quote });
    }, [userInfo]);

    return (
        <Card className={ classes.root }>
            <Header title="Paramètres" subheader="personnaliser votre interface, vos factures et devis" icon={ <SettingsIcon /> } />
            <CardContent>
                <FormControl component="fieldset" fullWidth>
                    <div className={ classes.fields }>
                        <Field id="invoice" label="Numérotation Facture" data={ data } />
                        <Field id="quote" label="Numérotation Devis" data={ data } />
                    </div>
                </FormControl>
            </CardContent>
            <Validate data={ data } />
        </Card>
    );
}
