import { StyleSheet, Text, View } from "@react-pdf/renderer";

// eslint-disable-next-line no-unused-vars
import React, { Fragment } from "react";

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: "row",
        marginTop: 36,
        justifyContent: "flex-end"
    },
    invoiceRefContainer: {
        flexDirection: "row",
        justifyContent: "flex-end"
    },
    invoiceDate: {
        //fontSize: 12,
        fontStyle: "bold"
    },
    label: {
        //fontSize: 12,
        width: 110,
        textAlign: "right",
        padding: "0 30px 0 0"
    }
});

const InvoiceNo = ({ invoice }) => (
    <Fragment>
        <View style={ styles.invoiceNoContainer }>
            <Text style={ styles.label }>{ invoice.title }</Text>
            <Text style={ styles.invoiceDate }>{ invoice.invoice_no }</Text>
        </View>
        <View style={ styles.invoiceRefContainer }>
            <Text style={ styles.label }>{ invoice.qoteRefTitle }</Text>
            <Text style={ styles.invoiceDate }>{ invoice.quoteRef }</Text>
            <Text style={ styles.label }>Date</Text>
            <Text style={ styles.invoiceDate }>{ invoice.trans_date }</Text>
        </View>
    </Fragment>
);

export default InvoiceNo;
