import { useAuthentication } from "../../auth";
import FbConfig from "../../base";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";

const useStyles = makeStyles(() => ({
    validate: {
        display: "flex",
        justifyContent: "end"
    }
}));

export default function Validate({ data: { d, userInfo, setData }, required = [], clean = [], validate = [] }) {
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem("token"));
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const auth = useAuthentication();

    const finaly = () => {
        for (let cl of clean) {
            d[cl] = "";
        }
        setData({ ...d });
        setLoading(false);
        return false;
    };

    const authenticated = async () => {
        const _user = FbConfig.auth().currentUser;
        let _credential = FbConfig.auth.EmailAuthProvider.credential(userInfo.email, d.old_password);
        let status = true;

        status = await _user.reauthenticateWithCredential(_credential).catch(error => {
            enqueueSnackbar(error.message, { variant: "error" });
            d.email = userInfo.email;
            return false;
        });
        return { _user, status };
    };

    const uploadEmail = async () => {
        let { _user, status } = await authenticated();
        if (status === false) return finaly();

        status = await _user
            .updateEmail(d.email)
            .then(() => {
                enqueueSnackbar("Email modifié avec succès", { variant: "success" });
                //userInfo.email = d.email;
                return true;
            })
            .catch(error => {
                enqueueSnackbar(error.message, { variant: "error" });
                d.email = userInfo.email;
                return false;
            });
        return status;
    };

    const uploadPassword = async () => {
        if (d.old_password === d.new_password) {
            enqueueSnackbar("Les mots de passe sont identiques", {
                variant: "error"
            });
            return;
        }
        let { _user, status } = await authenticated();
        if (status === false) return;

        await _user
            .updatePassword(d.new_password)
            .then(() => enqueueSnackbar("Mot de passe mis à jour", { variant: "success" }))
            .catch(error => enqueueSnackbar(error.message, { variant: "error" }));
    };

    const updateData = async () => {
        setLoading(true);
        if (d.email && d.email != userInfo.email) {
            if ((await uploadEmail()) === false) return finaly();
        }

        if (d.old_password && d.new_password) {
            await uploadPassword();
        }

        if (checkData()) return finaly();

        for (let cl of clean) {
            delete d[cl];
            delete userInfo[cl];
        }

        await FbConfig.database()
            .ref("users/" + user.uid)
            .update({ ...userInfo, ...d })
            .then(() => {
                let { address, bic, email, iban, logo, name } = { ...userInfo, ...d };
                localStorage.setItem("token", JSON.stringify({ address, bic, email, iban, logo, username: name, uid: user.uid }));
                auth.setUser({ address, bic, email, iban, logo, username: name, uid: user.uid });
                enqueueSnackbar("Les informations on bien été changées", {
                    variant: "success"
                });
            })
            .catch(error => enqueueSnackbar(error.message, { variant: "error" }));

        finaly();
    };

    const checkData = () => {
        if (loading) return true;
        for (let key of required) {
            if (!d[key] || d[key] === "") return true;
        }
        for (let key in d) {
            if (key in userInfo && d[key] !== userInfo[key]) return false;
        }
        for (let key of validate) {
            if (d[key] && d[key] !== "") return false;
        }
        return true;
    };

    return (
        <CardActions className={ classes.validate }>
            <Button variant="contained" color="primary" onClick={ updateData } disabled={ checkData() }>
                { loading ? <CircularProgress /> : "Valider" }
            </Button>
        </CardActions>
    );
}
