import fbConfig from "./base";
import { FormErrors } from "./forms";
import { FormikTextField } from "@dccs/react-formik-mui";
import { Button, Divider, Typography, makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Reaptcha from "reaptcha";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    Divider: {
        marginBottom: 20,
        marginTop: 20
    },
    container_title: {
        textAlign: "center"
    },
    green_button: {
        "&:active": {
            backgroundColor: "#8bc34a"
        },
        "&:hover, &:focus": {
            backgroundColor: "#8bc34a"
        },
        backgroundColor: "#4caf50"
    },
    main_container: {
        alignItems: "center",
        backgroundColor: "#ebebeb",
        display: "flex",
        height: "100vh",
        justifyContent: "center"
    },
    paper: {
        flexDirection: "column",
        overflow: "auto",
        padding: 30
    },
    root: {
        textAlign: "center"
    },
    spaceField: {
        marginTop: 25
    },
    subTitle: {
        fontStyle: "italic"
    },
    sub_container: {
        width: 400
    },
    title: {
        paddingRight: 10,
        paddingTop: 10,
        pagginLeft: 10
        //textAlign: "center",
    },
    valider_button: {
        "&:active": {
            backgroundColor: "#ff2e62"
        },
        "&:hover, &:focus": {
            backgroundColor: "#ff2e62"
        }
    }
}));

const validationSchema = Yup.object().shape({
    email: Yup.string().ensure().required("L'email est obligatoire"),
    password: Yup.string().ensure().required("Le mot de passe est obligatoire"),
    username: Yup.string().ensure().required("L'identifiant est obligatoire").nullable(false)
});

export default function Account() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [verified, setVerified] = useState(false);

    const redirectButton = () => {
        history.push("/login");
    };

    const onSubmit = values => {
        fbConfig
            .auth()
            .createUserWithEmailAndPassword(values.email.toString().toLowerCase(), values.password)
            .then(result => {
                if (result.user.uid && "" !== result.user.uid) {
                    fbConfig
                        .database()
                        .ref("users/" + result.user.uid)
                        .update({
                            email: values.email.toString().toLowerCase(),
                            firstInstall: true,
                            invoice: 250,
                            name: values.username,
                            quote: 250
                        })
                        .then(() => {
                            var sendMessage = fbConfig.functions().httpsCallable("sendCreateAccount");

                            sendMessage({ email: values.email.toString().toLowerCase(), username: values.username });
                            enqueueSnackbar("Enregistrement des modifications", { variant: "success" });
                        })
                        .catch(() => enqueueSnackbar("Un problème est survenu à l'enregistrement des modifications", { variant: "error" }));
                }
                enqueueSnackbar("Votre compte a bien été crée, bienvenue sur Ez Presta", { variant: "success" });
                history.push("/");
            })
            .catch(function (error) {
                var errorCode = error.code;
                var errorMessage = error.message;
                enqueueSnackbar(errorMessage + " : " + errorCode, { variant: "error" });
            });
    };

    // function onChange(value) {
    //   console.log("Captcha value:", value);
    // }

    const onVerify = value => {
        if (value) {
            setVerified(true);
        }
    };

    return (
        <div className={ classes.main_container }>
            <div className={ classes.sub_container }>
                <Paper className={ classes.paper } elevation={ 5 }>
                    <div className={ classes.container_title }>
                        <Typography className={ classes.title } variant="h2">
                            Ez Presta
                        </Typography>
                        <span className={ classes.subTitle }>la gestion simplifiée de votre entreprise</span>
                    </div>
                    <Divider className={ classes.Divider } variant="middle" />
                    <Typography>Créer votre compte Ez Presta</Typography>
                    <Formik validateOnBlur={ false } validateOnChange={ false } onSubmit={ onSubmit } validationSchema={ validationSchema } initialValues={ { email: null, password: null, username: null } }>
                        { ({ errors }) => (
                            <Form className={ classes.form } noValidate>
                                <FormErrors errors={ errors } />
                                <FormikTextField variant="outlined" margin="normal" required fullWidth type="text" label="Nom d'utilisateur ou pseudo" name="username" />
                                <FormikTextField variant="outlined" margin="normal" required fullWidth type="text" label="Email" name="email" />
                                <FormikTextField variant="outlined" margin="normal" required fullWidth type="password" label="Mot de passe" name="password" />
                                <Reaptcha sitekey="6Lcb-KYZAAAAAKb8Sapsj_O25qipuUUNYwTXr58w" onVerify={ e => onVerify(e) } />
                                <Button className={ [classes.spaceField, classes.valider_button] } type="submit" fullWidth variant="contained" color="secondary" disabled={ false === verified ? true : false }>
                                    Valider
                                </Button>
                                <Divider className={ classes.Divider } variant="middle" />
                                <Button type="button" fullWidth variant="contained" color="secondary" className={ classes.green_button } onClick={ () => redirectButton() }>
                                    J&apos;ai déjà un compte
                                </Button>
                            </Form>
                        ) }
                    </Formik>
                </Paper>
            </div>
        </div>
    );
}
