import { FormikTextField } from "@dccs/react-formik-mui";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import EuroIcon from "@material-ui/icons/Euro";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React from "react";

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650
    },
    green: {
        color: "#fff",
        backgroundColor: green[500]
    },
    paper: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    form: {
        marginTop: theme.spacing(1),
        width: "100%"
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    }
}));

export default function BookingAddFee(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    //const setFieldValue = props.setter;

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button variant="outlined" color="primary" onClick={ handleOpen } className={ classes.button } startIcon={ <EuroIcon /> } size="small">
                Ajouter des frais supplémentaires
            </Button>

            <Dialog open={ open } onClose={ handleClose }>
                <DialogTitle id="form-dialog-title">Ajouter des frais</DialogTitle>
                <Formik
                    validateOnBlur={ false }
                    validateOnChange={ false }
                    //enableReinitialize={true}
                    initialValues={ {
                        feeName: "",
                        feeAmount: ""
                    } }
                    //validationSchema={}
                    onSubmit={ (values, { setSubmitting }) => {
                        setTimeout(() => {
                            props.setNewFee(values);
                            enqueueSnackbar("Le frais a été ajouté", {
                                variant: "info"
                            });
                            handleClose();
                            setSubmitting(false);
                        }, 400);
                    } }>
                    { ({ isSubmitting }) => (
                        <Form className={ classes.form } noValidate>
                            <DialogContent>
                                <FormikTextField id="feeName" name="feeName" margin="normal" label="type de frais" type="text" fullWidth />
                                <FormikTextField
                                    id="feeAmount"
                                    name="feeAmount"
                                    margin="normal"
                                    label="montant du frais en euros"
                                    type="number"
                                    fullWidth
                                    InputProps={ {
                                        endAdornment: <EuroIcon />
                                    } }
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={ handleClose } color="primary">
                                    Annuler
                                </Button>
                                <Button type="submit" color="primary" disabled={ isSubmitting }>
                                    Valider
                                </Button>
                            </DialogActions>
                        </Form>
                    ) }
                </Formik>
            </Dialog>
        </>
    );
}
