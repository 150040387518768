import CatalogDelete from "./CatalogDelete";
import CatalogEdit from "./CatalogEdit";
import { getDuration } from "./CatalogTools";
import Category from "./Category";
import FbConfig from "../base";
import { CircularIndeterminate, dynamicSortMultiple, price } from "../utils/humanRead";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputBase from "@material-ui/core/InputBase";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { alpha, makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import Looks3Icon from "@material-ui/icons/Looks3";
import PaymentIcon from "@material-ui/icons/Payment";
import SearchIcon from "@material-ui/icons/Search";
import WifiIcon from "@material-ui/icons/Wifi";
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { useObjectVal } from "react-firebase-hooks/database";

const useStyles = makeStyles(theme => ({
    root: {
        "& > *": {
            borderBottom: "unset"
        }
    },
    form: {
        marginTop: theme.spacing(1),
        width: "100%"
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    },
    bgYearColor: {
        backgroundColor: "#3f50b552"
    },
    tablehead: {
        backgroundColor: "#f5f5f5"
    },
    subContainer: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        textAlign: "center",
        //border: "1px solid black",
        height: "40px",
        width: "auto",
        textOverflow: "ellipsis",
        overflow: "hidden",
        lineHeight: "40px"
    },
    Action: {
        marginRight: "30px"
    },
    paper: {
        margin: theme.spacing(2)
    },
    paperTitle: {
        backgroundColor: "#f5f5f5"
    },
    notDisplay: {
        display: "none"
    },
    dispNot720: {
        [theme.breakpoints.down("720")]: {
            display: "none"
        }
    },
    dispNot590: {
        [theme.breakpoints.down("590")]: {
            display: "none"
        }
    },
    textConcat: {
        display: "inline-block",
        wordWrap: "break-word",
        overflow: "hidden",
        maxHeight: "3.6em" /* (Number of lines you want visible) * (line-height) */,
        lineHeight: "1.2em",
        textAlign: "justify"
    }
}));

const useToolbar = makeStyles(theme => ({
    root: {
        "& a": {
            textDecoration: "none"
        },
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    title: {
        flex: 1
    },
    inputRoot: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 250,
        color: "inherit"
    },
    menuItem: {
        display: "flex",
        justifyContent: "space-between"
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300
    },
    noLabel: {
        marginTop: theme.spacing(3)
    },
    titleFilter: {
        fontWeight: "bold",
        justifyContent: "center"
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25)
        },
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(1),
            width: "auto"
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch"
            }
        }
    }
}));

const getOptions = values => {
    return (
        <>
            { true === values.payOnline && <PaymentIcon fontSize="small" titleAccess="Payable en ligne" /> }
            { true === values.bookOnline && <WifiIcon fontSize="small" titleAccess="Réservable en ligne" /> }
            { true === values.paymentInstalment && <Looks3Icon fontSize="small" titleAccess="Payable en 3x" /> }
        </>
    );
};

const getDurationById = values => {
    if (!values && !values.duration && !values.durationAllday) {
        return null;
    }

    let getItemDuration = getDuration().find(duration => duration.id === values.duration);
    return getItemDuration.name;
};

function EnhancedTableToolbar({ filters, setFilters }) {
    const { type, title, bookOnline, payOnline, paymentInstalment } = filters;
    const categ = JSON.parse(localStorage.getItem("category")).map(item => item.name);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useToolbar();

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeTitle = event => {
        setFilters({ ...filters, title: event.target.value });
    };

    const changeType = event => {
        setFilters({ ...filters, type: event.target.value });
    };

    const changeBook = () => {
        setFilters({ ...filters, bookOnline: !bookOnline });
    };

    const changeOnline = () => {
        setFilters({ ...filters, bookOnline: !bookOnline });
    };

    const changeInstalment = () => {
        setFilters({ ...filters, paymentInstalment: !paymentInstalment });
    };

    return (
        <Toolbar className={ classes.root }>
            <Typography className={ classes.title } variant="h6" id="tableTitle" component="div">
                Catalogue
            </Typography>
            <Tooltip title="Search">
                <div className={ classes.search }>
                    <div className={ classes.searchIcon }>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={ {
                            root: classes.inputRoot,
                            input: classes.inputInput
                        } }
                        inputProps={ { "aria-label": "search" } }
                        value={ title }
                        onChange={ changeTitle }
                    />
                </div>
            </Tooltip>
            <Tooltip title="Filter list">
                <IconButton aria-label="filter list" onClick={ handleClick }>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
            <Menu id="simple-menu" anchorEl={ anchorEl } keepMounted open={ Boolean(anchorEl) } onClose={ handleClose }>
                <MenuItem disabled className={ classes.titleFilter }>
                    Filters
                </MenuItem>
                <Divider style={ { margin: "0 6px" } } />
                <MenuItem className={ classes.menuItem }>
                    Type :
                    <FormControl className={ classes.formControl } variant="outlined">
                        <Select
                            multiple
                            value={ type }
                            onChange={ changeType }
                            input={ <Input /> }
                            renderValue={ selected => (
                                <div className={ classes.chips }>
                                    { selected.map(value => {
                                        return <Chip key={ value } label={ value } className={ classes.chip } />;
                                    }) }
                                </div>
                            ) }>
                            { categ.map(name => {
                                return (
                                    <MenuItem key={ name } value={ name }>
                                        <Checkbox checked={ type.indexOf(name) > -1 } />
                                        <ListItemText primary={ name } />
                                    </MenuItem>
                                );
                            }) }
                        </Select>
                    </FormControl>
                </MenuItem>
                <MenuItem className={ classes.menuItem }>
                    Réservable en ligne uniquement : <Switch checked={ bookOnline } onChange={ changeBook } />
                </MenuItem>
                <MenuItem className={ classes.menuItem }>
                    Payable en ligne uniquement : <Switch checked={ payOnline } onChange={ changeOnline } />
                </MenuItem>
                <MenuItem className={ classes.menuItem }>
                    Payable en 3x uniquement : <Switch checked={ paymentInstalment } onChange={ changeInstalment } />
                </MenuItem>
            </Menu>
        </Toolbar>
    );
}

export default function CatalogList() {
    const classes = useStyles();
    const user = JSON.parse(localStorage.getItem("token"));
    const [value, loading, error] = useObjectVal(FbConfig.database().ref("catalog/" + user.uid + "/"));
    const [filters, setFilters] = useState({ type: [], title: "", bookOnline: false, payOnline: false, paymentInstalment: false });

    let catalogListWithId = null;

    if (value) {
        const ids = Object.keys(value);
        const values = Object.values(value);

        catalogListWithId = values.map((value, index) => {
            return Object.assign({}, value, { id: ids[index] });
        });
    }

    const CatalogElementItem = ({ key, catalog }) => {
        let color = catalog.color;
        let style = {};
        if (color && "#ffffff00") style.borderLeft = `5px solid ${color}`;

        return (
            <React.Fragment>
                <TableRow key={ key } style={ style }>
                    <TableCell component="th" scope="row">
                        { catalog.type }
                    </TableCell>
                    <TableCell align="right">{ catalog.name }</TableCell>
                    <TableCell align="right" className={ classes.dispNot720 }>
                        <span className={ classes.textConcat }>{ catalog.description }</span>
                    </TableCell>
                    <TableCell align="right" className={ classes.dispNot590 }>
                        { getDurationById(catalog) }
                    </TableCell>
                    <TableCell align="right">{ price(catalog.price) }</TableCell>
                    <TableCell align="right">{ getOptions(catalog) }</TableCell>
                    <TableCell align="right">
                        <div className={ classes.subContainer }>
                            <CatalogEdit action="edit" item={ catalog } />
                            <CatalogDelete item={ catalog } />
                        </div>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    const catalogFilter = data => {
        if (!data) return false;
        if (filters.type.length > 0) {
            if (!filters.type.includes(data.type)) return false;
        }
        if (filters.title.length > 0) {
            if (!data.name.toLowerCase().includes(filters.title.toLowerCase())) return false;
        }
        if (filters.bookOnline === true) {
            if (data.bookOnline !== filters.bookOnline) return false;
        }
        if (filters.payOnline === true) {
            if (data.payOnline !== filters.payOnline) return false;
        }
        if (filters.paymentInstalment === true) {
            if (data.paymentInstalment !== filters.paymentInstalment) return false;
        }
        return true;
    };

    const CatalogElement = () => {
        return catalogListWithId
            .filter(catalogFilter)
            .sort(dynamicSortMultiple("type", "name"))
            .map(catalog => {
                return <CatalogElementItem key={ catalog.id } catalog={ catalog } />;
            });
    };

    return (
        <>
            <CatalogEdit />
            &nbsp;&nbsp;
            <Category />
            { error && <strong>Error: { error }</strong> }
            { loading && <CircularIndeterminate /> }
            { !loading && value && (
                <main>
                    <Paper className={ classes.paper }>
                        <EnhancedTableToolbar filters={ filters } setFilters={ setFilters } />
                        <TableContainer component={ Paper }>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Type
                                        </TableCell>
                                        <TableCell align="right">Titre</TableCell>
                                        <TableCell align="right" className={ classes.dispNot720 }>
                                            Description
                                        </TableCell>
                                        <TableCell align="right" className={ classes.dispNot590 }>
                                            Durée
                                        </TableCell>
                                        <TableCell align="right">Prix</TableCell>
                                        <TableCell align="right">Option(s)</TableCell>
                                        <TableCell align="right">Action(s)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <CatalogElement />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </main>
            ) }
            { !loading && !value && <div className={ classes.root }>{ "Votre catalogue est vide" }</div> }
        </>
    );
}
