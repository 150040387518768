import { StyleSheet, Text, View } from "@react-pdf/renderer";

// eslint-disable-next-line no-unused-vars
import React from "react";

const styles = StyleSheet.create({
    contactContainer: {
        flexDirection: "row",
        marginTop: 24,
        width: "100%"
        //backgroundColor: "blue",
    },
    clientContainer: {
        //backgroundColor: "red",
        //letterSpacing: 1,
        //fontSize: 8,
        textAlign: "right",
        width: "50%"
    },
    userContainer: {
        //letterSpacing: 1,
        //fontSize: 8,
        textAlign: "left",
        width: "50%"
        //backgroundColor: "yellow",
    },
    reportTitle: {
        color: "black",
        textTransform: "uppercase"
    }
});

const InvoiceContact = ({ user, client }) => (
    <View style={ styles.contactContainer }>
        <View style={ styles.userContainer }>
            <Text style={ styles.reportTitle }>{ user.company }</Text>
            <Text>SIRET : { user.siret }</Text>
            <Text>{ user.address }</Text>
            <Text>Tel : { user.phone }</Text>
            <Text>Mail : { user.email }</Text>
            <Text>Web : { user.url }</Text>
        </View>
        <View style={ styles.clientContainer }>
            <Text style={ styles.reportTitle }>Client :</Text>
            <Text>
                { client.company } - { client.firstname }
            </Text>
            <Text>{ client.userAddress }</Text>
            <Text>{ client.phone }</Text>
            <Text>{ client.email }</Text>
        </View>
    </View>
);

export default InvoiceContact;
