import Field from "./components/Field";
import Header from "./components/Header";
import Validate from "./components/Validate";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justifyContent: "center"
    },
    fields: {
        display: "flex",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        flexDirection: "column"
    }
}));

export default function UserAccount({ userInfo }) {
    const classes = useStyles();

    const [d, setData] = useState({ name: userInfo.name, email: userInfo.email });
    const data = { d, setData, userInfo };

    useEffect(() => {
        setData({ name: userInfo.name, email: userInfo.email });
    }, [userInfo]);

    return (
        <Card className={ classes.root }>
            <Header title="Compte utilisateur" subheader="Changer vos informations personnelles" icon={ <AccountCircleIcon /> } />
            <CardContent>
                <FormControl component="fieldset" fullWidth>
                    <div className={ classes.fields }>
                        <Field required id="name" label="Nom" data={ data } />
                        <Field required id="email" data={ data } />
                        <Field required id="old_password" type="password" data={ data } />
                        <Field id="new_password" type="password" data={ data } />
                    </div>
                </FormControl>
            </CardContent>
            <Validate data={ data } required={ ["name", "email", "old_password"] } clean={ ["old_password", "new_password"] } validate={ ["new_password"] } />
        </Card>
    );
}
