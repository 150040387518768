import DateFnsUtils from "@date-io/date-fns";
import { FormikDateTimepicker, FormikTextField } from "@dccs/react-formik-mui";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import parse from "autosuggest-highlight/parse";
import { Form, Formik } from "formik";
// eslint-disable-next-line no-restricted-imports
import throttle from "lodash/throttle";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React from "react";

//GoogleMaps Start
function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("id", id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };
//GoogleMaps End

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650
    },
    green: {
        color: "#fff",
        backgroundColor: green[500]
    },
    paper: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    form: {
        marginTop: theme.spacing(1),
        width: "100%"
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2)
    }
}));

export default function BookingAddNewLocation(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const loaded = React.useRef(false);

    const [open, setOpen] = React.useState(false);
    const [inputValue, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState([]);
    const [getPlaceId, setPlaceId] = React.useState({});

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (typeof window !== "undefined" && !loaded.current) {
        if (!document.querySelector("#google-maps")) {
            loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyCYVIg1bNAfhwm7CaHjnWo5RApHWgoG6FY&libraries=places", document.querySelector("head"), "google-maps");
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((input, callback) => {
                autocompleteService.current.getPlacePredictions(input, callback);
            }, 200),
        []
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === "") {
            setOptions([]);
            return undefined;
        }

        fetch({ input: inputValue }, results => {
            if (active) {
                setOptions(results || []);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, fetch]);

    // Distance et geoloc
    const fill2 = (e, setFieldValue, setValue) => {
        if (true === setValue) {
            setFieldValue("locationWhere", e.target.value);
        }
        setInputValue(e.target.value);
    };

    return (
        <>
            <Button variant="outlined" color="primary" onClick={ handleOpen } className={ classes.button } startIcon={ <EditLocationIcon /> } size="small">
                Ajouter un lieux
            </Button>

            <Dialog open={ open } onClose={ handleClose }>
                <DialogTitle id="form-dialog-title">Ajouter un nouveau lieu</DialogTitle>
                <Formik
                    validateOnBlur={ false }
                    validateOnChange={ false }
                    initialValues={ {
                        locationDate: props.locationDate
                    } }
                    onSubmit={ (values, { setSubmitting }) => {
                        setTimeout(() => {
                            if (!values.locationDate) {
                                enqueueSnackbar("Veuillez remplir le champ Date", { variant: "error" });
                                setSubmitting(false);
                                return;
                            }

                            if (!values?.locationTitle) {
                                enqueueSnackbar("Veuillez remplir le champ Titre", { variant: "error" });
                                setSubmitting(false);
                                return;
                            }

                            if (!values.locationWhere) {
                                enqueueSnackbar("Veuillez remplir le champ Lieux", { variant: "error" });
                                setSubmitting(false);
                                return;
                            }

                            if (!getPlaceId["place_id"]) {
                                enqueueSnackbar("Veuillez sélectionner un lieu dans la liste", { variant: "error" });
                                setSubmitting(false);
                                return;
                            }

                            values["locationDate"] = values["locationDate"].toISOString();
                            values["place_id"] = getPlaceId["place_id"];
                            values["isDefault"] = props.isDefault;
                            props.setNewLocation(values);
                            enqueueSnackbar("Le lieu supplémentaire a été ajouté", {
                                variant: "info"
                            });
                            handleClose();
                            setSubmitting(false);
                        }, 400);
                    } }>
                    { ({ isSubmitting, handleChange, handleBlur, setFieldValue }) => (
                        <Form className={ classes.form } noValidate>
                            <DialogContent>
                                <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                                    <FormikDateTimepicker id="locationDate" name="locationDate" margin="normal" label="Quand" fullWidth keyboard="true" clearable="true" autoOk format="dd/MM/yyyy HH:mm:ss"></FormikDateTimepicker>
                                </MuiPickersUtilsProvider>
                                <FormikTextField id="locationTitle" name="locationTitle" margin="normal" label="Evènement" type="text" fullWidth />
                                <Autocomplete
                                    id="locationWhere"
                                    getOptionLabel={ option => (typeof option === "string" ? option : option.description) }
                                    onChange={ (event, value) => {
                                        if (value) {
                                            setPlaceId(value);
                                        }
                                    } }
                                    filterOptions={ x => x }
                                    options={ options }
                                    autoComplete
                                    includeInputInList
                                    freeSolo
                                    //disableOpenOnFocus
                                    renderInput={ params => (
                                        <TextField
                                            { ...params }
                                            label="Lieu"
                                            name="locationWhere"
                                            fullWidth
                                            margin="normal"
                                            onChange={ e => {
                                                handleChange(e);
                                                fill2(e, setFieldValue);
                                            } }
                                            onBlur={ e => {
                                                handleBlur(e);
                                                fill2(e, setFieldValue, true);
                                            } }
                                        />
                                    ) }
                                    renderOption={ option => {
                                        const matches = option.structured_formatting.main_text_matched_substrings;
                                        const parts = parse(
                                            option.structured_formatting.main_text,
                                            matches.map(match => [match.offset, match.offset + match.length])
                                        );

                                        return (
                                            <Grid container alignItems="center">
                                                <Grid item>
                                                    <LocationOnIcon className={ classes.icon } />
                                                </Grid>
                                                <Grid item xs>
                                                    { parts.map((part, index) => (
                                                        <span
                                                            key={ index }
                                                            style={ {
                                                                fontWeight: part.highlight ? 700 : 400
                                                            } }>
                                                            { part.text }
                                                        </span>
                                                    )) }

                                                    <Typography variant="body2" color="textSecondary">
                                                        { option.structured_formatting.secondary_text }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        );
                                    } }
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={ handleClose } color="primary">
                                    Annuler
                                </Button>
                                <Button type="submit" color="primary" disabled={ isSubmitting }>
                                    Valider
                                </Button>
                            </DialogActions>
                        </Form>
                    ) }
                </Formik>
            </Dialog>
        </>
    );
}
