import FbConfig from "../base";
import { getCurrentDate } from "../Tools";
import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, makeStyles } from "@material-ui/core";
import { green, pink } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { useSnackbar } from "notistack";

// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";

const useStyles = makeStyles(theme => ({
    nameContainer: {
        display: "flex",
        alignItems: "center"
    },
    marginRight: theme.spacing(2),
    green: {
        color: "#fff",
        backgroundColor: green[500]
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500]
    },
    checkbox: {
        minWidth: "24px"
    },
    Avatar: {
        marginTop: "5px"
    },
    ListItemText: {
        marginTop: "10px"
    },
    Divider: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    PaymentMethod: {
        textAlign: "center"
    },
    PaymentSelect: {
        minWidth: "200px !important"
    },
    taskIsDone: {
        textDecorationLine: "line-through"
    }
}));

const StyledBadge = withStyles(theme => ({
    badge: {
        right: 0,
        top: 3,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: "0 4px"
    }
}))(Badge);

export default function BookingTodo(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const user = JSON.parse(localStorage.getItem("token"));

    const [taskName, setTaskName] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [id, setId] = useState();

    const values = props.order;
    const orderId = props.id;

    let firebaseTaskURL = "orders/" + user.uid + "/" + orderId + "/todo";
    let dateNow = getCurrentDate("-");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const displaySnackbar = (msg, type) => {
        enqueueSnackbar(msg, {
            variant: type
        });
    };

    const editTask = (id, value) => {
        if (id) {
            setId(id);
            setTaskName(value);
        }
    };

    const editTodo = (id, value) => {
        return (
            <IconButton aria-label="Éditer cette tâche" color="primary" onClick={ () => editTask(id, value) }>
                <EditIcon />
            </IconButton>
        );
    };

    const deleteTask = itemId => {
        const fbUrl = firebaseTaskURL + "/" + itemId + "/";
        FbConfig.database()
            .ref(fbUrl)
            .remove()
            .then(() => enqueueSnackbar("La tâche a été effacée", { variant: "success" }))
            .catch(() => enqueueSnackbar("Erreur lors de la suppression", { variant: "error" }));
    };

    const checkTask = itemId => {
        const fbUrl = firebaseTaskURL + "/" + itemId + "/";
        FbConfig.database()
            .ref(fbUrl)
            .update({
                isDone: true,
                dateDone: dateNow
            })
            .then(() => {
                displaySnackbar("La tâche a bien été modifiée", "success");
            })
            .catch(() => {
                displaySnackbar("Problème à l'enregistrement de la tâche", "error");
                handleClose();
            });
    };

    const deleteTodo = id => {
        return (
            <IconButton aria-label="Supprimer une tâche" color="primary" onClick={ () => deleteTask(id) }>
                <DeleteIcon />
            </IconButton>
        );
    };

    const checkTodo = id => {
        return (
            <IconButton aria-label="Valider cette tâche" color="primary" onClick={ () => checkTask(id) }>
                <CheckCircleIcon />
            </IconButton>
        );
    };

    const addTask = () => {
        return (
            <>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="taskName"
                    name="taskName"
                    label="Ajouter une tâche"
                    type="text"
                    value={ taskName }
                    onKeyDown={ e => {
                        if (e.key === "Enter") {
                            id ? saveOrUpdateTask(id) : insertTask(taskName);
                        }
                    } }
                    onChange={ e => {
                        setTaskName(e.target.value);
                    } }
                    onBlur={ e => {
                        setTaskName(e.target.value);
                    } }
                    InputProps={ {
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton aria-label="Enregistrer">
                                    <SaveIcon
                                        color="secondary"
                                        className={ classes.categorysaveicon }
                                        onClick={ () => {
                                            id ? saveOrUpdateTask(id) : insertTask(taskName);
                                        } }
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    } }
                />
                <Divider />
            </>
        );
    };

    const countTask = () => {
        let counter = 0;
        let list = getListTask();

        list.map(row => (!row.isDone || false === row.isDone ? (counter += 1) : null));

        return counter;
    };

    const getListTask = () => {
        let list = [];

        if (values && values.todo) {
            const _ids = Object.keys(values.todo);
            const _values = Object.values(values.todo);

            list = _values.map((value, index) => {
                return Object.assign({}, value, { id: _ids[index] });
            });
        }

        return list;
    };

    const displayTask = () => {
        let list = getListTask();

        if (!list || 0 === list.length) {
            return "Aucune tâche définie";
        }

        let i = 1;

        return (
            <TableContainer component={ Paper }>
                <Table className={ classes.table } size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">#</TableCell>
                            <TableCell align="center">Type</TableCell>
                            <TableCell align="right">Action(s)</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        { list.map(row => (
                            <TableRow key={ row.id }>
                                <TableCell component="th" scope="row">
                                    { i++ }
                                </TableCell>
                                <TableCell component="th" scope="row" className={ row.isDone && true === row.isDone ? classes.taskIsDone : null }>
                                    { row.name }
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    { !row.isDone || false === row.isDone ? checkTodo(row.id) : null }
                                    { !row.isDone || false === row.isDone ? editTodo(row.id, row.name) : null }
                                    { deleteTodo(row.id) }
                                </TableCell>
                            </TableRow>
                        )) }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const insertTask = () => {
        saveOrUpdateTask();
    };

    const saveOrUpdateTask = itemId => {
        if ("" === taskName) {
            displaySnackbar("Le libellé de la tâche ne peut pas être vide", "error");
        } else {
            if (itemId) {
                firebaseTaskURL += "/" + itemId;
                FbConfig.database()
                    .ref(firebaseTaskURL)
                    .set({
                        name: taskName
                    })
                    .then(() => {
                        displaySnackbar("La tâche a bien été modifiée", "success");
                    })
                    .catch(() => {
                        displaySnackbar("Problème à l'enregistrement de la tâche", "error");
                        handleClose();
                    });
            } else {
                FbConfig.database()
                    .ref(firebaseTaskURL)
                    .push({
                        name: taskName
                    })
                    .then(() => {
                        displaySnackbar("La tâche a bien été enregistrée", "success");
                    })
                    .catch(() => {
                        displaySnackbar("Problème à l'enregistrement de la tâche", "error");
                        handleClose();
                    });
            }
            setTaskName("");
        }
    };

    const getColorTask = () => {
        return countTask() > 0 ? "#3f51b5" : "green";
    };

    const getMsgTask = () => {
        const nbTask = countTask();

        let msg = "Pas de tâches en attente";

        if (nbTask === 1) {
            msg = "vous avez 1 tâche en attente";
        } else if (nbTask > 1) {
            msg = "vous avez " + nbTask + " tâches en attente";
        }

        return msg;
    };

    return (
        <>
            <Tooltip title={ getMsgTask() }>
                <IconButton aria-label="valider">
                    <StyledBadge badgeContent={ countTask() } color="secondary" onClick={ () => handleClickOpen(props) }>
                        <AssignmentIcon color="primary" style={ { color: getColorTask() } } />
                    </StyledBadge>
                </IconButton>
            </Tooltip>
            <Dialog open={ open } onClose={ handleClose } aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Liste des tâches</DialogTitle>
                <DialogContent>
                    { addTask() }
                    { displayTask() }
                </DialogContent>
                <DialogActions>
                    <Button onClick={ handleClose } color="primary">
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
