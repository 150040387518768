// eslint-disable-next-line no-unused-vars
import React from "react";

const Names = () => {
    return (
        <>
            <div>
                <p>
                    Avant de commencer nous allons vous demander quelques informations pour personnaliser votre compte mais aussi vos devis et factures.
                    <br />
                    <br />
                    Vous pouvez à tout moment modifier vos paramètres en allant dans <a href="/settings">Réglages</a>.
                </p>
            </div>
        </>
    );
};

export default Names;
