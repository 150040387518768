import { useAuthentication } from "../auth/Authentication";
import { getDuration } from "../catalog/CatalogTools";
import { IconButton } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EventIcon from "@material-ui/icons/Event";
// eslint-disable-next-line no-unused-vars
import React from "react";
import ICalendarLink from "react-icalendar-link";

export default function BookingIcal(props) {
    const values = props.order;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const catalog = JSON.parse(localStorage.getItem("catalog"));

    const userSettings = useAuthentication();
    let userAddress = !userSettings.user.address ? "à la maison" : userSettings.user.address;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const calcEndTime = (start, duration) => {
        let endTime = new Date(start);
        endTime.setHours(endTime.getHours() + duration);
        return endTime;
    };

    const removeHour = value => {
        return value.split("T")[0];
    };

    const getLocation = () => {
        if ("home" === values.where) {
            return userAddress;
        }

        return values.where;
    };

    const event = {
        title: "EZPresta : " + values.catalogType + " - " + values.client,
        description: "My Description",
        startTime: values.date,
        endTime: calcEndTime(values.date, values.catalogDuration),
        location: getLocation()
    };

    const eventAllDay = {
        title: "EZPresta : " + values.catalogType + " - " + values.client,
        description: "My Description",
        startTime: removeHour(values.date),
        location: values.where
    };

    const getEvent = () => {
        return values.catalogDuration && values.catalogDuration == 24 ? eventAllDay : event;
    };

    const openInNewTab = url => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    function addTimeToDate(date, hours, minutes) {
        date.setHours(date.getHours() + hours);
        date.setMinutes(date.getMinutes() + minutes);
        return date;
    }

    const makeLink = () => {
        // eslint-disable-next-line no-useless-escape
        let start = new Date(values.date).toISOString().replaceAll(/\.|\:|\-/g, "");
        let idDuration = catalog.find(cat => cat.itemId === values.catalogId)?.duration;
        let [hours, minutes] = [1, 0];
        if (idDuration) [hours, minutes] = getDuration()[idDuration].name.split("h").map(Number);
        let end = addTimeToDate(new Date(values.date), hours, minutes)
            .toISOString()
            // eslint-disable-next-line no-useless-escape
            .replaceAll(/\.|\:|\-/g, "");
        let location = values.where;
        let title = "EZPresta : " + values.catalogType + " - " + values.client;

        let link = `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${start}/${end}&location=${location}&text=${title}`;
        openInNewTab(link);
        handleClose();
    };

    return (
        <>
            <IconButton aria-label="Exporter dans votre calendrier" onClick={ handleClick } color="primary">
                <EventIcon />
            </IconButton>
            <Menu id="simple-menu" anchorEl={ anchorEl } keepMounted open={ Boolean(anchorEl) } onClose={ handleClose }>
                <MenuItem onClick={ makeLink }>Google</MenuItem>
                <MenuItem onClick={ handleClose }>Apple Calendar</MenuItem>
                <ICalendarLink event={ getEvent() } style={ { height: 24, textDecoration: "none", color: "inherit" } }>
                    <MenuItem onClick={ handleClose }>Apple Calendar</MenuItem>
                </ICalendarLink>
                <ICalendarLink event={ getEvent() } style={ { height: 24, textDecoration: "none", color: "inherit" } }>
                    <MenuItem onClick={ handleClose }>Ical</MenuItem>
                </ICalendarLink>
            </Menu>
        </>
    );
}
