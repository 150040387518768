import { FormErrors } from "../../forms";
import { FormikTextField } from "@dccs/react-formik-mui";
import { Avatar, Button, Divider, Paper, Typography, makeStyles } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
    main_container: {
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#ebebeb"
    },
    sub_container: {
        width: 400
    },
    paper: {
        overflow: "auto",
        flexDirection: "column",
        padding: 30
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        width: 28,
        height: 28
    },
    avatar_img: {
        fontSize: 18
    },
    form: {
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    spaceField: {
        marginTop: 25
    },
    title: {
        paddingTop: 10,
        paddingRight: 10,
        pagginLeft: 10
        //textAlign: "center",
    },
    subTitle: {
        fontStyle: "italic"
    },
    Divider: {
        marginTop: 20,
        marginBottom: 20
    },
    container_title: {
        textAlign: "center"
    },
    green_button: {
        color: "white",
        backgroundColor: "#4caf50",
        "&:hover, &:focus": {
            backgroundColor: "#8bc34a"
        },
        "&:active": {
            backgroundColor: "#8bc34a"
        }
    },
    valider_button: {
        "&:hover, &:focus": {
            backgroundColor: "#ff2e62"
        },
        "&:active": {
            backgroundColor: "#ff2e62"
        }
    },
    text_container: {
        display: "flex"
    },
    login_text: {
        alignSelf: "center"
    }
}));

const validationSchema = Yup.object().shape({
    login: Yup.string().ensure().required("L'identifiant est obligatoire").nullable(false),
    password: Yup.string().ensure().required("Le mot de passe est obligatoire")
});

export function LoginForm({ onSubmit }) {
    const classes = useStyles();
    const history = useHistory();

    const redirectButton = () => {
        history.push("/addAccount");
    };

    return (
        <div className={ classes.main_container }>
            <div className={ classes.sub_container }>
                <Paper className={ classes.paper } elevation={ 5 }>
                    <div className={ classes.container_title }>
                        <Typography className={ classes.title } variant="h2">
                            Ez Presta
                        </Typography>
                        <span className={ classes.subTitle }>la gestion simplifiée de votre entreprise</span>
                    </div>
                    <Divider className={ classes.Divider } variant="middle" />
                    <Formik validateOnBlur={ false } validateOnChange={ false } onSubmit={ onSubmit } validationSchema={ validationSchema } initialValues={ { login: "", password: "" } }>
                        { ({ isSubmitting, errors }) => (
                            <Form className={ classes.form } noValidate>
                                <FormErrors errors={ errors } />
                                <div className={ classes.text_container }>
                                    <Avatar className={ classes.avatar }>
                                        <LockOutlinedIcon className={ classes.avatar_img } />
                                    </Avatar>
                                    <Typography className={ classes.login_text }>Se connecter à Ez Presta</Typography>
                                </div>
                                <FormikTextField variant="outlined" margin="normal" required fullWidth type="text" label="Email" name="login" />
                                <FormikTextField variant="outlined" margin="normal" required fullWidth name="password" label="Mot de passe" type="password" />
                                <Button type="submit" fullWidth variant="contained" color="secondary" className={ (classes.spaceField, classes.valider_button) } disabled={ isSubmitting }>
                                    Se connecter
                                </Button>
                                <Divider className={ classes.Divider } variant="middle" />
                                <Button type="button" fullWidth variant="contained" className={ classes.green_button } disabled={ isSubmitting } onClick={ () => redirectButton() }>
                                    Créer votre compte
                                </Button>
                            </Form>
                        ) }
                    </Formik>
                </Paper>
            </div>
        </div>
    );
}

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};
