import Field from "./components/Field";
import Header from "./components/Header";
import Validate from "./components/Validate";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";

// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        justifyContent: "center"
    },
    fields: {
        display: "flex",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        flexDirection: "column"
    }
}));

export default function Entreprise({ userInfo }) {
    const classes = useStyles();

    const [d, setData] = useState({
        company: userInfo.company || "",
        address: userInfo.address || "",
        siret: userInfo.siret || "",
        url: userInfo.url || "",
        phone: userInfo.phone || ""
    });
    const data = { d, setData, userInfo };

    useEffect(() => {
        setData({ company: userInfo.company, address: userInfo.address, siret: userInfo.siret, url: userInfo.url, phone: userInfo.phone });
    }, [userInfo]);

    return (
        <Card className={ classes.root }>
            <Header title="Votre entreprise" subheader="Changer les informations de votre entreprise" icon={ <BusinessCenterIcon /> } />
            <CardContent>
                <FormControl component="fieldset" fullWidth>
                    <div className={ classes.fields }>
                        <Field id="company" label="Entreprise" data={ data } />
                        <Field id="address" label="adresse" data={ data } />
                        <Field id="siret" data={ data } />
                        <Field id="url" label="Site WEB" data={ data } />
                        <Field id="phone" label="Téléphone" form="phone" data={ data } />
                    </div>
                </FormControl>
            </CardContent>
            <Validate data={ data } />
        </Card>
    );
}
